import {
	Card,
	CardHeader,
	Button,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	HStack,
	FormControl,
	Select,
	useToast,
	IconButton,
	Menu,
	MenuButton,
	MenuList,
	CloseButton,
	MenuItem,
	useDisclosure,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogFooter,
	Skeleton,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import ApiService from "../../service/Api.service";
import { FiRefreshCw } from "react-icons/fi";
import { useState, useRef } from "react";

export const PlayerSelectionTabCard = ({
	groups,
	rounds,
	selectedGroup,
	selectedRound,
	updatePlyrSelection,
	eventId,
	refreshPlyrSelection,
	groupsLookupLoad,
	roundsLookupLoad,latestRoundPlyrSelection
}) => {
	const cardText = useBreakpointValue({
		base: "md",
		md: "lg",
	});

	let toast = useToast();
	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
	const cancelRef = useRef();

	const [menuLoading, setMenuLoading] = useState(false);

	const handleNotificaiton = async (event) => {
		console.log("Action performed for:", event.target.value);
		let notification = event.target.value;
		switch (notification) {
			case "sendPlyrSelLink":
				try {
					setMenuLoading(true);
					let link = await ApiService.httpPost(
						`/ScoreLink?EventId=${eventId}&Class=PlayerSelection`,
						{}
					);
					if (link.status === true) {
						toast({
							title: "Send Player Selection Link",
							description: "Player Selection Link Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Player Selection",
							description: "Failed to Send Player Selection Link",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Player Selection",
						description: "Failed to Send Player Selection Link",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
			case "sendTeamMatchups":
				try {
					setMenuLoading(true);
					let link = await ApiService.httpPost(
						`/ScoreLink?EventId=${eventId}&Class=TeamsMatches`,
						{}
					);
					if (link.status === true) {
						toast({
							title: "Send Team Matchups",
							description: "Team Matchups Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Team Matchups",
							description: "Failed to send Team Matchups",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Team Matchups",
						description: "Failed to send Team Matchups",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
			case "sendPositions":
				try {
					setMenuLoading(true);
					let link = await ApiService.httpPost(
						`/ScoreLink?EventId=${eventId}&Class=TeamsPlayersPositions`,
						{}
					);
					if (link.status === true) {
						toast({
							title: "Send Positions",
							description: "Positions Sent Successfully!",
							status: "success",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					} else {
						toast({
							title: "Send Positions",
							description: "Failed to send Positions",
							status: "error",
							duration: 3000,
							isClosable: true,
							position: "top-right",
						});
					}
					setMenuLoading(false);
				} catch (err) {
					console.log(err);
					toast({
						title: "Send Positions",
						description: "Failed to send Positions",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setMenuLoading(false);
				}
				break;
			case "deleteLatestRound":
				onAlertOpen();
				break;
		}
	};

	const deleteLatestRound = async () => {
		try {
			setMenuLoading(true);
			let deleteRoundRes = await ApiService.httpDelete(
				`/TeamsRoundPlayers?TourEventId=${eventId}&GroupName=${selectedGroup.GroupId}`
			);
			console.log("Deleted Latest MatchUp:", deleteRoundRes);
			if (deleteRoundRes.status === true) {
				onAlertClose();
				toast({
					title: "Delete Latest Round",
					description: "Latest Round Deleted Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				latestRoundPlyrSelection();
			} else {
				onAlertClose();
				toast({
					title: "Delete Latest Round",
					description: "Failed to Delete Latest Round",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
			setMenuLoading(false);
		} catch (err) {
			console.log(err);
			onAlertClose();
			toast({
				title: "Delete Latest Round",
				description: "Failed to Delete Latest Round",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			setMenuLoading(false);
		}
	};

	return (
		<>
			<Card
				variant={"elevated"}
				bgColor="white"
				boxShadow={useColorModeValue("sm", "sm-dark")}>
				<CardHeader>
					<Stack
						direction={useBreakpointValue({
							base: "column",
							sm: "row",
						})}
						spacing="1.2rem"
						justify="space-between">
						<Text
							as="h3"
							fontSize={useBreakpointValue({
								base: "lg",
								md: "xl",
							})}
							fontWeight="semibold">
							Player Selection
						</Text>
						<Stack spacing="1rem" direction={"row"} justify="end" align="end">
							<IconButton
								variant="ghost"
								size="md"
								color="accent"
								icon={<FiRefreshCw size="1.7rem" onClick={refreshPlyrSelection} />}
							/>
							{groupsLookupLoad ? (
								<Skeleton isLoaded={!groupsLookupLoad} w="9vw">
									<Select />
								</Skeleton>
							) : (
								<FormControl width={"fit-content"}>
									<Select
										name="Group"
										colorScheme="blue"
										value={selectedGroup.GroupId}
										onChange={(event) =>
											updatePlyrSelection(
												event.target.value,
												selectedRound?.RoundNo
											)
										}>
										{groups?.map((group, index) => {
											return (
												<option key={group.GroupName} value={group.GroupId}>
													{group.GroupName}
												</option>
											);
										})}
									</Select>
								</FormControl>
							)}
							{roundsLookupLoad ? (
								<Skeleton isLoaded={!roundsLookupLoad} w="8vw">
									<Select />
								</Skeleton>
							) : (
								<FormControl width={"fit-content"}>
									<Select
										name="Round"
										colorScheme="blue"
										value={selectedRound?.RoundNo}
										fontSize={{ base: "sm", md: "md" }}
										{...((rounds?.length < 1 || rounds === undefined) && {
											placeholder: "-",
										})}
										textAlign="start"
										onChange={(event) => {
											updatePlyrSelection(
												selectedGroup.GroupId,
												event.target.value
											);
										}}>
										{rounds?.map((round, index) => {
											return (
												<option key={round.RoundNo} value={round.RoundNo}>
													{round.RoundName}
												</option>
											);
										})}
									</Select>
								</FormControl>
							)}
							<Menu direction="rtl">
								{({ isOpen }) => (
									<>
										<MenuButton
											isActive={isOpen}
											as={IconButton}
											variant="ghost"
											color="green.500"
											size="md"
											borderRadius="full"
											isLoading={menuLoading}
											icon={
												isOpen ? (
													<CloseButton variant="ghost" />
												) : (
													<FiMoreVertical size="1.7rem" />
												)
											}
										/>
										<MenuList zIndex="overlay">
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="sendPlyrSelLink"
												onClick={handleNotificaiton}>
												Send Player Selection Link
											</MenuItem>
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="sendTeamMatchups"
												onClick={handleNotificaiton}>
												Send Team Matchups
											</MenuItem>
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="sendPositions"
												onClick={handleNotificaiton}>
												Send Positions
											</MenuItem>
											<MenuItem
												textColor="green.400"
												fontWeight="medium"
												value="deleteLatestRound"
												onClick={handleNotificaiton}>
												Delete Latest Round
											</MenuItem>
										</MenuList>
									</>
								)}
							</Menu>
						</Stack>
					</Stack>
				</CardHeader>
			</Card>
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onAlertClose}
				isOpen={isAlertOpen}
				isCentered>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader>Delete Latest Round</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>Are you sure you want to Delete Latest Round?</AlertDialogBody>
					<AlertDialogFooter>
						{!menuLoading && (
							<Button ref={cancelRef} onClick={onAlertClose}>
								Cancel
							</Button>
						)}
						<Button
							colorScheme="red"
							ml={3}
							isLoading={menuLoading}
							onClick={deleteLatestRound}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};
