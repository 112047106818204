import {
	HStack,
	Image,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	VStack,
	FormControl,
	Select,
	Input,
	TableContainer,
	useBreakpointValue,
	Container,
	Text,
	Stack,
	Divider,
	useColorModeValue,
	FormLabel,
	IconButton,
	Icon,
	position,
	ButtonGroup,
	Button,
	Flex,
	useToast,
} from "@chakra-ui/react";
import { GiTrophy } from "react-icons/gi";
import { data } from "../MatchupsResults/data";
import { useEffect, useState } from "react";
import { FiEdit, FiX, FiCheck, FiShare2 } from "react-icons/fi";
import ApiService from "../../../service/Api.service";

export const RefereesTable = ({
	matchupsData,
	eventId,
	filteredMatchups,
	unassignedReferees,
	fetchRoundsData,
}) => {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const [editingRow, setEditingRow] = useState(null);
	const [selectedReferee, setSelectedReferee] = useState({});
	const toast = useToast();
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
		lg: false,
		xl: false,
	});
	// Tablet Breakpoint Value
	const isTablet = useBreakpointValue({
		base: false,
		sm: false,
		md: true,
		lg: false,
		xl: false,
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	// const handleEditClick = (matchupId) => {
	// 	setEditingRow(matchupId);
	// 	setSelectedReferee((prev) => ({
	// 		...prev,
	// 		[matchupId]: filteredMatchups.find((board) => board.MatchupId === matchupId)?.RefereeName || "",
	// 	}));
	// };
	const onUpdateReferee = async (matchupId, refereeId) => {
		try {
			const response = await fetch(
				`https://carromslive.azurewebsites.net/TourEventMatchupsByGroup?Class=UpdateReferee&MatchupId=${matchupId}&User=U00157`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ RefereeId: refereeId }),
				}
			);

			if (response.ok) {
				toast({
					title: "Referee updated successfully.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				await fetchRoundsData();
				//await fetchMatchups();

				setEditingRow(null); // Exit edit mode
			} else {
				const errorData = await response.json();
				toast({
					title: "Failed to update referee.",
					description: errorData.message || "Something went wrong.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (error) {
			toast({
				title: "Network Error",
				description: "Failed to connect to the server.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	const handleEditClick = (matchupId) => {
		setEditingRow(matchupId); // Enable editing for the specific row
	};

	const handleCheckClick = (matchupId) => {
		const refereeId = selectedReferee[matchupId];
		if (refereeId) {
			onUpdateReferee(matchupId, refereeId); // Trigger the API call
		} else {
			toast({
				title: "Please select a referee.",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};
	const handleCancelSelection = (matchupId) => {
		// Reset the editingRow state to null (or an appropriate value)
		setEditingRow(null);

		// Optionally, reset selectedReferee if needed
		setSelectedReferee((prevState) => {
			const newState = { ...prevState };
			delete newState[matchupId]; // Remove the selection for the current matchupId
			return newState;
		});
	};

	const handleRefereeChange = (matchupId, refereeId) => {
		setSelectedReferee((prev) => ({ ...prev, [matchupId]: refereeId }));
	};
	const handleShareLink = async () => {
		try {
			const response = await ApiService.httpPost(
				`/ScoreLink?Class=sendScoreLinkToRefereeByBoard&EventId=${eventId}&RoundNo=1&GroupName=A&Board=1&User=U00157`,
				{}
			);
			console.log("al", response);
			if (response.status === true) {
				//alert(response.data.Message); // Show success message
				toast({
					title: "Score Links",
					description: response.Message || "Score Links Sent successfully",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				//alert("Failed to send the link."); // Handle failure
				toast({
					title: "Score Links",
					description: response.Message || "Failed to send score links",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (error) {
			console.error("Error sending the link:", error);
			// alert("An error occurred while sending the link."); // Handle error
			toast({
				title: "Score Links",
				description: "An error occurred while sending the link",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	};

	const tableDesktop = () => (
		<Box>
			<Box boxShadow={shadow} borderTopRadius={"2xl"}>
				<Table variant="striped">
					<Thead position="sticky" top={0} bgColor="black" zIndex="2">
						<Tr>
							<Th
								textColor="white"
								fontSize={"md"}
								borderTopLeftRadius={"2xl"}
								textAlign={isTablet ? "end" : "center"}
								px={3}
								py={4}
								{...(isMobile && {
									px: 1.5,
									maxW: "1rem",
									fontSize: "xs",
									py: 3,
								})}
								{...(isTablet && {
									maxW: "-webkit-fit-content",
									px: 1,
									fontSize: "sm",
								})}>
								{isMobile ? "#" : "Board"}
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={5}
								{...(isMobile && { px: 2, fontSize: "xs" })}
								textAlign={"start"}>
								Player 1
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={5}
								{...(isMobile && { px: 1, fontSize: "xs" })}
								textAlign={"start"}>
								Score
							</Th>
							{/* <Th
								textColor="#4E4EE9"
								fontSize="xl"
								{...(isMobile && { px: 2 })}
							textAlign="center"></Th>*/}
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={5}
								{...(isMobile && { px: 2, fontSize: "xs" })}
								textAlign={"start"}>
								Player 2
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								borderTopRightRadius={"2xl"}
								px={5}
								{...(isMobile && { px: 2, fontSize: "xs" })}
								textAlign={"start"}>
								Referee
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{filteredMatchups?.map((board) => (
							<Tr key={board.MatchupId}>
								<Td
									textAlign={"center"}
									fontSize={isTablet ? "md" : "lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1.5,
										maxW: "-webkit-fit-content",
										fontSize: "sm",
									})}
									{...(isTablet && { px: 2, maxW: "-webkit-fit-content" })}>
									{board.Venue}
								</Td>
								<Td
									textAlign={"start"}
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 2 })}
									maxW="-webkit-fit-content"
									whiteSpace={"break-spaces"}>
									<HStack justify={"start"}>
										<Text>{board.Player1}</Text>
										{board.isPlayer1Won === "True" && <Icon as={GiTrophy} />}
									</HStack>
								</Td>
								<Td
									textAlign="start"
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 2 })}
									maxW="-webkit-fit-content">
									{board.Score}
								</Td>
								{/* <Td
									textColor="accent"
									textAlign="center"
									fontSize={"xl"}
									fontWeight="medium"
									{...(isMobile && {
										px: 2,
										fontSize: "lg",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 3 })}
									maxW="-webkit-fit-content">
									
								</Td> */}
								<Td
									textAlign={"start"}
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 1 })}
									maxW="-webkit-fit-content"
									whiteSpace={"break-spaces"}>
									<HStack justify={"start"}>
										<Text>{board.Player2}</Text>
										{board.isPlayer2Won === "True" && <Icon as={GiTrophy} />}
									</HStack>
								</Td>
								<Td
									textAlign={"start"}
									fontSize={"lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1,
										fontSize: "sm",
										maxW: "-webkit-fit-content",
									})}
									{...(isTablet && { px: 2 })}
									maxW="-webkit-fit-content"
									whiteSpace={"break-spaces"}>
									<HStack justify={"space-between"}>
										{editingRow === board.MatchupId ? (
											<>
												<Select
													placeholder="Select Referee"
													value={selectedReferee[board.MatchupId] || ""}
													onChange={(e) =>
														handleRefereeChange(board.MatchupId, e.target.value)
													}
													size="sm">
													{unassignedReferees.map((referee) => (
														<option key={referee.RefereeId} value={referee.RefereeId}>
															{referee.RefereeName}
														</option>
													))}
												</Select>
												<IconButton
													aria-label="Cancel"
													icon={<Icon as={FiX} />}
													colorScheme="red"
													size="sm"
													onClick={() => handleCancelSelection(board.MatchupId)}
												/>
												<IconButton
													aria-label="Confirm"
													icon={<Icon as={FiCheck} />}
													colorScheme="green"
													size="sm"
													onClick={() => handleCheckClick(board.MatchupId)}
												/>
											</>
										) : (
											<>
												<Text>{board.RefereeName}</Text>
												<HStack>
													<IconButton
														aria-label="Edit Referee"
														icon={<Icon as={FiEdit} />}
														colorScheme="blue"
														size="sm"
														onClick={() => handleEditClick(board.MatchupId)}
													/>
													<IconButton
														aria-label="Share"
														icon={<FiShare2 />}
														colorScheme="teal"
														size="sm"
														onClick={() => handleShareLink(board.MatchupId)}
													/>
												</HStack>
											</>
										)}
									</HStack>
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
			<HStack justify="space-between" p="1rem" bgColor="white" borderBottomRadius={"2xl"}>
				{/*---------------For Pagination-------------------------------*/}
			</HStack>
		</Box>
	);

	return tableDesktop();
};
