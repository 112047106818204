import React, { useState, useEffect } from "react";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";
import { Table } from "../../../Components/V2/TableWrapper";

const TeamStandingsTable = ({ groupname, roundNo, eventId }) => {
	const user = useAuthProvider();

	const [teamStandings, setTeamStandings] = useState([]);
	const [standingsRound, setStandingsRound] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 8;

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const displayedTeamStandings = teamStandings?.slice(startIndex, endIndex);

	async function fetchTeamStandings() {
		try {
			let teamStand = await ApiService.httpGet(
				`/TourStandings?Class=Teams&TourEventId=${eventId}&GroupName=${groupname}&RoundNo=${roundNo}`,
				user.userId
			);
			if (teamStand.status === true) {
				setTeamStandings(teamStand.TourStandings);
				setStandingsRound(teamStand.RoundNo);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		fetchTeamStandings();
	}, [groupname, roundNo, eventId]);

	useEffect(() => {
		// Function to show the next values
		console.log("second useEffect");
		const showNextValues = () => {
			if (endIndex < teamStandings.length) {
				setCurrentPage(currentPage + 1);
			} else {
				setCurrentPage(1); // Start from the beginning if reached the end
			}
		};

		// Automatically show next values every 5 seconds
		const timer = setTimeout(showNextValues, 5000);
		const refreshTimer = setInterval(fetchTeamStandings, 5000);

		// Clear the timer when the component unmounts or when the currentPage changes
		return () => {
			clearTimeout(timer);
			clearInterval(refreshTimer);
		};
	}, [currentPage, endIndex, teamStandings.length]);

	if (!teamStandings?.length) return null;

	const columns = [
		{
			key: "Position",
			title: "Position",
			align: "center",
			width: "10%",
		},
		{
			key: "TeamName",
			title: "Team Name",
			align: "left",
			width: "25%",
		},
		{
			key: "MatchesPlayed",
			title: "Played",
			align: "center",
			width: "15%",
		},
		{
			key: "MatchesWon",
			title: "Won",
			align: "center",
			width: "15%",
		},
		{
			key: "PointsScored",
			title: "Points",
			align: "center",
			width: "15%",
		},
		{
			key: "NetWins",
			title: "Won/Lost (Individual)",
			align: "center",
			width: "15%",
		},
	];

	return (
		<div className="animate-in fade-in zoom-in duration-300">
			<div className=" mx-auto h-auto lg:w-[95vw] sm:w-[95vw] overflow-y-auto rounded-2xl bg-white  overflow-hidden">
				<div className="px-4 lg:py-2">
					<div className="flex min-h-[75px] min-w-[500px] items-center justify-center flex-row gap-2">
						<h2 className="w-fit text-center text-xl font-semibold text-black">
							TEAM STANDINGS
						</h2>
						{standingsRound > 0 && (
							<h4 className="w-fit text-center text-sm font-bold text-white bg-green-600 px-2 py-1 rounded-full">
								AFTER ROUND {standingsRound}
							</h4>
						)}
					</div>
				</div>
				<Table
					data={displayedTeamStandings?.slice(0, 10)}
					columns={columns}
					wrapperClassName="px-0"
					className="w-full bg-white"
					cellClassName="h-2"
					headerClassName="w-full text-center"
					zebra={true}
					rounded={true}
					bordered={true}
					hover={false}
					animate={false}
					sortable={false}
				/>
			</div>
		</div>
	);
};

export default TeamStandingsTable;
