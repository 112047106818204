import { useState, useEffect } from "react";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";
import { Table } from "../../../Components/V2/TableWrapper";

export const BoardScoresTable = ({ matchupId }) => {
	const user = useAuthProvider();
	const [boardScores, setBoardScores] = useState(null);

	async function fetchBoardScores() {
		try {
			let boardScores = await ApiService.httpGet(
				`/TourEventScores?MatchupId=${matchupId}`,
				user.userId
			);
			if (boardScores.status === true) {
				setBoardScores(
					boardScores.ScoreDetails[
						boardScores.SetCount > 1 ? boardScores.LatestKnockoutSet - 1 : 0
					]
				);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		fetchBoardScores();
		const intervalId = setInterval(() => {
			fetchBoardScores();
		}, 10000);

		return () => clearInterval(intervalId);
	}, [matchupId]);

	if (!boardScores) return null;

	const columns = [
		{
			key: "BoardId",
			title: "#",
		},

		{
			key: "Player1Score",
			title: boardScores.Player1Name.split("&")[1]
				? boardScores.Player1Name.split("&")[1]
				: boardScores.Player1Name.split(" ")[0],
			// render: (value, row) => (
			// 	<div className="flex items-center justify-between">
			// 		{/* Check if Player1 is the Queen */}
			// 		{row.Queen === row.Player1Id ? (
			// 			<span className="text-yellow-500">{value}</span>
			// 		) : (
			// 			<span className="text-gray-500">{value}</span>
			// 		)}
			// 	</div>
			// ),
		},
		{
			key: "Player2Score",
			title: boardScores.Player2Name.split("&")[1]
				? boardScores.Player2Name.split("&")[1]
				: boardScores.Player2Name.split(" ")[0],
			// render: (text, record) => (
			// 	// Highlight Player2 score if Player2 is the queen
			// 	<div className={record.QueenName === boardScores.Player2Name ? 'bg-yellow-200' : ''}>
			// 		{text}
			// 	</div>
			// ),
		},
	];

	const alignedData =
		boardScores?.Scores?.map((score) => ({
			BoardId: score.BoardId,
			QueenName: score.QueenName.split(" ")[0],
			Player1Score: score.Player1Score,
			Player2Score: score.Player2Score,
		})) || [];

	return (
		<main className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90vw] lg:w-[50vw] h-auto">
			{/* Add absolute positioning */} {/* Add absolute positioning */}{" "}
			<div className="flex flex-col items-center w-auto bg-white rounded-2xl  p-4 h-auto overflow-y-auto">
				<h2 className="w-fit text-center text-xl font-bold text-black">Board Scores</h2>
				<Table
					data={alignedData?.slice(0, 10)}
					columns={columns}
					className="w-full"
					headerClassName="text-center  rounded-full "
					cellClassName="h-2"
					zebra={true}
					rounded={true}
					bordered={true}
					hover={true}
				/>
			</div>
		</main>
	);
};
