import React from "react";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
	return twMerge(clsx(inputs));
}

export const Table = ({
	data = [],
	columns = [],
	className,
	headerClassName,
	rowClassName,
	cellClassName,
	wrapperClassName,
	showHeader = true,
	zebra = true,
	rounded = true,
	bordered = true,
	compact = false,
	hover = true,
	animate = true,
	sortable = false,
}) => {
	const defaultWrapperStyles = cn(
		"w-full h-full", // Full height for mobile screens
		animate && "animate-in fade-in zoom-in duration-300",
		wrapperClassName
	);

	const defaultTableStyles = cn(
		"w-full h-full min-w-full", // Ensure the table fits on smaller screens
		"bg-white",
		className
	);

	const defaultHeaderStyles = cn("p-1 sm:text-xs lg:text-sm ", headerClassName);

	const defaultHeaderCellStyles = cn(
		"bg-[#4A38B7] text-white font-normal text-center",
		rounded && "rounded-full",
		"px-2 lg:py-3 sm:py-1"
	);

	const getRowStyles = (index) =>
		cn(
			zebra && index % 2 === 0 ? "bg-white" : " ",
			hover && "hover:bg-gray-100 transition-colors duration-150",
			rowClassName
		);

	const getCellStyles = (index, column) =>
		cn(
			"p-1 sm:text-xs lg:text-sm font-medium text-black",
			compact ? "py-1" : "sm:py-1 lg:py-2",
			column.align === "left" && "text-left",
			column.align === "right" && "text-right",
			column.align === "center" && "text-center",
			cellClassName,
			column.cellClassName
		);

	const getContentWrapperStyles = (index) =>
		cn(
			"px-2 lg:py-2 sm:py-1 flex items-center justify-center h-full",
			rounded && "rounded-full",
			bordered && "border border-gray-300 ",
			zebra && index % 2 !== 0 && "text-[#4A38B7] bg-gray-50",
			compact ? "py-1" : "lg:py-2 sm:py-1"
		);

	const renderCell = (row, column, rowIndex) => {
		const content = column.render
			? column.render(row[column.key], row, rowIndex)
			: row[column.key];

		return <div className={getContentWrapperStyles(rowIndex)}>{content}</div>;
	};

	const sortedColumns = React.useMemo(() => {
		return sortable ? columns.sort((a, b) => (a.order || 0) - (b.order || 0)) : columns;
	}, [columns, sortable]);

	return (
		<div className={defaultWrapperStyles}>
			<table className={defaultTableStyles}>
				{showHeader && (
					<thead>
						<tr>
							{sortedColumns.map((column, index) => (
								<th
									key={column.key || index}
									style={{ whiteSpace: "normal" }}
									className={cn(
										defaultHeaderStyles,
										column.width && `w-[${column.width}]`,
										column.headerClassName
									)}>
									<div className={defaultHeaderCellStyles}>{column.title}</div>
								</th>
							))}
						</tr>
					</thead>
				)}
				<tbody>
					{data.map((row, rowIndex) => (
						<tr key={rowIndex} className={getRowStyles(rowIndex)}>
							{sortedColumns.map((column, colIndex) => (
								<td
									key={column.key || colIndex}
									className={getCellStyles(rowIndex, column)}>
									{renderCell(row, column, rowIndex)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
