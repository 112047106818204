import {
	Flex,
	useBreakpointValue,
	AbsoluteCenter,
	HStack,
	Image,
	Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../service/Api.service";
import PlayerStandingsTable from "./GreenScreen/PlayerStandingsTable";
import TeamStandingsTable from "./GreenScreen/TeamStandingsTable";
import { BoardScoresTable } from "./GreenScreen/BoardScoresTable";
import clubLogo from "../../assets/cpl-logo.png";
import carromLogo from "../../images/logo.png";
import { PlayerMatchupResults } from "./GreenScreen/PlayerMatchupResults";
import { PlayerMatchup } from "./GreenScreen/PlayerMatchup";
import { PlayerProfile } from "./GreenScreen/PlayerProfile";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import { PlayerLeftJourney } from "./GreenScreen/PlayerLeftJourney";
import { PlayerRightJourney } from "./GreenScreen/PlayerRightJourney";
import { TeamJourney } from "./GreenScreen/TeamJourney";
import { TeamRightJourney } from "./GreenScreen/TeamRightJourney";
import { DaySchedule } from "./GreenScreen/DaySchedule";
import { FlashNews } from "./GreenScreen/FlashNews";
import { TourSchedule } from "./GreenScreen/TourSchedule";
import { MatchupResults } from "./GreenScreen/MatchupResults";
import { TeamMatchupResults } from "./GreenScreen/TeamMatchupResults";
import { useAuthProvider } from "../../context/AuthProvider";
import { clubImgDir } from "../../Utilities/ImageLinks";
import ScoreCard from "../../Components/EventTabsCards/ScoreCard";
import Teams3SetMatchUpResults from "./GreenScreen/Teams3SetMatchUpResults";
import { TeamMatchup } from "./GreenScreen/TeamMatchup";

export const NewScoreBoard = () => {
	// User Details
	const user = useAuthProvider();

	const isMobile = useBreakpointValue({
		base: true,
		sm: false,
		md: false,
		lg: false,
		xl: false,
	});
	const { eventId, boardNo } = useParams();
	let [boardData, setBoardData] = useState(null);

	const [overlaysState, setOverlaysState] = useState(null);

	// overlays
	async function getOverlaysState() {
		try {
			const resp = await ApiService.httpGet(
				`/StreamingConfigurations?TourEventId=${eventId}&Board=${boardNo}&Class=getStreamControlSB&User=U00184`,
				user.userId
			);
			const states = resp.StreamControl.map((c) => {
				return {
					control: c.ControlCode,
					state: c.State,
					streamControlId: c.StreamControlId,
					property: c.Property,
				};
			});
			console.log(states);
			setOverlaysState(states);
		} catch (err) {
			console.log(err);
		}
	}

	// Groupname, RoundNo, MatchupId
	let fetchScoreData = async () => {
		try {
			let boardRes = await ApiService.httpGet(
				`/TourEventScores?Class=StreamingJSON&Board=${boardNo}&TourEventId=${eventId}`,
				user.userId
			);
			setBoardData(boardRes.payload);
		} catch (err) {
			console.log(err);
		}
	};

	const setupSocketConn = async () => {
		const resp = await fetch("https://carromslive.azurewebsites.net/pubsub/token");
		const token = await resp.json();

		const client = new WebPubSubClient(token.url);
		client.on("connected", (e) => {
			console.log(`Web PubSub connected`, e);
		});
		client.on("server-message", (e) => {
			if (e.message.data.event === "streaming-control-update") {
				const { boardId, tourEventId, streamControlId, state } = e.message.data;
				if (boardId === boardNo && tourEventId === eventId) {
					console.log("board matches - updating the control state");
					getOverlaysState();
				}
			} else if (e.message.data.event === "score-update") {
				const { board, tourEventId } = e.message.data;
				const boardId = board.toString();
				console.log(boardId, boardNo, tourEventId, eventId);
				if (boardId === boardNo && tourEventId === eventId) {
					console.log("updating the scores realtime");
					fetchScoreData();
				}
			} else if (e.message.data.event === "stream-page-reset") {
				localStorage.clear();
				sessionStorage.clear();
				setTimeout(() => {
					window.location.reload(true); // Forced reload
				}, 100);
			}
		});
		client.start();
		return client;
	};

	useEffect(() => {
		document.body.style.overflow = "hidden";
		fetchScoreData();
		getOverlaysState();
		let client;
		(async () => {
			client = await setupSocketConn();
		})();

		return () => {
			client.stop();
			document.body.style.overflow = "auto";
		};
	}, []);
	if (boardData) {
		return (
			<>
				<AbsoluteCenter zIndex="overlay">
					{boardData &&
						overlaysState &&
						overlaysState.map((overlay) => {
							if (
								boardData.isteams &&
								overlay.state &&
								overlay.control === "PlayerStandings"
							) {
								return (
									<PlayerStandingsTable
										key={overlay.control}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
									/>
								);
							} else if (overlay.state && overlay.control === "Standings") {
								return (
									<PlayerStandingsTable
										key={overlay.control}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
									/>
								);
							} else if (
								boardData.isteams &&
								overlay.state &&
								overlay.control === "TeamStandings"
							) {
								return (
									<TeamStandingsTable
										key={overlay.control}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
									/>
								);
							} else if (overlay.state && overlay.control === "BoardScore") {
								return (
									<BoardScoresTable
										key={overlay.control}
										matchupId={boardData.matchupId}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
										boardData={boardData}
									/>
								);
							} else if (
								boardData.isteams &&
								overlay.state &&
								overlay.control === "TeamJourney"
							) {
								return (
									<TeamJourney
										key={overlay.control}
										teamId={overlay.property}
										overlaysState={overlaysState}
									/>
								);
							} else if (overlay.state && overlay.control === "PlayerLeftJourney") {
								return (
									<PlayerLeftJourney key={overlay.control} teamId={boardData.playerAId} />
								);
							} else if (overlay.state && overlay.control === "PlayerRightJourney") {
								return (
									<PlayerRightJourney
										key={overlay.control}
										teamId={boardData.playerBId}
									/>
								);
							} else if (overlay.state && overlay.control === "DaySchedule") {
								return <DaySchedule key={overlay.control} tourId={boardData.tourId} />;
							} else if (overlay.state && overlay.control === "TournamentSchedule") {
								return <TourSchedule key={overlay.control} tourId={boardData.tourId} />;
							} else if (
								boardData.isteams &&
								overlay.state &&
								overlay.control === "PlayerMatchupResults"
							) {
								return (
									<PlayerMatchupResults
										key={overlay.control}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
									/>
								);
							} else if (overlay.state && overlay.control === "TeamMatchup") {
								return (
									<TeamMatchup
										key={overlay.control}
										eventId={eventId}
										tournamentname={boardData.tournamentname}
										round={boardData.round}
										boardData={boardData}
										team1Id={boardData.TeamsConfigs.Team1Id}
										team2Id={boardData.TeamsConfigs.Team2Id}
										roundNo={boardData.roundNo}
									/>
								);
							} else if (overlay.state && overlay.control === "PlayerProfile") {
								return (
									<PlayerProfile
										key={overlay.control}
										eventId={eventId}
										boardNo={boardNo}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
										overlaysState={overlaysState}
										profPlayerId={overlay.property}
									/>
								);
							} else if (
								overlay.state &&
								(overlay.control === "Teams3Set" || overlay.control === "Matchups")
							) {
								return (
									<Teams3SetMatchUpResults
										round={boardData.round}
										eventId={eventId}
										roundNo={boardData.roundNo}
										groupname={boardData.groupname}
									/>
								);
							} else if (
								overlay.state &&
								(overlay.control === "PlayerMatchup" || overlay.control === "Matchups")
							) {
								return (
									<PlayerMatchup
										matchupId={boardData.matchupId}
										tournamentname={boardData.tournamentname}
										round={boardData.round}
										boardData={boardData}
									/>
								);
							} else if (
								boardData.isteams &&
								overlay.state &&
								overlay.control === "TeamMatchupResults"
							) {
								return (
									<TeamMatchupResults
										key={overlay.control}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
									/>
								);
							} else if (
								!boardData.isteams &&
								overlay.state &&
								overlay.control === "MatchupResults"
							) {
								return (
									<MatchupResults
										key={overlay.control}
										eventId={eventId}
										groupname={boardData.groupname}
										roundNo={boardData.roundNo}
										isteams={boardData.isteams}
									/>
								);
							}
							return null;
						})}
				</AbsoluteCenter>
				<Flex
					userSelect={"none"}
					as="section"
					direction="column"
					height="100vh"
					bg={boardNo === "21" ? "rgb(0, 0, 0)" : "#FFFF00"}>
					{overlaysState?.find(
						(overlay) => overlay.state && overlay.control === "TopBar"
					) && (
						<HStack
							justify={"space-between"}
							p={3}
							w={"100%"}
							position={"absolute"}
							top={0}>
							{/* Club Image */}
							<Image
								src={
									boardData.clubimage ? `${clubImgDir}${boardData.clubimage}` : clubLogo
								}
								alt="Club Image"
								blockSize={100}
								objectFit="contain"
								draggable="false"
								margin="2"
								zIndex={1} // Ensure the overlay is above other components
							/>
							{/* Carrom Logo */}
							<Image
								src={carromLogo}
								alt="Carrom Platform Logo"
								blockSize={125}
								objectFit="contain"
								draggable="false"
								zIndex={1} // Ensure the overlay is above other components
							/>
						</HStack>
					)}
					<Stack
						dir="row"
						position={"absolute"}
						bottom={3}
						w={"100vw"}
						my={0}
						spacing={1}
						alignItems={"center"}
						justifyContent={"center"}>
						{boardData &&
							overlaysState &&
							overlaysState?.find(
								(overlay) => overlay.state && overlay.control === "FlashNews"
							) && <FlashNews eventId={eventId} boardNo={boardNo} />}
						{boardData &&
							overlaysState &&
							overlaysState?.find(
								(overlay) => overlay.state && overlay.control === "ScoreCard"
							) && <ScoreCard boardData={boardData} />}
					</Stack>
				</Flex>
			</>
		);
	}
};
