import {
	Card,
	CardHeader,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	Select,
	FormControl,
	Button,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	useDisclosure,
	ModalContent,
	useToast,
	FormLabel,
	FormErrorMessage,
	HStack,
	IconButton,
} from "@chakra-ui/react";
import { useState } from "react";
import ApiService from "../../service/Api.service";
import { FiRefreshCw } from "react-icons/fi";

export const StreamingTabCard = ({
	eventId,
	streamingGroups,
	refreshStreaming,
	groupsLookupLoad,
}) => {
	const [board, setBoard] = useState(1);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [selectedGroup, setSelectedGroup] = useState("");
	const [selectedBoardNo, setSelectedBoardNo] = useState(0);

	// For Modal
	const { isOpen, onOpen, onClose } = useDisclosure();

	let toast = useToast();

	const addBoard = async () => {
		if (selectedGroup === "" || selectedGroup === undefined || selectedGroup === null) {
			setError("Please Select a Group");
		} else {
			setError(null);
			let reqObj = {
				TourEventId: eventId,
				GroupName: selectedGroup,
				Board: Number(board),
			};
			try {
				setLoading(true);
				let response = await ApiService.httpPost(
					`/StreamingConfigurations?Class=ScInfo`,
					reqObj
				);
				console.log("Add Boards", response);
				if (response.status === true) {
					toast({
						title: "Add Board",
						description: "Board Added Successfully.",
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					setSelectedGroup("");
					setBoard(1);
					onClose();
					refreshStreaming();
				} else if (response.status === false) {
					toast({
						title: "Add Board",
						description: "Failed to Add Board",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				} else {
					toast({
						title: "Add Board",
						description: "Failed to Add Board",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				}
				setLoading(false);
			} catch (err) {
				console.log(err);
				toast({
					title: "Add Board",
					description: "Failed to Add Board",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setLoading(false);
			}
			console.log(reqObj);
		}
	};

	async function hardReloadStreamBoards() {
		try {
			let response = await ApiService.httpPut(
				"/StreamingConfigurations?Class=StreamPageReset&TourEventId=" + eventId,
				{}
			);
			if (response.status === true) {
				toast({
					title: "Reload Boards",
					description: "Boards Reloaded Successfully.",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Reload Boards",
					description: "Failed to Reload Boards.",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			toast({
				title: "Reload Boards",
				description: "Failed to Reload Boards.",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	}

	return (
		<>
			<Card
				variant={"elevated"}
				bgColor="white"
				boxShadow={useColorModeValue("sm", "sm-dark")}>
				<CardHeader>
					<Stack
						direction={{ base: "column", sm: "row" }}
						justify="space-between"
						align="center">
						<Stack direction={"row"} spacing=".7rem">
							<Text
								as="h3"
								fontSize={useBreakpointValue({
									base: "lg",
									md: "xl",
								})}
								fontWeight="semibold">
								Streaming
							</Text>
							<Text
								fontSize={{
									base: "md",
									md: "lg",
								}}
								textColor="accent"
								fontWeight="semibold">
								Configurations
							</Text>
						</Stack>
						<HStack justify={"end"} align={"end"}>
							<IconButton
								variant="ghost"
								size="md"
								color="accent"
								icon={<FiRefreshCw size="1.7rem" />}
								onClick={refreshStreaming}
							/>
							<Button
								size="md"
								minW={"-webkit-fit-content"}
								color="white"
								bgColor="bg-accent"
								borderRadius="full"
								isLoading={groupsLookupLoad}
								onClick={onOpen}>
								Add Board
							</Button>
							<Button
								size={"md"}
								minW={"-webkit-fit-content"}
								color={"white"}
								bgColor={"bg-accent"}
								borderRadius={"full"}
								onClick={hardReloadStreamBoards}>
								Reload Boards
							</Button>
						</HStack>
					</Stack>
				</CardHeader>
			</Card>
			<Modal
				isOpen={isOpen}
				onClose={() => {
					onClose();
					setSelectedGroup("");
					setBoard(1);
				}}
				size={{ base: "xs", sm: "md", md: "lg", lg: "xl" }}
				isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Add Board</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack direction={"row"}>
							<FormControl
								isInvalid={
									error &&
									(selectedGroup === "" ||
										selectedGroup === undefined ||
										selectedGroup === null)
								}>
								<FormLabel>Group Name</FormLabel>
								<Select
									placeholder="Select a Group"
									onChange={(e) => setSelectedGroup(e.target.value)}>
									{streamingGroups?.map((group) => {
										return (
											<option key={group.GroupId} value={group.GroupId}>
												{group.GroupName}
											</option>
										);
									})}
								</Select>
								<FormErrorMessage>{error && error}</FormErrorMessage>
							</FormControl>
							<FormControl>
								<FormLabel>Board</FormLabel>
								<NumberInput
									defaultValue={1}
									min={1}
									inputMode="numeric"
									onChange={(value) => setBoard(value)}>
									<NumberInputField />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
							</FormControl>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" mr={3} isLoading={loading} onClick={addBoard}>
							Submit
						</Button>
						{!loading && (
							<Button
								onClick={() => {
									onClose();
									setSelectedGroup("");
									setBoard(1);
								}}>
								Cancel
							</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
