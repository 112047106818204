import React, { useState, useEffect } from "react";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";
import { Trophy } from "lucide-react";
import { Table } from "../../../Components/V2/TableWrapper";

export const MatchupResults = ({ eventId, groupname, roundNo }) => {
	const user = useAuthProvider();
	const [matchupResults, setMatchupResults] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const displayedMatchups = matchupResults?.slice(startIndex, endIndex);

	async function fetchMatchups() {
		try {
			let matchupRes = await ApiService.httpGet(
				`/TourEventMatchupsByGroup?TourEventId=${eventId}&GroupName=${groupname}&RoundNo=${roundNo}`,
				user.userId
			);
			if (matchupRes.status === true) {
				setMatchupResults(matchupRes.TourEventMatchups);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		fetchMatchups();
	}, [eventId, groupname, roundNo]);

	useEffect(() => {
		const showNextValues = () => {
			if (endIndex < matchupResults?.length) {
				setCurrentPage(currentPage + 1);
			} else {
				setCurrentPage(1);
			}
		};

		const timer = setTimeout(showNextValues, 5000);

		return () => clearTimeout(timer);
	}, [currentPage, endIndex, matchupResults?.length]);

	if (!matchupResults?.length) return null;

	const columns = [
		{ key: "Venue", title: "Board", align: "center", width: "15%" },
		{
			key: "Player1",
			title: "Player 1",
			align: "left",
			width: "25%",
			render: (value, row) => (
				<div className="flex items-center justify-between">
					<span>{value}</span>
					{row.isPlayer1Won === 1 && <Trophy className="h-4 w-4 text-yellow-500" />}
				</div>
			),
		},
		{ key: "Team1Score", title: "Score", align: "center", width: "15%" },
		{
			key: "vs",
			title: "VS",
			align: "center",
			width: "15%",
			render: () => "X",
		},
		{ key: "Team2Score", title: "Score", align: "center", width: "15%" },
		{
			key: "Player2",
			title: "Player 2",
			align: "right",
			width: "25%",
			render: (value, row) => (
				<div className="flex items-center justify-between">
					{row.isPlayer2Won === 1 && <Trophy className="h-4 w-4 text-yellow-500" />}
					<span>{value}</span>
				</div>
			),
		},
	];

	return (
		<div className="animate-in fade-in zoom-in duration-300">
			<div className="h-auto min-w-[90vw] overflow-y-auto rounded-2xl bg-white overflow-hidden">
				<div className="px-4 lg:py-1 sm:py-0">
					<div className="flex min-h-[75px] sm:min-h-[60px] min-w-[500px] items-center justify-center">
						<h2 className="w-fit text-center text-2xl font-bold text-black">
							Matchups Results
						</h2>
					</div>
				</div>
				<Table
					data={displayedMatchups?.slice(0, 10)}
					columns={columns}
					wrapperClassName="px-4"
					className="w-full bg-white"
					cellClassName="h-2"
					headerClassName="w-full text-center"
					zebra={true}
					rounded={true}
					bordered={true}
					hover={false}
					animate={false}
					sortable={false}
				/>
			</div>
		</div>
	);
};
