import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Text,
	Switch,
	HStack,
	Heading,
	Container,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Stack,
	StackDivider,
	SimpleGrid,
	useToast,
	List,
	ListItem,
	Box,
	VStack,
	FormControl,
	FormLabel,
	Input,
	Textarea,
	Button,
	FormErrorMessage,
	Select,
} from "@chakra-ui/react";
import ApiService from "../../../service/Api.service";
import {
	RadioButton,
	RadioButtonGroup,
} from "../../../Components/Elements/RadioButtonGroup";
import { Controller, useForm } from "react-hook-form";
import { useAuthProvider } from "../../../context/AuthProvider";

export function ScoreBoardControls() {
	// User Details
	const user = useAuthProvider();

	const { eventId, boardNo } = useParams();
	let toast = useToast();
	const [controlsState, setControlsState] = useState(null);
	const [teamsList, setTeamsList] = useState(null);
	const [flashMessage, setFlashMessage] = useState(null);

	const {
		register,
		handleSubmit,
		reset,
		watch,
		getValues,
		FormValues,
		setValue,
		control,
		formState: { isDirty, isValid, errors },
	} = useForm({
		delayError: "500",
		mode: "all",
		defaultValues: {
			FormValues: {
				TourEventId: eventId,
				Board: boardNo,
				Message: "",
				MessageTitle: "Coming Up",
			},
		},
	});
	// GET api for fetching all the controls
	async function getControlStates() {
		try {
			const controls = await ApiService.httpGet(
				`/StreamingConfigurations?TourEventId=${eventId}&BoardId=${boardNo}&Class=StreamControl&User=U00184`,
				user.userId
			);
			setControlsState(controls.StreamControls);
		} catch (err) {
			console.log(err);
		}
	}

	async function getTeamsList() {
		try {
			const teams = await ApiService.httpGet(
				`/TeamEventTeams?Class=Teams&TourEventId=${eventId}&User=U00184`
			);
			setTeamsList(teams.TeamNames);
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		getTeamsList();
		fetchplayerprofileoptions();
		getControlStates();
		fetchMessage();
	}, [eventId, boardNo]);

	// Toggle Switch PUT operation
	async function handleChange(groupKey, c, newState, property) {
		newState = newState === true ? 1 : 0;
		setControlsState((prevData) => {
			const newData = [...prevData];
			const group = newData.find((g) => Object.keys(g)[0] === groupKey);
			if (group) {
				const control = group[groupKey].find((cs) => cs.ControlCode === c.ControlCode);
				if (control) {
					control.State = newState;
				}
			}
			return newData;
		});
		try {
			const reqObj = {
				ControlCode: c.ControlCode,
				State: newState,
				StreamControlId: c.StreamControlId,
				Property: property,
			};
			const toggleChange = await ApiService.httpPut(
				`/StreamingConfigurations?TourEventId=${eventId}&BoardId=${boardNo}&Class=StreamControl&User=U00184`,
				reqObj
			);
			if (toggleChange.status === true) {
				toast({
					title: "Screen Control",
					description: `${c.ControlName} updated Successfully.`,
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				getControlStates();
			} else {
				toast({
					title: "Screen Control",
					description: `Failed to update ${c.ControlName}`,
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				getControlStates();
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Screen Control",
				description: `Failed to update ${c.ControlName}`,
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			getControlStates();
		}
	}
	async function fetchMessage() {
		try {
			let resp = await ApiService.httpGet(
				`/StreamingConfigurations?TourEventId=${eventId}&Board=${boardNo}&Class=getMessage&User=U00184`,
				user.userId
			);
			if (resp.status === true) {
				setFlashMessage(resp);
				setValue("FormValues.Message", resp.Message !== null ? resp.Message : "");
				setValue(
					"FormValues.MessageTitle",
					resp.MessageTitle !== null ? resp.MessageTitle : "Coming Up"
				);
			}
		} catch (err) {
			console.log(err);
		}
	}
	function onSubmit(data) {
		let SubmitJSON = {
			TourEventId: eventId,
			Board: data.FormValues.Board,
			Message: data.FormValues.Message,
			MessageTitle: data.FormValues.MessageTitle,
		};
		console.log(SubmitJSON);
		saveMessage(SubmitJSON);
	}
	const [playersList, setPlayersList] = useState([]);

	const fetchplayerprofileoptions = async () => {
		try {
			const response = await ApiService.httpGet(
				`/TourPlayers?Class=PlayersLookup&User=${user.userId}`,
				user.userId
			);

			if (response.status === true) {
				// Setting dropdown options with PlayerName as label and ProfPlayerId as value
				setPlayersList(response.PlayersLookup);
			} else {
				// Handle failure
				console.error("Failed to fetch player profile options.");
			}
		} catch (error) {
			console.error("Error fetching player profile options:", error.message || error);
		}
	};

	async function saveMessage(reqObj) {
		try {
			const message = await ApiService.httpPut(
				`/StreamingConfigurations?Class=UpdateMessage&User=U00184`,
				reqObj
			);
			if (message.status) {
				toast({
					title: "Flash News!",
					description: "Flash News Updated Successfully",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			} else {
				toast({
					title: "Flash News!",
					description: "Failed to Update Flash News",
					status: "warning",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Flash News!",
				description: "Failed to Update Flash News",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	}

	if (controlsState?.length > 0) {
		return (
			<Container padding={5}>
				<Heading as="h6" size="xs" paddingBottom={10}>
					Screen Controls for Scoreboard {boardNo}
				</Heading>
				<SimpleGrid
					columns={{
						base: 1,
						md: 2,
						lg: 3,
						xl: 4,
					}}
					spacing={5}>
					{controlsState &&
						controlsState.map((group, groupIndex) => {
							const groupKey = Object.keys(group)[0];
							const controls = group[groupKey];
							if (groupKey !== "FlashNews") {
								return (
									<Box
										key={groupIndex}
										boxShadow={"lg"}
										borderRadius="lg"
										p={3}
										bgColor="white">
										<Stack direction={"column"}>
											<Text fontWeight={"bold"} fontSize={"lg"}>
												{groupKey}
											</Text>
											<Stack>
												{controls.map((c) => {
													return (
														<>
															{c.ControlCode === "TeamJourney" && (
																<Select
																	w={"fit-content"}
																	placeholder="Select a Team"
																	value={c.Property === null ? undefined : c.Property}
																	onChange={(e) =>
																		handleChange(groupKey, c, c.State, e.target.value)
																	}>
																	{teamsList?.map((team, index) => (
																		<option key={team.TETeamId} value={team.TETeamId}>
																			{team.TeamName}
																		</option>
																	))}
																</Select>
															)}
															{c.ControlCode === "PlayerProfile" && (
																<Select
																	id="player-profile"
																	value={c.Property === null ? undefined : c.Property}
																	onChange={(e) =>
																		handleChange(groupKey, c, c.State, e.target.value)
																	}
																	placeholder="Select a Player"
																	w={"fit-content"}>
																	{playersList.map((player) => (
																		<option
																			key={player.ProfPlayerId}
																			value={player.ProfPlayerId}>
																			{player.PlayerName}
																		</option>
																	))}
																</Select>
															)}
															<HStack key={c.StreamControlId}>
																<Switch
																	id={c.StreamControlId}
																	isChecked={c.State}
																	onChange={(e) =>
																		handleChange(
																			groupKey,
																			c,
																			e.target.checked,
																			c.Property
																		)
																	}
																/>
																<Text>{c.ControlName}</Text>
															</HStack>
														</>
													);
												})}
											</Stack>
										</Stack>
									</Box>
								);
							}
						})}
				</SimpleGrid>
				<Card mt={3} bg={"white"}>
					<CardHeader>
						<HStack justify={"space-between"}>
							<Heading size={"xs"} w={"fit-content"} textAlign={"center"}>
								{
									controlsState.find((group) => Object.keys(group)[0] === "FlashNews")
										.FlashNews[0].ControlName
								}
							</Heading>
							<Switch
								isChecked={
									controlsState.find((group) => Object.keys(group)[0] === "FlashNews")
										.FlashNews[0].State
								}
								onChange={(e) =>
									handleChange(
										"FlashNews",
										controlsState.find((group) => Object.keys(group)[0] === "FlashNews")
											.FlashNews[0],
										e.target.checked,
										null
									)
								}
							/>
						</HStack>
					</CardHeader>
					<CardBody py={1}>
						<form onSubmit={handleSubmit((data) => onSubmit(data))}>
							<Stack>
								<FormControl w={"fit-content"} isInvalid={errors.FormValues?.Board}>
									<FormLabel>Board</FormLabel>
									<Controller
										name="FormValues.Board"
										control={control}
										rules={{ required: "Please Select a Board" }}
										render={({ field }) => (
											<RadioButtonGroup {...field} size={"md"}>
												<RadioButton value={boardNo}>Current Board</RadioButton>
												<RadioButton value="All">All Boards</RadioButton>
											</RadioButtonGroup>
										)}
									/>
									<FormErrorMessage>
										{errors.FormValues?.Board && errors.FormValues?.Board.message}
									</FormErrorMessage>
								</FormControl>
								<FormControl isInvalid={errors.FormValues?.MessageTitle}>
									<FormLabel>Title</FormLabel>
									<Input
										type="text"
										{...register("FormValues.MessageTitle", {
											required: "Please Enter a Title",
											minLength: {
												value: 2,
												message: "Title is very short",
											},
										})}
									/>
									<FormErrorMessage>
										{errors.FormValues?.MessageTitle &&
											errors.FormValues?.MessageTitle.message}
									</FormErrorMessage>
								</FormControl>
								<FormControl isInvalid={errors.FormValues?.Message}>
									<FormLabel>Message</FormLabel>
									<Textarea
										{...register("FormValues.Message", {
											required: "Please Enter a Message",
											minLength: {
												value: 2,
												message: "Message is very short",
											},
										})}
									/>
									<FormErrorMessage>
										{errors.FormValues?.Message && errors.FormValues?.Message.message}
									</FormErrorMessage>
								</FormControl>
							</Stack>
							<CardFooter px={0} justifyContent={"end"}>
								<Button type="submit" borderRadius={"full"} colorScheme={"blue"}>
									Submit
								</Button>
							</CardFooter>
						</form>
					</CardBody>
				</Card>
			</Container>
		);
	} else {
		return (
			<Text fontSize={"lg"} alignSelf={"center"}>
				No Stream Controls Available for this Board
			</Text>
		);
	}
}
