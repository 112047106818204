import {
	HStack,
	Table,
	Tbody,
	Td,
	Box,
	Tr,
	Th,
	Thead,
	FormControl,
	Select,
	useBreakpointValue,
	Text,
	Divider,
	useColorModeValue,
	ButtonGroup,
	Button,
	useToast,
} from "@chakra-ui/react";
import ApiService from "../../../service/Api.service";
import _ from "lodash";
import { useState } from "react";

export const PlayerSelectionTable = ({
	plyrSelData,
	plyrSelPositions,
	currTeamId,
	initialPlayers,
	selectedPlayers,
	setInitialPlayers,
	setSelectedPlayers,
	setReload,
	setEditMode,
	editMode,
	isEditable,
}) => {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	// Mobile Breakpoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
		lg: false,
		xl: false,
	});
	// Tablet Breakpoint Value
	const isTablet = useBreakpointValue({
		base: false,
		sm: false,
		md: true,
		lg: false,
		xl: false,
	});

	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");

	let toast = useToast();

	const [saveLoad, setSaveLoad] = useState(false);

	const playerSelector = (
		player,
		roundId,
		position,
		matchType,
		roundNo,
		playerPos
	) => {
		let tempObj = JSON.parse(player);
		if (
			matchType !== "Singles" &&
			selectedPlayers.findIndex((round) => round.RoundTeamId === roundId) > -1
		) {
			let selectedRoundData = {
				...selectedPlayers.find((round) => round.RoundTeamId === roundId),
			};
			if (playerPos === "Player2") {
				selectedRoundData["Player2Id"] = tempObj.PlayerId;
				selectedRoundData["Player2Name"] = tempObj.PlayerName;
				selectedRoundData["Player2Gender"] = tempObj.Gender;
			} else {
				selectedRoundData["Player1Id"] = tempObj.PlayerId;
				selectedRoundData["Player1Name"] = tempObj.PlayerName;
				selectedRoundData["Player1Gender"] = tempObj.Gender;
			}
			console.log("Selected Round Data", selectedRoundData);
			if (
				selectedPlayers?.length > 0 &&
				selectedPlayers.some((ele) =>
					ele.Position === selectedRoundData.Position ? true : false
				) > 0
			) {
				let newArray = selectedPlayers?.map((player, index) => {
					if (player.Position === selectedRoundData.Position) {
						return {
							...player,
							...selectedRoundData,
						};
					}
					return player;
				});
				setSelectedPlayers(newArray);
			}
		} else {
			let obj;
			if (playerPos === "Player2") {
				obj = {
					Player2Id: tempObj.PlayerId,
					Player2Name: tempObj.PlayerName,
					Player2Gender: tempObj.Gender,
				};
			} else {
				obj = {
					Player1Id: tempObj.PlayerId,
					Player1Name: tempObj.PlayerName,
					Player1Gender: tempObj.Gender,
				};
			}
			obj.RoundTeamId = roundId;
			obj.Position = position;
			obj.MatchType = matchType;
			obj.RoundNo = roundNo;
			if (
				selectedPlayers?.length > 0 &&
				selectedPlayers.some((ele) =>
					ele.Position === obj.Position ? true : false
				) > 0
			) {
				let newArray = selectedPlayers?.map((player, index) => {
					if (player.Position === obj.Position) {
						return {
							...player,
							Player1Id: obj.Player1Id,
							Player1Name: obj.Player1Name,
							Player1Gender: obj.Gender,
						};
					}
					return player;
				});
				setSelectedPlayers(newArray);
			} else {
				setSelectedPlayers([...selectedPlayers, obj]);
			}
		}
	};

	const savePlayers = async () => {
		setSaveLoad(true);
		let doublesErr = 0;
		let genderErr = 0;

		selectedPlayers.forEach((ele) => {
			if (ele.Position === "Doubles - 2") {
				const isPlayer1Female = ele.Player1Gender === "Female";
				const isPlayer2Female = ele.Player2Gender === "Female";

				if (!isPlayer1Female && !isPlayer2Female) {
					genderErr = genderErr + 1;
				}
			}

			if (ele.MatchType === "Doubles") {
				if (ele.Player1Id === "" && ele.Player2Id === "") {
				} else if (ele.Player1Id === "" || ele.Player2Id === "") {
					doublesErr = doublesErr + 1;
				}
			}
		});

		if (genderErr > 0) {
			toast({
				title: "Save Player Selection",
				description: "For Doubles - 2, at least one player must be Female.",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			genderErr = 0;
			setSaveLoad(false);
		} else if (doublesErr > 0) {
			toast({
				title: "Save Player Selection",
				description: "Select Two Players for Doubles Event.",
				status: "warning",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			doublesErr = 0;
			setSaveLoad(false);
		} else {
			try {
				let reqObj = { TETeamId: currTeamId, Rounds: selectedPlayers };
				let savedPlayersRes = await ApiService.httpPut(
					`/TeamsRoundPlayers?Class=Rounds`,
					reqObj
				);
				if (savedPlayersRes.status === true) {
					toast({
						title: "Save Player Selection",
						description: "Saved Selected Players successfully",
						status: "success",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					console.log("Saved Players Response:", savedPlayersRes);
					setInitialPlayers([]);
					setSelectedPlayers([]);
					setReload(true);
				} else if (savedPlayersRes.status === false) {
					toast({
						title: "Save Player Selection",
						description: savedPlayersRes?.Message,
						status: "warning",
						duration: 5000,
						isClosable: true,
						position: "top-right",
					});
				} else {
					toast({
						title: "Save Player Selection",
						description: "Failed to Save Selected Players",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
				}
				setSaveLoad(false);
			} catch (err) {
				console.log(err);
				toast({
					title: "Save Player Selection",
					description: "Failed to Save Selected Players",
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				setSaveLoad(false);
			} finally {
				setSaveLoad(false);
			}
		}
	};
	console.log("Initial", initialPlayers);
	console.log("Selected", selectedPlayers);

	const tableDesktop = () => (
		<Box>
			<Box borderTopRadius={"2xl"} boxShadow={shadow}>
				<Table variant="striped">
					<Thead position="sticky" top={0} bgColor="black" zIndex="overlay">
						<Tr>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={3}
								py={4}
								{...(isMobile && { px: 2, fontSize: "xs" })}
								textAlign={"start"}
								borderTopLeftRadius="2xl">
								Position
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={3}
								py={4}
								{...(isMobile && { px: 2, fontSize: "xs" })}
								textAlign={"start"}>
								Player Name
							</Th>
							<Th
								textColor="white"
								fontSize={isTablet ? "sm" : "md"}
								px={3}
								py={4}
								{...(isMobile && { px: 2, fontSize: "xs" })}
								textAlign={"start"}
								borderTopRightRadius="2xl"></Th>
						</Tr>
					</Thead>
					<Tbody>
						{plyrSelData?.Rounds?.map((position, index) => (
							<Tr key={position.RoundTeamId}>
								<Td
									textAlign="start"
									fontSize={isTablet ? "md" : "lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1.5,
										fontSize: "sm",
									})}
									{...(isTablet && {
										px: 2,
										fontSize: "md",
									})}
									whiteSpace={"normal"}>
									{position.Position}
								</Td>
								<Td
									align="start"
									fontSize={isTablet ? "md" : "lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1.5,
										fontSize: "sm",
									})}
									{...(isTablet && {
										px: 2,
										fontSize: "md",
									})}>
									{editMode === true && isEditable === 1 ? (
										<FormControl textAlign="start">
											<Select
												defaultValue={JSON.stringify({
													PlayerId: position.Player1Id,
													PlayerName: position.Player1Name,
												})}
												onChange={(e) => {
													const selectedPlayer = JSON.parse(e.target.value);
													const player2Gender = selectedPlayer?.Gender;
													const player1Gender = selectedPlayers.find(
														(ele) => ele.Position === position?.Position
													)?.Player1Gender;
													if (
														position.Position === "Doubles - 2" &&
														player1Gender === "Male" &&
														player2Gender === "Male"
													) {
														toast({
															title: "Invalid Player Selection",
															description:
																"At least one player must be female in doubles matches.",
															status: "error",
															duration: 3000,
															isClosable: true,
															position: "top-right",
														});
														return;
													}
													playerSelector(
														e.target.value,
														position.RoundTeamId,
														position?.Position,
														position.MatchType,
														position.RoundNo,
														"Player1"
													);
												}}>
												<option
													value={JSON.stringify({
														PlayerId: "",
														PlayerName: "",
													})}>
													{selectedPlayers?.find(
														(ele) => ele.Position === position?.Position
													)?.Player1Id &&
													selectedPlayers?.find(
														(ele) => ele.Position === position?.Position
													)?.Player1Id.length > 0
														? "---Unselect the Player---"
														: "Select a Player"}
												</option>
												{plyrSelPositions[0]?.Players?.map((players, index) => {
													return (
														<option
															hidden={selectedPlayers?.some(
																(ele) =>
																	ele.Player1Id === players.PlayerId ||
																	ele.Player2Id === players.PlayerId
															)}
															key={players.PlayerId}
															value={JSON.stringify(players)}>
															{players.PlayerName}
														</option>
													);
												})}
											</Select>
										</FormControl>
									) : (
										<Text fontSize="lg" fontWeight="medium">
											{position.Player1Name}
										</Text>
									)}
								</Td>
								<Td
									textAlign="-webkit-center"
									fontSize={isTablet ? "md" : "lg"}
									fontWeight="medium"
									{...(isMobile && {
										px: 1.5,
										fontSize: "sm",
									})}
									{...(isTablet && {
										px: 2,
										fontSize: "md",
									})}>
									{position.MatchType !== "Singles" &&
									editMode === true &&
									isEditable === 1 ? (
										<FormControl textAlign="start">
											<Select
												defaultValue={JSON.stringify({
													PlayerId: position.Player2Id,
													PlayerName: position.Player2Name,
												})}
												onChange={(e) => {
													const selectedPlayer = JSON.parse(e.target.value);
													const player2Gender = selectedPlayer?.Gender;
													const player1Gender = selectedPlayers.find(
														(ele) => ele.Position === position?.Position
													)?.Player1Gender;
													if (
														position.Position === "Doubles - 2" &&
														player1Gender === "Male" &&
														player2Gender === "Male"
													) {
														toast({
															title: "Invalid Player Selection",
															description:
																"At least one player must be female in doubles matches.",
															status: "error",
															duration: 3000,
															isClosable: true,
															position: "top-right",
														});
														return;
													}
													playerSelector(
														e.target.value,
														position.RoundTeamId,
														position?.Position,
														position.MatchType,
														position.RoundNo,
														"Player2"
													);
												}}>
												<option
													value={JSON.stringify({
														PlayerId: "",
														PlayerName: "",
													})}>
													{selectedPlayers?.find(
														(ele) => ele.Position === position?.Position
													)?.Player2Id &&
													selectedPlayers?.find(
														(ele) => ele.Position === position?.Position
													)?.Player2Id.length > 0
														? "---Unselect the Player---"
														: "Select a Player"}
												</option>
												{plyrSelPositions[0]?.Players?.map((players, index) => {
													return (
														<option
															hidden={selectedPlayers?.some(
																(ele) =>
																	ele.Player1Id === players.PlayerId ||
																	ele.Player2Id === players.PlayerId
															)}
															key={players.PlayerId}
															value={JSON.stringify(players)}>
															{players.PlayerName}
														</option>
													);
												})}
											</Select>
										</FormControl>
									) : (
										<Text fontSize="lg" fontWeight="medium">
											{position.Player2Name}
										</Text>
									)}
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
			<Divider />
			<HStack justify="end" p="1rem" borderBottomRadius={"2xl"}>
				<ButtonGroup spacing="3">
					{!saveLoad &&
						(editMode && isEditable === 1 ? (
							<Button
								size="lg"
								borderRadius="full"
								colorScheme="red"
								onClick={() => setEditMode(false)}>
								Cancel
							</Button>
						) : editMode === false && isEditable === 1 ? (
							""
						) : (
							selectedPlayers.every((ele) =>
								ele.MatchType === "Singles"
									? ele.Player1Id !== ""
									: ele.Player1Id !== "" && ele.Player2Id !== ""
							) &&
							isEditable === 1 && (
								<Button
									size="lg"
									borderRadius="full"
									colorScheme="red"
									onClick={() => setEditMode(false)}>
									Cancel
								</Button>
							)
						))}
					{editMode && isEditable === 1 && (
						<Button
							type="submit"
							size="lg"
							borderRadius="full"
							colorScheme="blue"
							onClick={savePlayers}
							isLoading={saveLoad}
							isDisabled={_.isEqual(initialPlayers, selectedPlayers) === true}>
							Save
						</Button>
					)}
					{/* {editMode === false && isEditable === 1 ? (
						<Button
							type="submit"
							size="lg"
							borderRadius="full"
							colorScheme="blue"
							onClick={() => setEditMode(true)}>
							Edit Players
						</Button>
					) : (
						selectedPlayers.every((ele) =>
							ele.MatchType === "Singles"
								? ele.Player1Id !== ""
								: ele.Player1Id !== "" && ele.Player2Id !== ""
						) &&
						isEditable === 1 && (
							<Button
								type="submit"
								size="lg"
								borderRadius="full"
								colorScheme="blue"
								onClick={() => setEditMode(false)}>
								View Players
							</Button>
						)
					)} */}
				</ButtonGroup>
			</HStack>
		</Box>
	);

	return tableDesktop();
};
