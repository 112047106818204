import { HStack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import Marquee from "react-fast-marquee";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";

export const FlashNews = ({ eventId, boardNo }) => {
	// User Details
	const user = useAuthProvider();

	const [flashMessage, setFlashMessage] = useState(null);
	useEffect(() => {
		async function fetchMessage() {
			try {
				let message = await ApiService.httpGet(
					`/StreamingConfigurations?TourEventId=${eventId}&Board=${boardNo}&Class=getMessage`,
					user.userId
				);
				if (message.status === true) {
					setFlashMessage(message);
				}
			} catch (err) {
				console.log(err);
			}
		}
		fetchMessage();
	}, [eventId, boardNo]);
	if (flashMessage !== null) {
		return (
			<HStack
				bg={"white"}
				borderRadius={"2xl"}
				minWidth={"60%"}
				maxWidth={"80%"}
				marginBottom={2}
				alignSelf={"center"}
				zIndex={3}>
				<Text
					fontWeight={"bold"}
					px={3}
					margin={1}
					textColor={"white"}
					bg={"#F7002A"}
					w="fit-content"
					whiteSpace={"nowrap"}
					borderRadius={"2xl"}>
					{flashMessage.MessageTitle}
				</Text>
				<Text noOfLines={2}>{flashMessage.Message}</Text>
			</HStack>
		);
	}
};
