import React, { useState, useEffect } from "react";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";
import { Table } from "../../../Components/V2/TableWrapper";

const PlayerStandingsTable = ({ eventId, groupname, roundNo }) => {
	const user = useAuthProvider();
	const [playerStandings, setPlayerStandings] = useState([]);
	const [standingsRound, setStandingsRound] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const displayedPlayerStandings = playerStandings?.slice(startIndex, endIndex);

	async function fetchPlayerStandings() {
		try {
			let playerStand = await ApiService.httpGet(
				`/TourStandings?TourEventId=${eventId}&GroupName=${groupname}&RoundNo=${roundNo}`,
				user.userId
			);
			if (playerStand.status === true) {
				setPlayerStandings(playerStand.TourStandings);
				setStandingsRound(playerStand.RoundNo);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		fetchPlayerStandings();
	}, [eventId, groupname, roundNo]);

	useEffect(() => {
		const showNextValues = () => {
			if (endIndex < playerStandings?.length) {
				setCurrentPage(currentPage + 1);
			} else {
				setCurrentPage(1);
			}
		};

		const timer = setTimeout(showNextValues, 5000);
		const refreshTimer = setInterval(fetchPlayerStandings, 5000);

		return () => {
			clearTimeout(timer);
			clearInterval(refreshTimer);
		};
	}, [currentPage, endIndex, playerStandings?.length]);

	if (!playerStandings?.length) return null;

	const columns = [
		{
			key: "Position",
			title: "Position",
			align: "center",
			width: "10%",
		},
		{
			key: "TeamName",
			title: "Player Name",
			align: "left",
			width: "25%",
		},
		{
			key: "MatchesPlayed",
			title: "Matches Played",
			align: "center",
			width: "15%",
		},
		{
			key: "Points",
			title: "Points",
			align: "center",
			width: "15%",
		},
		{
			key: "OppStrength",
			title: "Opp Strength",
			align: "center",
			width: "15%",
		},
		{
			key: "PointsScored",
			title: "Score",
			align: "center",
			width: "15%",
		},
	];

	return (
		<div className="animate-in fade-in zoom-in duration-300">
			<div className=" mx-auto h-auto lg:w-[95vw] sm:w-[95vw] overflow-y-auto rounded-2xl bg-white  overflow-hidden">
				<div className="px-4 lg:py-2 ">
					<div className="flex min-h-[75px] sm:min-h-[60px] min-w-[500px] items-center justify-center flex-row gap-2">
						<h2 className="w-fit text-center text-xl font-semibold text-black">
							PLAYER STANDINGS
						</h2>
						{standingsRound > 0 && (
							<h4 className="w-fit text-center text-sm font-bold text-white bg-green-600 px-2 py-1 rounded-full">
								AFTER ROUND {standingsRound}
							</h4>
						)}
					</div>
				</div>
				<Table
					data={displayedPlayerStandings?.slice(0, 10)}
					columns={columns}
					wrapperClassName="px-0"
					className="w-full bg-white"
					cellClassName="h-2"
					headerClassName="w-full text-center"
					zebra={true}
					rounded={true}
					bordered={true}
					hover={false}
					animate={false}
					sortable={false}
				/>
			</div>
		</div>
	);
};

export default PlayerStandingsTable;
