import React, { useState, useEffect } from "react";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";

export const DaySchedule = ({ tourId }) => {
	const user = useAuthProvider();
	const [scheduleTable, setScheduleTable] = useState(null);
	
	async function fetchDaySchedule() {
		try {
			let scheduleResp = await ApiService.httpGet(
				`/StreamingConfigurations?Class=DaySchedule&TourId=${tourId}`,
				user.userId
			);
			if (scheduleResp.status === true) {
				setScheduleTable(scheduleResp.Schedule);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		fetchDaySchedule();
	}, [tourId]);

	if (!scheduleTable?.length > 0) return null;

	return (
		<div className="bg-white rounded-3xl lg:p-6 sm:p-2 lg:w-[95vw] mx-auto h-auto sm:w-[95vw]">
			<h2 className="lg:text-xl sm:text-xl font-semibold text-center lg:mb-6 sm:mb-2">
				Today's Schedule
			</h2>

			{/* Header */}
			<div className="grid grid-cols-4 gap-4 mb-4">
				<div className="bg-[#4A38B7] text-white lg:p-2 sm:p-1 rounded-full   font-medium sm:text-sm text-center whitespace-nowrap">
					Start Time
				</div>
				<div className="bg-[#4A38B7] text-white lg:p-2 sm:p-1 rounded-full  font-medium sm:text-sm  text-center whitespace-nowrap">
					End Time
				</div>
				<div className="bg-[#4A38B7] text-white lg:p-2 sm:p-1 rounded-full w-[45vw] font-medium sm:text-sm  text-center whitespace-nowrap">
					Description
				</div>
			</div>

			{/* Content */}
			<div className="space-y-2">
				{scheduleTable.map((item, index) => (
					<div key={index}>
						{item.Type === "B" ? (
							<div className="grid grid-cols-4 gap-4">
								<div className="bg-indigo-50 lg:p-2 sm:p-1  rounded-full"></div>
								<div className="bg-indigo-50 lg:p-2 sm:p-1 rounded-full"></div>
								<div className="bg-indigo-50 lg:p-2 sm:p-1 rounded-full"></div>
								<div className="bg-indigo-50 lg:p-2 sm:p-1 lg:w-[22vw] rounded-full text-center">
									{item.Description}
								</div>
							</div>
						) : (
							<div className="grid grid-cols-4 gap-4">
								<div className="bg-gray-50 lg:p-2 sm:p-1 rounded-full text-center items-center flex justify-center sm:text-sm">
									{item.StartTime}
								</div>
								<div className="bg-gray-50 lg:p-2 sm:p-1 rounded-full text-center items-center flex justify-center sm:text-sm ">
									{item.EndTime}
								</div>
								<div className="bg-gray-50 lg:p-2 sm:p-1 rounded-full w-[45vw]  text-center whitespace-nowrap sm:text-sm">
									{item.Description}
								</div>
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default DaySchedule;
