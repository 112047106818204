import React, { useState, useEffect } from "react";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";

export const TourSchedule = ({ tourId }) => {
	const user = useAuthProvider();
	const [scheduleTable, setScheduleTable] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10; // Number of items to show per page

	async function fetchTourSchedule() {
		try {
			let scheduleResp = await ApiService.httpGet(
				`/StreamingConfigurations?Class=TourSchedule&TourId=${tourId}`,
				user.userId
			);
			if (scheduleResp.status === true) {
				setScheduleTable(scheduleResp.Schedule);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		fetchTourSchedule();
	}, [tourId]);

	// Auto-rotation effect
	useEffect(() => {
		if (!scheduleTable?.length) return;

		const totalPages = Math.ceil(scheduleTable.length / itemsPerPage);
		const timer = setInterval(() => {
			setCurrentPage((prev) => (prev === totalPages ? 1 : prev + 1));
		}, 10000); // Rotate every 10 seconds

		return () => clearInterval(timer);
	}, [scheduleTable]);

	if (!scheduleTable?.length > 0) return null;

	// Get current page items
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = scheduleTable.slice(indexOfFirstItem, indexOfLastItem);

	// Calculate total pages
	const totalPages = Math.ceil(scheduleTable.length / itemsPerPage);

	return (
		<div className="bg-white rounded-3xl lg:p-6  sm:p-2 w-[92vw] sm:w-[95vw] lg:h-auto">
			<h2 className="text-xl sm:text-md font-semibold text-center mb-3">
				Tournament Schedule
			</h2>
			{/* Header */}
			<div className="grid grid-cols-4 gap-4 mb-4">
				<div className="bg-[#4A38B7] text-white p-2  rounded-full font-medium sm:text-sm lg:text-md text-center">
					Activity
				</div>
				<div className="bg-[#4A38B7] text-white p-2 rounded-full font-medium sm:text-sm lg:text-md text-center">
					Date
				</div>
				<div className="bg-[#4A38B7] text-white p-2   rounded-full font-medium sm:text-sm lg:text-md text-center">
					Start Time
				</div>
				<div className="bg-[#4A38B7] text-white p-2   rounded-full font-medium sm:text-sm lg:text-md text-center">
					End Time
				</div>
			</div>

			{/* Content */}
			<div className="space-y-1">
				{currentItems.map((item, index) => (
					<div key={index}>
						{item.Type === "B" ? (
							<div className="grid grid-cols-4 gap-4">
								<div className="bg-indigo-50 p-2 rounded-full text-center">
									{" "}
									{item.Description}
								</div>
								<div className="bg-indigo-50 p-2   rounded-full"></div>
								<div className="bg-indigo-50 p-2   rounded-full"></div>
								<div className="bg-indigo-50 p-2  rounded-full text-center"></div>
							</div>
						) : (
							<div className="grid grid-cols-4 gap-4">
								<div className="bg-gray-50 p-2 rounded-full text-center sm:text-sm lg:text-md inline-block items-center justify-center  break-words  sm:break-words sm:whitespace-nowrap sm:overflow-hidden text-ellipsis">
									{item.Description}
								</div>
								<div className="bg-gray-50 p-2 rounded-full text-center sm:text-sm lg:text-md items-center flex justify-center sm:whitespace-nowrap">
									{item.Date}
								</div>
								<div className="bg-gray-50 p-2 rounded-full text-center sm:text-sm lg:text-md items-center flex justify-center sm:whitespace-nowrap">
									{item.StartTime}
								</div>
								<div className="bg-gray-50 p-2  rounded-full text-center sm:text-sm lg:text-md items-center flex justify-center sm:whitespace-nowrap">
									{item.EndTime}
								</div>
							</div>
						)}
					</div>
				))}
			</div>

			{/* Page Numbers */}
			{/* <div className="flex justify-center items-center gap-2 mt-8">
				{Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
					<div
						key={page}
						className={`
              w-8 h-8 rounded-full flex items-center justify-center text-sm
              ${page === currentPage
								? 'bg-[#4A38B7] text-white'
								: 'bg-indigo-50'
							}
            `}>
						{page.toString().padStart(2, '0')}
					</div>
				))}
			</div> */}
		</div>
	);
};

export default TourSchedule;
