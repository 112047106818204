import { Icon } from "@chakra-ui/react";
import React from "react";
import { GiTrophy } from "react-icons/gi";

const Scoreboard = ({ boardData }) => {
	const getInitials = (name) => {
		if (!name) return "??";
		return name
			.split(" ")
			.map((word) => word[0])
			.join("")
			.toUpperCase()
			.substring(0, 2);
	};

	const getSetScores = (player) => {
		const sets = [];
		if (boardData?.set1Display === "true") {
			sets.push({
				score: player === "A" ? boardData.playerASet1 : boardData.playerBSet1,
				color: "#008000",
				opacityy:
					player === "A"
						? boardData.isPlayerASet1Low
							? 0.4
							: 1
						: boardData.isPlayerBSet1Low
						? 0.4
						: 1,
			});
		}
		if (boardData?.set2Display === "true") {
			sets.push({
				score: player === "A" ? boardData.playerASet2 : boardData.playerBSet2,
				color: "#008000",
				opacityy:
					player === "A"
						? boardData.isPlayerASet2Low
							? 0.4
							: 1
						: boardData.isPlayerBSet2Low
						? 0.4
						: 1,
			});
		}
		if (boardData?.set3Display === "true") {
			sets.push({
				score: player === "A" ? boardData.playerASet3 : boardData.playerBSet3,
				color: "#4A38B7",
				opacityy:
					player === "A"
						? boardData.isPlayerASet3Low
							? 0.4
							: 1
						: boardData.isPlayerBSet3Low
						? 0.4
						: 1,
			});
		}
		return sets;
	};

	return (
		<div className="container m-0 bg-[#4A38B7] min-h-[10vh]  max-h-[25vh] flex  items-center justify-between rounded-2xl">
			{/* Left Section */}
			<div className="flex items-center space-x-2 w-[30vw]">
				<div className="flex flex-row items-center gap-1">
					{/* Player A Image */}
					<div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
						{boardData?.PlayersData?.TeamAPlayer1ImageLink?.substring(0, 9) ===
						"undefined" ? (
							<div className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium">
								{getInitials(boardData.PlayersData.TeamAPlayer1Name)}
							</div>
						) : (
							<img
								src={`https://caromblob.blob.core.windows.net/images/Players/${boardData?.PlayersData?.TeamAPlayer1ImageLink}`}
								alt={boardData?.playerA}
								className="w-12 h-12 rounded-full"
								onError={(e) => {
									e.target.style.display = "none";
									e.target.parentElement.innerHTML = `<div class="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium">${getInitials(
										boardData.PlayersData.TeamAPlayer1Name
									)}</div>`;
								}}
							/>
						)}
					</div>

					{/* Player B Image */}
					{boardData?.PlayersData?.MatchType === "Doubles" && (
						<div className="w-12 h-12 bg-white/20 rounded-full flex items-center justify-center">
							{boardData?.PlayersData?.TeamAPlayer2ImageLink?.substring(0, 9) ===
							"undefined" ? (
								<div className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium">
									{getInitials(boardData.PlayersData.TeamAPlayer2Name)}
								</div>
							) : (
								<img
									src={`https://caromblob.blob.core.windows.net/images/Players/${boardData?.PlayersData?.TeamAPlayer2ImageLink}`}
									alt={boardData?.playerA}
									className="w-12 h-12 rounded-full"
									onError={(e) => {
										e.target.style.display = "none";
										e.target.parentElement.innerHTML = `<div class="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium">${getInitials(
											boardData.PlayersData.TeamAPlayer2Name
										)}</div>`;
									}}
								/>
							)}
						</div>
					)}
				</div>
				<div className="flex flex-col text-balance">
					{/* <span className="text-white text-xs md:text-sm xl:text-lg font-sans opacity-75">{boardData?.groupname}</span> */}
					<span className="text-white text-xs md:text-sm xl:text-lg font-sans font-semibold">
						{boardData?.tournamentname}
					</span>
				</div>
			</div>

			{/* Center Section */}
			<div className="w-[40vw] z-[2] overflow-y-hidden ">
				<div className="relative top-[-1.85rem] sm:h-[4.5rem] md:h-[5rem] lg:h-[5.5rem] xl:h-[6rem] flex items-center justify-center">
					<div
						className="absolute w-[100%] h-[200px]  bg-[#FFFF] p-3 flex justify-center items-center"
						style={{ clipPath: "polygon(20% 0, 80% 0, 100% 100%, 0 100%)" }}>
						<div className="grid grid-cols-2 gap-0 xl:gap-2 mt-[60px]  items-center  w-[80%] lg:max-w-[80%] ">
							{["A", "B"].map((team, index) => (
								<React.Fragment key={team}>
									<div className="text-gray-900 text-xs lg:text-sm xl:text-[17px] font-medium font-sans  ">
										<h3 className="flex flex-row items-center gap-1 whitespace-nowrap overflow-hidden">
											{team === "A" ? boardData?.playerA : boardData?.playerB}{" "}
											{team === "A" && boardData.isPlayerAWon && <Icon as={GiTrophy} />}{" "}
											{team === "B" && boardData.isPlayerBWon && <Icon as={GiTrophy} />}
										</h3>
										<p className="text-xs opacity-75 italic">
											{team === "A"
												? boardData.TeamsConfigs?.Team1Name
												: boardData.TeamsConfigs?.Team2Name}
										</p>
									</div>
									<div className="flex items-center justify-end gap-1 lg:gap-1 ">
										{team === "A" && boardData.isPlayerACurrentStrike && (
											<span className=" text-xs ">
												<svg
													width="10"
													height="18"
													viewBox="0 0 10 18"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M9.94501 2.35704C9.94579 2.24087 9.92297 2.12575 9.87794 2.01866C9.8329 1.91157 9.76659 1.81474 9.68301 1.73404L9.13601 1.20604C8.96174 1.04075 8.7307 0.948608 8.49051 0.948608C8.25032 0.948608 8.01928 1.04075 7.84501 1.20604L0.411011 8.37704C0.326866 8.45794 0.259923 8.555 0.214195 8.66239C0.168468 8.76979 0.144897 8.88531 0.144897 9.00204C0.144897 9.11877 0.168468 9.23429 0.214195 9.34169C0.259923 9.44908 0.326866 9.54614 0.411011 9.62704L7.83801 16.791C8.01228 16.9563 8.24332 17.0485 8.48351 17.0485C8.7237 17.0485 8.95474 16.9563 9.12901 16.791L9.67601 16.264C10.032 15.919 9.94501 2.59204 9.94501 2.35704Z"
														fill="#4A38B7"
													/>
												</svg>
											</span>
										)}

										{team === "B" && boardData.isPlayerBCurrentStrike ? (
											<span className="text-xs">
												<svg
													width="10"
													height="14"
													viewBox="0 0 10 18"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M9.94501 2.35704C9.94579 2.24087 9.92297 2.12575 9.87794 2.01866C9.8329 1.91157 9.76659 1.81474 9.68301 1.73404L9.13601 1.20604C8.96174 1.04075 8.7307 0.948608 8.49051 0.948608C8.25032 0.948608 8.01928 1.04075 7.84501 1.20604L0.411011 8.37704C0.326866 8.45794 0.259923 8.555 0.214195 8.66239C0.168468 8.76979 0.144897 8.88531 0.144897 9.00204C0.144897 9.11877 0.168468 9.23429 0.214195 9.34169C0.259923 9.44908 0.326866 9.54614 0.411011 9.62704L7.83801 16.791C8.01228 16.9563 8.24332 17.0485 8.48351 17.0485C8.7237 17.0485 8.95474 16.9563 9.12901 16.791L9.67601 16.264C10.032 15.919 9.94501 2.59204 9.94501 2.35704Z"
														fill="#4A38B7"
													/>
												</svg>
											</span>
										) : (
											<span
												className={`text-xs mt-2 ${
													team === "A" && boardData?.isPlayerACurrentStrike
														? "hidden"
														: ""
												}`}>
												<svg
													width="10"
													height="14"
													viewBox="0 0 10 18"
													className="invisible mt-1"
													fill="none"
													xmlns="http://www.w3.org/2000/svg">
													<path
														d="M9.94501 2.35704C9.94579 2.24087 9.92297 2.12575 9.87794 2.01866C9.8329 1.91157 9.76659 1.81474 9.68301 1.73404L9.13601 1.20604C8.96174 1.04075 8.7307 0.948608 8.49051 0.948608C8.25032 0.948608 8.01928 1.04075 7.84501 1.20604L0.411011 8.37704C0.326866 8.45794 0.259923 8.555 0.214195 8.66239C0.168468 8.76979 0.144897 8.88531 0.144897 9.00204C0.144897 9.11877 0.168468 9.23429 0.214195 9.34169C0.259923 9.44908 0.326866 9.54614 0.411011 9.62704L7.83801 16.791C8.01228 16.9563 8.24332 17.0485 8.48351 17.0485C8.7237 17.0485 8.95474 16.9563 9.12901 16.791L9.67601 16.264C10.032 15.919 9.94501 2.59204 9.94501 2.35704Z"
														fill="#4A38B7"
													/>
												</svg>
											</span>
										)}
										{getSetScores(team).map((set, setIndex) => {
											console.log(set.opacityy); // Correctly placed inside a block
											return (
												<div
													key={setIndex}
													className="rounded-md text-white text-sm h-6 w-12 text-center font-bold flex items-center justify-center"
													style={{
														backgroundColor: set.color || "#4A38B7",
														opacity: set.opacityy || 1, // Default to 1 if opacityy is undefined
													}}>
													{set.score || 0}
												</div>
											);
										})}
									</div>

									{index === 0 && (
										<div className="col-span-2 h-[1px] bg-gray-200 my-1 "></div>
									)}
								</React.Fragment>
							))}
						</div>
					</div>
				</div>
			</div>

			{/* Right Section */}
			<div className="flex items-center justify-end space-x-2 w-[30vw]">
				<div className="flex flex-col text-balance text-right">
					<span className="text-white text-xs md:text-sm xl:text-lg font-sans opacity-75">
						Board - {boardData?.board}
					</span>
					<span className="text-white text-xs md:text-sm xl:text-lg font-sans font-semibold">
						{boardData?.round}
					</span>
				</div>
				<div className="flex flex-row items-center gap-1">
					{/* Player A Image */}
					<div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
						{boardData?.PlayersData?.TeamBPlayer1ImageLink?.substring(0, 9) ===
						"undefined" ? (
							<div className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium">
								{getInitials(boardData.PlayersData.TeamBPlayer1Name)}
							</div>
						) : (
							<img
								src={`https://caromblob.blob.core.windows.net/images/Players/${boardData?.PlayersData?.TeamBPlayer1ImageLink}`}
								alt={boardData?.playerA}
								className="w-12 h-12 rounded-full"
								onError={(e) => {
									e.target.style.display = "none";
									e.target.parentElement.innerHTML = `<div class="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium">${getInitials(
										boardData.PlayersData.TeamBPlayer1Name
									)}</div>`;
								}}
							/>
						)}
					</div>

					{/* Player B Image */}
					{boardData?.PlayersData?.MatchType === "Doubles" && (
						<div className="w-12 h-12 bg-white/20 rounded-full flex items-center justify-center">
							{boardData?.PlayersData?.TeamBPlayer2ImageLink?.substring(0, 9) ===
							"undefined" ? (
								<div className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium">
									{getInitials(boardData.PlayersData.TeamBPlayer2Name)}
								</div>
							) : (
								<img
									src={`https://caromblob.blob.core.windows.net/images/Players/${boardData?.PlayersData?.TeamBPlayer2ImageLink}`}
									alt={boardData?.playerA}
									className="w-12 h-12 rounded-full"
									onError={(e) => {
										e.target.style.display = "none";
										e.target.parentElement.innerHTML = `<div class="w-12 h-12 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium">${getInitials(
											boardData.PlayersData.TeamBPlayer2Name
										)}</div>`;
									}}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Scoreboard;
