import React, { useEffect, useState } from "react";
import ApiService from "../../../service/Api.service";
import { playerImgDir } from "../../../Utilities/ImageLinks";
import { useAuthProvider } from "../../../context/AuthProvider";
import { Table } from "../../../Components/V2/TableWrapper";
import { Avatar } from "@chakra-ui/react";
import { teamImgDir } from "../../../Utilities/ImageLinks";

export const TeamJourney = ({ teamId, roundNo, overlaysState }) => {
	// User Details
	const user = useAuthProvider();

	const [teamJourney, setTeamJourney] = useState(null);
	const [standingsRound, setStandingsRound] = useState(0);

	async function fetchTeamJourney() {
		try {
			let journeyResp = await ApiService.httpGet(
				`/TeamEventTeams?Class=getTeamJourney&TETeamId=${teamId}`,
				user.userId
			);
			if (journeyResp.status === true) {
				setTeamJourney(journeyResp);
				setStandingsRound(journeyResp.RoundNo);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		fetchTeamJourney();
	}, [teamId, roundNo, overlaysState]);

	if (!teamJourney) return null;

	const columns = [
		{ key: "RoundName", title: "Round", align: "left" },
		{ key: "Opponent", title: "Opponent", align: "left" },
		{
			key: "Result",
			title: "Result",
			align: "center",
			render: (value) => (
				<span className={value === "Lost" ? "text-red-500" : "text-green-500"}>
					{value}
				</span>
			),
		},
		{ key: "Singles-1", title: "Singles-1", align: "center" },
		{ key: "Singles-2", title: "Singles-2", align: "center" },
		{ key: "Singles-3", title: "Singles-3", align: "center" },
		{ key: "Doubles-1", title: "Doubles-1", align: "center" },
		{ key: "Doubles-2", title: "Doubles-2", align: "center" },
	];

	return (
		<div className="flex flex-col items-center lg:w-[95vw] sm:w-[90vw] bg-white  rounded-2xl  lg:p-4 sm:p-2 h-auto overflow-y-auto">
			<h2 className="text-2xl font-bold tracking-tight mb-2">Team Journey</h2>
			<div className="flex items-center justify-between w-full">
				{/* Avatar and Team Name on the Left */}
				<div className="flex items-center">
					<Avatar
						borderWidth="6px"
						borderColor={"white"}
						size="xl"
						src={`${teamImgDir}${teamJourney.TeamLogoLink}`}
						name={teamJourney.TeamName}
					/>
					<span className="text-xl font-semibold ml-2">{teamJourney.TeamName}</span>
				</div>

				{/* Player Names Grid on the Right */}
				<div className="grid grid-cols-4 gap-2 ml-4 whitespace-nowrap">
					{teamJourney.players.PlayerNames.map((playerName, index) => (
						<div key={index} className="px-3 py-1 text-sm bg-gray-100 rounded-full">
							{playerName
								.split(" ")
								.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
								.join(" ")}
						</div>
					))}
				</div>
			</div>
			<Table
				data={teamJourney.MatchupResults.slice(0, 10)}
				columns={columns}
				className="w-full"
				headerClassName="text-center  rounded-full"
				zebra={true}
				rounded={true}
				bordered={true}
				hover={true}
			/>
		</div>
	);
};
