"use client";

import React, { useEffect, useState } from "react";
import ApiService from "../../../service/Api.service";
import { playerImgDir } from "../../../Utilities/ImageLinks";
import { useAuthProvider } from "../../../context/AuthProvider";
import { Table } from "../../../Components/V2/TableWrapper";
import { Avatar } from "@chakra-ui/react";

export const PlayerLeftJourney = ({ teamId }) => {
	const user = useAuthProvider();
	const [playerJourney, setPlayerJourney] = useState(null);

	async function fetchPlayerJourney() {
		try {
			let journeyResp = await ApiService.httpGet(
				`/TourPlayers?Class=PlayerJourney&TeamId=${teamId}`,
				user.userId
			);
			if (journeyResp.status === true) {
				setPlayerJourney(journeyResp);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		fetchPlayerJourney();
	}, [teamId]);

	if (!playerJourney) return null;

	const columns = [
		{ key: "RoundName", title: "Round", align: "left" },
		{ key: "Opponent", title: "Opponent", align: "left" },
		{
			key: "Result",
			title: "Result",
			align: "center",
			render: (value) => (
				<span className={value === "Lost" ? "text-red-500" : "text-green-500"}>
					{value}
				</span>
			),
		},
		{ key: "Scores", title: "Score(s)", align: "center" },
	];

	return (
		<div className="flex flex-col items-center w-[80vw] bg-white rounded-2xl  p-4 h-auto ">
			<h2 className="text-xl font-medium tracking-tight mb-2">Player Journey </h2>

			<div className="flex justify-between items-center border border-gray-200 w-full rounded-lg px-3 mb-4">
				<div className="flex items-center ">
					{playerJourney.ImageLinks.slice(0, 1).map((player) => (
						<Avatar
							borderWidth="6px"
							// borderColor={bgColor}
							size="lg"
							src={`${playerImgDir}${player}`}
							name={playerJourney.TeamName}
						/>
					))}
					<span className="text-sm font-medium ml-2">{playerJourney.TeamName}</span>
				</div>
				<div className="flex flex-col items-center justify-center">
					<span className="text-sm text-black">Team A</span>
				</div>
				<button className="bg-[#28B46A] text-white rounded-full px-4 py-2 shadow-md flex items-center gap-2 text-sm">
					Player Profile
					<span className="w-6 h-6 rounded-full bg-white text-[#28B46A] flex items-center justify-center">
						&gt;
					</span>
				</button>
			</div>

			<Table
				data={playerJourney.MatchupResults.slice(0, 10)}
				columns={columns}
				className="w-full"
				headerClassName="text-center  rounded-full"
				zebra={true}
				rounded={true}
				bordered={true}
				hover={true}
			/>
		</div>
	);
};
