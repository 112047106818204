import { Avatar, Box, HStack, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { HiLocationMarker } from "react-icons/hi";
import ApiService from "../../../service/Api.service";
import { playerImgDir } from "../../../Utilities/ImageLinks";
import { useAuthProvider } from "../../../context/AuthProvider";
import { Table } from "../../../Components/V2/TableWrapper";
import { playerProfImgDir } from "../../../Utilities/ImageLinks";

export const PlayerProfile = ({
	eventId,
	groupname,
	roundNo,
	overlaysState,
	boardNo,
	profPlayerId,
}) => {
	const user = useAuthProvider();
	const textColor = useColorModeValue("gray.600", "gray.300");
	const [playerProfile, setPlayerProfile] = useState([]);
	async function fetchPlayerProfile() {
		try {
			const profileRes = await ApiService.httpGet(
				`/TourPlayers?Class=PlayerProfiles&ProfPlayerId=${profPlayerId}&User=${user.userId}`,
				user.userId
			);
			if (profileRes.status === true && profileRes.PlayerProfiles?.length) {
				setPlayerProfile(profileRes.PlayerProfiles);
				console.log("profiles", profileRes.PlayerProfiles);
			}
		} catch (err) {
			console.error("Error fetching player profiles:", err);
		}
	}

	useEffect(() => {
		fetchPlayerProfile();
	}, [eventId, groupname, roundNo, overlaysState]);

	const columns = [
		{ key: "Grip", title: "Grip", align: "left" },
		{ key: "Age", title: "Age / Type", align: "left" },
		{ key: "Location", title: "Location", align: "center" },
		{ key: "Department", title: "Department", align: "center" },
		{ key: "Description", title: "Achievements", align: "right" },
	];

	if (!playerProfile?.length) return null;

	return (
		<div className="flex flex-col items-center w-[80vw] bg-white rounded-2xl lg:p-4 sm:p-0 h-auto">
			<div className="text-center">
				<h2 className="text-xl font-medium tracking-tight lg:mb-2 sm:mb-0">
					Player Profile
				</h2>
				{playerProfile.map((player, index) => (
					<Text key={index} fontSize="xl" fontWeight="bold">
						{player.PlayerName}
					</Text>
				))}
			</div>
			<div
				className="w-full sm:p-2 flex justify-start items-start lg:mb-3 sm:mb-0"
				style={{ justifyContent: "space-between" }}>
				<div className="flex justify-center items-center bg-gray-100 rounded-xl">
					<img
						src={`${playerProfImgDir}${playerProfile[0]?.ImageLink}`}
						className="object-cover h-48 w-48 rounded-xl"
					/>
				</div>

				<div className=" flex-wrap w-3/4 grid grid-cols-2 gap-3 ">
					{columns.slice(0, 4).map((column, index) => (
						<div>
							<div
								key={index}
								className={`flex-1 text-${column.align} bg-[#4A38B7] text-white font-normal text-center lg:px-2 lg:py-2 sm:px-1 sm:py-1 rounded-full mx-2`}>
								<p>{column.title}</p>
							</div>
							{playerProfile.map((player, idx) => (
								<p
									key={idx}
									className="text-sm text-center bg-gray-100 rounded-full font-normal lg:px-2 lg:py-2 sm:px-1 sm:py-1 mt-2 mx-2 ">
									{column.key === "Age"
										? `${player["Age"]} / ${player["Type"]}`
										: player[column.key]}
								</p>
							))}
						</div>
					))}
				</div>
			</div>

			<div className="w-full gap-3 rounded-lg sm:p-2 ">
				{columns.slice(4, 5).map((column, index) => (
					<div>
						<div
							key={index}
							className={`text-${column.align} bg-[#4A38B7] text-white font-normal text-center lg:px-2 lg:py-2 sm:px-1 sm:py-1 rounded-full`}>
							<p>{column.title}</p>
						</div>

						{playerProfile.map((player, idx) => {
							const text = player[column.key];
							const sentences = text
								.split("@#$")
								.filter((sentence) => sentence.trim() !== "");
							let gridCols =
								sentences.length > 10
									? "list-none grid grid-cols-3 p-0"
									: "list-none grid grid-cols-2 p-0";
							return (
								<ul key={idx} className={gridCols}>
									{sentences.map((sentence, sentenceIdx) => (
										<li
											key={sentenceIdx}
											className="text-sm align-content-center text-left bg-gray-100 rounded-full font-normal lg:px-4 sm:px-2 lg:py-3 sm:py-1 mt-2 mx-2">
											{sentence.trim()}
										</li>
									))}
								</ul>
							);
						})}
					</div>
				))}
			</div>
		</div>
	);
};
