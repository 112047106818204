import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";
import MatchesStyle from "./MatchesStyle";

const Teams3SetMatchUpResults = ({ round, eventId, roundNo, groupname }) => {
	const { userId } = useAuthProvider();
	const [matchupData, setMatchupData] = useState(null);
	const [loading, setLoading] = useState(true);

	const fetchTeamsSet = async () => {
		try {
			const response = await ApiService.httpGet(
				`/TourEventMatchupsByGroup?TourEventId=${eventId}&GroupName=${groupname}&RoundNo=${roundNo}&Class=Teams&User=${userId}`,
				userId
			);

			if (response.status === true) {
				setMatchupData(response);
			} else {
				console.error(
					"Failed to fetch matchup data:",
					response.message || "Unknown error"
				);
			}
		} catch (error) {
			console.error("Error fetching matchup data:", error.message || error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchTeamsSet();
		const intervalId = setInterval(() => {
			fetchTeamsSet();
		}, 10000);

		return () => clearInterval(intervalId);
	}, [eventId, groupname, roundNo]);

	if (loading) {
		return null;
	}

	if (!matchupData || !matchupData.Teams) {
		return null;
	}

	return (
		<div className="animate-in fade-in zoom-in duration-300  ">
			<div className="mx-auto h-auto lg:w-[98vw] sm:w-[98vw] justify-center rounded-2xl bg-white ">
				<h2 className="text-center h2 font-bold">Current Matchups Snapshot</h2>
				<div className="lg:px-10 justify-center  lg:p-3">
					{matchupData.Teams.map((team, teamIndex) => (
						<div key={teamIndex} className="lg:mb-3">
							<div className="flex justify-center">
								<div className="bg-[#4A38B7] w-[30vw] items-center rounded-full lg:mt-2">
									<h1 className="lg:text-lg lg:font-semibold sm:text-sm sm:font-normal   text-white text-center rounded-full">
										{team.TeamNames}
									</h1>
								</div>
							</div>

							<div className="flex flex-row flex-wrap lg:w-[90vw] sm:w-[100vw] lg:gap-4 justify-center sm:gap-2 p-1">
								{team.Matchups.map((eachMatch, matchIndex) => (
									<MatchesStyle key={matchIndex} eachMatch={eachMatch} />
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

Teams3SetMatchUpResults.propTypes = {
	round: PropTypes.string.isRequired,
	eventId: PropTypes.string.isRequired,
	roundNo: PropTypes.number.isRequired,
	groupname: PropTypes.string.isRequired,
};

Teams3SetMatchUpResults.defaultProps = {
	round: "",
	eventId: "",
	roundNo: 0,
	groupname: "",
};

export default Teams3SetMatchUpResults;
