import "./App.css";
import {
	ChakraProvider,
	Flex,
	useBreakpointValue,
	useColorModeValue,
} from "@chakra-ui/react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Outlet,
	useLocation,
	useMatch,
} from "react-router-dom";

import { Navbar, NavbarEvents } from "./Components/Navbar/Navbar";
import { Sidebar } from "./Components/Sidebar/Sidebar";
import { Home } from "./Pages/Home/Home";
import { ManageClubs } from "./Pages/Clubs/ManageClubs";
import { ManageMembers } from "./Pages/Members/ManageMembers";
import { ManageTournaments } from "./Pages/Tournaments/ManageTournaments";
import { ManageEvents } from "./Pages/Events/ManageEvents";
import { createContext } from "react";
import { AddClub } from "./Pages/Clubs/AddClub";
import { AddMember } from "./Pages/Members/AddMember";
import { BoardScores } from "./Pages/Events/BoardScores";
import { AddTournament } from "./Pages/Tournaments/AddTournament";
import { MatchupsResults } from "./Pages/Events/MatchupsResults";
import { Standings } from "./Pages/Events/Standings";
import { PlayerSelection } from "./Pages/Events/PlayerSelection";
import { Events } from "./Pages/Events/Events";
import { SignIn } from "./Pages/SignIn/SignIn";
import { ClubDetails } from "./Pages/Clubs/ClubDetails";
import { TournamentDetails } from "./Pages/Tournaments/TournamentDetails";
import { UpdateClub } from "./Pages/Clubs/UpdateClub";
import { UpdateMember } from "./Pages/Members/UpdateMember";
import { UpdateTournament } from "./Pages/Tournaments/UpdateTournament";
import { ScoreBoard } from "./Pages/Events/OldScoreBoard";
import { ControlBoard } from "./Pages/Events/ControlPage";
import { Groups } from "./Pages/Events/Groups";
import { Players } from "./Pages/Events/Players";
import { Teams } from "./Pages/Events/Teams";
import { Login } from "./Pages/SignIn/Login";
import { ScoreBoardControls } from "./Pages/Events/GreenScreen/ScoreBoardControls";
import { NewScoreBoard } from "./Pages/Events/NewScoreBoard";
import { BroadcastSetup } from "./Pages/Events/GreenScreen/Youtube/BroadcastSetup";
import { useAuthProvider } from "./context/AuthProvider";
import { Unauthorized } from "./Pages/SignIn/Unauthorized";
import { ExternalResults } from "./Pages/Events/ExternalResults";
import { DoublesPairs } from "./Pages/Events/DoublesPairs";
import { TeamMatchups } from "./Pages/Events/TeamMatchups";
import { Positions } from "./Pages/Events/Positions";
import { RefereeAssign } from "./Pages/Events/RefereeAssign";

export let data = createContext();

function AppContent() {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});

	// Get the current location
	const location = useLocation();

	// List of paths where you don't want to display the Navbar
	const pathsWithNavbar = [
		"/",
		"/clubs",
		"/members",
		"/tournaments",
		"/events",
		"/clubs/addclub",
		"/clubs/clubdetails",
		"/clubs/updateclub",
		"/members/addmember",
		"/members/updatemember",
		"/tournaments/addtournament",
		"/tournaments/tournamentdetails",
		"/tournaments/updatetournament",
		"/events/manage-events",
	];

	// auth
	const user = useAuthProvider();
	const redirect = window.location.pathname;

	// Determine if Navbar should be displayed based on the current path
	let shouldDisplayNavbar = pathsWithNavbar.includes(location.pathname.toLowerCase());

	let scoreboardPage =
		location.pathname.startsWith("/scoreboard") ||
		location.pathname.startsWith("/streaming");

	return (
		<>
			{scoreboardPage ? (
				<Routes>
					<Route
						path="/streaming/:eventId/scoreboard/:boardNo"
						element={<NewScoreBoard />}
					/>
					<Route
						path="/streaming/:eventId/scoreboard/:boardNo/controls"
						element={<ScoreBoardControls />}
					/>
				</Routes>
			) : (
				<Flex
					as="section"
					direction={{
						base: "column",
						lg: "row",
					}}
					height="100vh"
					bg="bg-canvas"
					overflowY="auto"
					css={{
						"::-webkit-scrollbar": {
							width: "0.4rem",
							height: "0.4rem",
						},
						"::-webkit-scrollbar-track": {
							background: "#bee3f8",
							borderRadius: "1rem",
						},
						"::-webkit-scrollbar-thumb": {
							background: "#3182ce",
							borderRadius: "1rem",
						},
					}}>
					{shouldDisplayNavbar && user.authorized && isDesktop && <Navbar />}
					{shouldDisplayNavbar &&
						user.authorized &&
						(isDesktop ? <Sidebar /> : <Navbar />)}
					{!shouldDisplayNavbar && <NavbarEvents />}
					<Routes>
						{/* Routes for the external pages */}
						<Route path="/players/:eventId" element={<Players />} />
						<Route path="/doublespairs/:eventId" element={<DoublesPairs />} />
						<Route path="/groups/:eventId" element={<Groups />} />
						<Route path="/boardscores/:matchupId" element={<BoardScores />} />
						<Route path="/referees/:eventId" element={<RefereeAssign />} />
						{/* <Route path="/scoreboard" element={<ScoreBoard />} /> */}
						{/* <Route path="/controlboard" element={<ControlBoard />} /> */}
						<Route path="/matchupsresults/:eventId" element={<MatchupsResults />} />
						<Route path="/standings/:eventId" element={<Standings />} />
						<Route path="/playerselection/:teamId" element={<PlayerSelection />} />
						<Route path="/teams/:eventId/:teamId" element={<Teams />} />
						{/* <Route path="/externalresults/:eventId" element={<ExternalResults />} /> */}
						<Route path="/team-matchups/:teamId" element={<TeamMatchups />} />
						<Route path="/positions/:eventId" element={<Positions />} />
						{/* <Route
							path="/streaming/:eventId/scoreboards/:boardNo/controls"
							element={<ScoreBoardControls />}
						/> */}
						{user.loggedIn && user.authorized ? (
							<>
								<Route path="/" element={<Home />} />
								<Route path="/clubs">
									<Route index element={<ManageClubs />} />
									<Route path="addclub" element={<AddClub />} />
									<Route path="clubdetails" element={<ClubDetails />} />
									<Route path="updateclub" element={<UpdateClub />} />
								</Route>
								<Route path="/members">
									<Route index element={<ManageMembers />} />
									<Route path="addmember" element={<AddMember />} />
									<Route path="updatemember" element={<UpdateMember />} />
								</Route>
								<Route path="/tournaments">
									<Route index element={<ManageTournaments />} />
									<Route path="addtournament" element={<AddTournament />} />
									<Route path="tournamentdetails" element={<TournamentDetails />} />
									<Route path="updatetournament" element={<UpdateTournament />} />
								</Route>
								<Route path="/events">
									<Route index element={<ManageEvents />} />
									<Route path="manage-events" element={<Events />} />
								</Route>
								<Route path="/broadcast/:eventId" element={<BroadcastSetup />} />
							</>
						) : null}
						{user.loggedIn && !user.authorized && (
							<Route path="*" element={<Unauthorized />} />
						)}
						{!user.loggedIn && (
							<Route path="*" element={<SignIn redirect={redirect} />} />
						)}
					</Routes>
				</Flex>
			)}
		</>
	);
}

export default AppContent;
