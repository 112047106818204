import {
	Container,
	Heading,
	Icon,
	IconButton,
	useBreakpointValue,
	Stack,
	HStack,
	Card,
	CardHeader,
	Center,
	useColorModeValue,
	FormControl,
	Select,
	Input,
	InputGroup,
	InputLeftElement,
	useDisclosure,
	MenuButton,
	Menu,
	MenuList,
	MenuItem,
	Modal,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	ModalOverlay,
	Button,
	Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CalendarWhite } from "../../icons/Icons";
import ApiService from "../../service/Api.service";
import { useAuthProvider } from "../../context/AuthProvider";
import { FiDownloadCloud, FiMoreVertical, FiRefreshCw, FiSearch } from "react-icons/fi";
import { AiOutlineSwap } from "react-icons/ai";
import { RefereesTable } from "../../Components/Events/Referees/RefereesTable";
import { useToast } from "@chakra-ui/react";

export const RefereeAssign = ({ eventId: propEventId, props }) => {
	// User Details
	const user = useAuthProvider();
	const toast = useToast();
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	});
	// Mobile BreakPoint Value
	const isMobile = useBreakpointValue({
		base: true,
		sm: true,
		md: false,
	});
	//Page Header
	const pageHeader = useBreakpointValue({
		base: "xs",
		sm: "sm",
	});
	//Box Shadow
	const shadow = useColorModeValue("sm", "sm-dark");
	//Card Heading
	const cardHeading = useBreakpointValue({
		base: "xs",
		md: "sm",
	});

	let { eventId: urlParamEventId } = useParams();
	let eventId = propEventId || urlParamEventId;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isAlertOpen,
		onOpen: onAlertOpen,
		onClose: onAlertClose,
	} = useDisclosure();
	//let setQuery = props.setQuery;
	let [groups, setGroups] = useState([]);
	let [selectedGroupName, setSelectedGroupName] = useState({});
	let [matchupsData, setMatchupsData] = useState([]);
	let [tourEventName, setTourEventName] = useState("");
	const [selectedMatch1, setSelectedMatch1] = useState("");
	const [selectedMatch2, setSelectedMatch2] = useState("");
	const [menuLoading, setMenuLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [unassignedReferees, setUnassignedReferees] = useState([]);

	useEffect(async () => {
		await fetchData();
	}, []);

	const [loading, setLoading] = useState(false); // Add loading state
	const [roundsData, setRoundsData] = useState([]); // Stores all rounds with RoundNo and RoundName
	const [filteredRounds, setFilteredRounds] = useState([]);
	const [selectedRound, setSelectedRound] = useState(null);

	const fetchData = async () => {
		setLoading(true); // Start loading before making the API requests

		let groups = await ApiService.httpGet(
			"/EventTeams?GroupsEventId=" + eventId,
			"U00184"
		);
		// Set the data to the appropriate state
		setGroups(groups.GroupNames);
		setSelectedGroupName(groups.GroupNames[0]);
		console.log(groups.GroupNames[0]);
		try {
			const response = await ApiService.httpGet(
				`/TeamEventTeams?Class=MatchesRoundLookup&TourEventId=${eventId}&User=U00184`,
				"U00156"
			);
			if (response && response.status) {
				const rounds = response.Rounds;
				console.log("Fetched Rounds:", rounds);
				setRoundsData(rounds);

				const roundsByGroup = rounds?.[groups.GroupNames[0]?.GroupId] || [];
				if (roundsByGroup.length > 0) {
					setFilteredRounds(roundsByGroup);

					const latestRound = roundsByGroup.find((round) => round.Latest === "YES");
					if (latestRound) {
						setSelectedRound(latestRound);
						fetchMatchups(
							groups.GroupNames[0].GroupId,
							roundsByGroup.find((round) => round.Latest === "YES")?.RoundNo
						);
						getUnassignedReferees(
							groups.GroupNames[0].GroupId,
							roundsByGroup.find((round) => round.Latest === "YES")?.RoundNo
						);
						console.log("Latest Round:", latestRound);
					} else {
						console.warn("No round with 'Latest' set to 'YES' found.");
					}
				} else {
					console.warn("Rounds A array is empty.");
				}
			} else {
				console.error("API response status is not successful.");
			}
		} catch (err) {
			console.error("Error fetching rounds data:", err);
		}
	};

	const swapReferees = async () => {
		try {
			setMenuLoading(true); // Show loading indicator

			// Call API to swap venues
			const swapRes = await ApiService.httpPut(
				`/TourEventMatchupsByGroup?Class=Referee&Matchup1Id=${selectedMatch1}&Matchup2Id=${selectedMatch2}&User=${user.userId}`,
				{}
			);

			// Check if swapRes contains status indicating success
			if (swapRes && swapRes.status === true) {
				toast({
					title: "Swap Referees",
					description: swapRes.Message || "Venues Swapped Successfully!",
					status: "success",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
				await props.refreshMatchups();
				setSelectedMatch1("");
				setSelectedMatch2("");
			} else {
				// Failure case
				toast({
					title: "Swap Referees",
					description: swapRes.Message || "Failed to Swap Referees", // Use the message from the response if available
					status: "error",
					duration: 3000,
					isClosable: true,
					position: "top-right",
				});
			}
		} catch (err) {
			// Error case: API call fails or other errors
			toast({
				title: "Swap Referees",
				description: "Error occurred while swapping Referees",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		} finally {
			setMenuLoading(false); // Hide loading indicator
			onClose(); // Close modal
		}
	};

	const fetchMatchups = async (selectedGroupId, selectedRoundNo) => {
		try {
			setMenuLoading(true);
			let matchupsResponse = await ApiService.httpGet(
				`/TourEventMatchupsByGroup?Class=RefereeDetails&TourEventId=${eventId}&RoundNo=${selectedRoundNo}&GroupName=${selectedGroupId}`,
				"U00184"
			);
			setMatchupsData(matchupsResponse.TourEventMatchups || []);
			setTourEventName(matchupsResponse.TourEventName + " Event");
			// 	const data = JSON.parse(groupName); // Parse the JSON string
			// const value = data[0]; // Extract the first element
			// console.log(value);
			//setLoading(false);
			setMenuLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	const updateMatchupsData = async (groupId, roundNo) => {
		console.log("Updated GroupName", groupId);
		console.log("Updated RoundNo", roundNo);
		setFilteredRounds(roundsData?.[groupId]);
		if (groupId !== selectedGroupName.GroupId) {
			roundNo = roundsData?.[groupId].find((round) => round.Latest === "YES")?.RoundNo;
		}
		setSelectedRound({ RoundNo: roundNo });
		setSelectedGroupName({ GroupId: groupId });
		fetchMatchups(groupId, roundNo);
		getUnassignedReferees(groupId, roundNo);
	};

	const filteredMatchups = matchupsData?.filter((matchup) => {
		return (
			(matchup.Player1 &&
				matchup.Player1.toLowerCase().includes(searchQuery.toLowerCase())) ||
			(matchup.Player2 &&
				matchup.Player2.toLowerCase().includes(searchQuery.toLowerCase())) ||
			(matchup.RefereeName &&
				matchup.RefereeName.toLowerCase().includes(searchQuery.toLowerCase())) ||
			(matchup.Venue &&
				typeof matchup.Venue === "string" &&
				matchup.Venue.toLowerCase().includes(searchQuery.toLowerCase()))
		);
	});
	const getUnassignedReferees = async (selectedGroupId, selectedRoundNo) => {
		try {
			let roundNo = selectedRound?.RoundNo;
			const response = await ApiService.httpGet(
				`/TourEventMatchupsByGroup?Class=UnassignedReferees&TourEventId=${eventId}&RoundNo=${selectedRoundNo}&GroupName=${selectedGroupId}`,
				"U00184"
			);

			if (response && response.status) {
				console.log("Unassigned Referees:", response.UnassignedReferees);
				setUnassignedReferees(response.UnassignedReferees); // Update state with the fetched referees
			} else {
				console.error("Failed to fetch unassigned referees: Invalid status.");
			}
		} catch (error) {
			console.error("Error fetching unassigned referees:", error);
		}
	};
	return (
		<>
			<Container
				flex="1"
				maxWidth="100vw"
				{...(propEventId === undefined && {
					marginTop: isDesktop ? "7rem" : "5rem",
				})}
				marginX={0}
				{...(isMobile && { px: "1" })}
				paddingBottom={10}>
				<Stack spacing="1.7rem">
					<Heading size={pageHeader} fontWeight="bold">
						Referee Assignment
					</Heading>
					<Stack spacing="2rem">
						<Card variant={"elevated"} bgColor="white" boxShadow={shadow}>
							<CardHeader>
								<Stack
									direction={{
										base: "column",
										md: "row",
									}}
									spacing="1.2rem"
									align={{
										base: "start",
										md: "center",
									}}
									justify={{ md: "space-between" }}>
									<HStack spacing="1.2rem" align="center">
										{isMobile ? (
											""
										) : (
											<Center bgColor="accent" p="1rem" borderRadius="xl">
												<Icon as={CalendarWhite} />
											</Center>
										)}
										<Heading as="h2" size={cardHeading}>
											{tourEventName}
										</Heading>
									</HStack>
									<Stack spacing="1rem" direction={"row"}>
										<InputGroup maxW="xs" colorScheme="blue">
											<InputLeftElement pointerEvents="none">
												<Icon as={FiSearch} color="accent" boxSize="5" />
											</InputLeftElement>
											<Input
												w="20vw"
												placeholder="Search"
												value={searchQuery}
												onChange={(e) => setSearchQuery(e.target.value)}
											/>
										</InputGroup>
										<Button onClick={onAlertOpen}>Unassigned Referees</Button>
										<Button onClick={onOpen}>Swap Referees</Button>
										<IconButton
											variant="ghost"
											size="md"
											color="accent"
											icon={
												<FiRefreshCw
													size="1.7rem"
													onClick={() => {
														fetchData();
														fetchMatchups(
															selectedGroupName.GroupId,
															selectedRound.RoundNo
														);
													}}
												/>
											}
										/>
										<FormControl w={"-webkit-fit-content"}>
											<Select
												name="Group"
												colorScheme="blue"
												fontSize={{ base: "sm", md: "md" }}
												value={selectedGroupName.GroupId}
												onChange={(event) => {
													updateMatchupsData(event.target.value, selectedRound.RoundNo);
												}}>
												{groups.map((group, index) => {
													return (
														<option key={group.GroupId} value={group.GroupId}>
															{group.GroupName}
														</option>
													);
												})}
											</Select>
										</FormControl>
										<FormControl w={"-webkit-fit-content"}>
											<Select
												name="Round"
												colorScheme="blue"
												fontSize={{ base: "sm", md: "md" }}
												value={selectedRound?.RoundNo}
												onChange={(event) => {
													updateMatchupsData(
														selectedGroupName.GroupId,
														event.target.value
													);
												}}>
												{filteredRounds?.map((round, index) => {
													return (
														<option key={round.RoundNo} value={round.RoundNo}>
															{round.RoundName}
														</option>
													);
												})}
											</Select>
										</FormControl>
									</Stack>
								</Stack>
							</CardHeader>
						</Card>

						<RefereesTable
							matchupsData={matchupsData}
							filteredMatchups={filteredMatchups}
							unassignedReferees={unassignedReferees}
							fetchRoundsData={fetchData}
							eventId={eventId}
						/>
					</Stack>
				</Stack>
			</Container>
			<Modal
				onClose={() => {
					setSelectedMatch1("");
					setSelectedMatch2("");
					onClose();
				}}
				isOpen={isOpen}
				isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign={"center"}>Swap Referees</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<HStack justify={"space-evenly"}>
							<Select
								width={"-webkit-max-content"}
								placeholder="Select a Team"
								onChange={(e) => setSelectedMatch1(e.target.value)}>
								{matchupsData
									?.filter((matchup) => matchup.MatchupId !== selectedMatch2)
									?.map((players, index) => (
										<option key={players.MatchupId} value={players.MatchupId}>
											{players.RefereeName && players.RefereeName + " - "}
											{players.Player1} vs {players.Player2}
										</option>
									))}
							</Select>
							<Icon as={AiOutlineSwap} boxSize={"1.5rem"} />
							<Select
								width={"-webkit-max-content"}
								placeholder="Select a Team"
								onChange={(e) => setSelectedMatch2(e.target.value)}>
								{matchupsData
									?.filter((matchup) => matchup.MatchupId !== selectedMatch1)
									?.map((players, index) => (
										<option key={players.MatchupId} value={players.MatchupId}>
											{players.RefereeName && players.RefereeName + " - "}
											{players.Player1} vs {players.Player2}
										</option>
									))}
							</Select>
						</HStack>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme={"green"}
							onClick={swapReferees}
							isLoading={menuLoading}
							disabled={selectedMatch1 !== "" && selectedMatch2 !== "" ? false : true}>
							Swap
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal onClose={onAlertClose} isOpen={isAlertOpen} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign={"center"}>UnassignedReferees</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{unassignedReferees.length > 0 ? (
							unassignedReferees.map((referee, index) => (
								<Text key={index}>{referee.RefereeName}</Text>
							))
						) : (
							<Text>No unassigned referees available.</Text>
						)}
					</ModalBody>
					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
