import { useEffect, useState } from "react";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";

export const PlayerMatchup = ({ matchupId, tournamentname, round, boardData }) => {
	const user = useAuthProvider();
	const getInitials = (name) => {
		if (!name) return "??";
		return name
			.split(" ")
			.map((word) => word[0])
			.join("")
			.toUpperCase()
			.substring(0, 2);
	};

	const [players, setPlayers] = useState(null);
	async function fetchPlayers() {
		try {
			let playersRes = await ApiService.httpGet(
				`/TourStandings?Class=PlayersInfo&MatchupId=${matchupId}`,
				user.userId
			);
			if (playersRes.status === true) {
				setPlayers({
					...playersRes.PlayersInfo[0],
					MatchType: playersRes.MatchType,
				});
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		fetchPlayers();
	}, []);

	if (players) {
		return (
			<div className=" w-[90vw] lg:mt-[3%] sm:h-auto mx-auto h-auto bg-white rounded-[20px]  border border-blue-200 overflow-hidden p-7">
				{/* Header */}
				<div className="text-center mb-6">
					<h1 className=" sm:text-md text-2xl font-semibold mb-3">Matchup</h1>
					<div className="flex justify-center gap-4">
						<div className="bg-gray-50 px-4 py-1.5 rounded-full sm:text-md text-md">
							{tournamentname}
						</div>
						<div className="bg-gray-50 px-4 py-1.5 rounded-full sm:text-md text-md">
							{round}
						</div>
					</div>
				</div>

				{/* Players Section */}
				<div className="flex items-center justify-between gap-8">
					{/* Left Team */}
					<div className="flex-1 w-[35%]">
						<div className="space-y-6">
							{/* Players */}
							<div className="flex gap-4 justify-center">
								<PlayerAvatar
									imageUrl={players.Team1Player1ImageLink}
									name={players.Team1Player1Name}
									matchType={players.MatchType}
									getInitials={getInitials}
								/>
								{players.MatchType === "Doubles" && (
									<PlayerAvatar
										imageUrl={players.Team1Player2ImageLink}
										name={players.Team1Player2Name}
										getInitials={getInitials}
										matchType={players.MatchType}
									/>
								)}
							</div>

							{/* Names */}
							<div className="space-y-2">
								<div className="text-center">
									<div className="bg-purple-50 rounded-full px-6 py-2 inline-block">
										<span className="text-purple-700 text-md whitespace-nowrap">
											{players.Team1Player1Name}
										</span>
									</div>
								</div>
								{players.MatchType === "Doubles" && (
									<>
										<div className="text-center">
											<div className="bg-purple-50 rounded-full px-6 py-2 inline-block">
												<span className="text-purple-700 text-md whitespace-nowrap">
													{players.Team1Player2Name}
												</span>
											</div>
										</div>
										{players.Team1Name && (
											<div className="text-center">
												<div className="bg-purple-50 rounded-full px-6 py-2 inline-block">
													<span className="text-purple-700 text-md font-medium whitespace-nowrap">
														{players.Team1Name}
													</span>
												</div>
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</div>

					{/* Stats Grid */}
					<div className="grid grid-cols-2 gap-2 mx-auto">
						<StatPill
							label="Played"
							value={
								players.MatchType === "Doubles"
									? players.Team1Played
									: players.Team1Player1Played
							}
							color="red"
							team1color={boardData?.TeamsConfigs?.Team1Color}
							team2color={boardData?.TeamsConfigs?.Team2Color}
						/>
						<StatPill
							label="Won"
							value={
								players.MatchType === "Doubles"
									? players.Team1Won
									: players.Team1Player1Won
							}
							color="red"
							team1color={boardData?.TeamsConfigs?.Team1Color}
							team2color={boardData?.TeamsConfigs?.Team2Color}
						/>
						<StatPill
							label="Lost"
							value={
								players.MatchType === "Doubles"
									? players.Team1Lost
									: players.Team1Player1Lost
							}
							color="red"
							team1color={boardData?.TeamsConfigs?.Team1Color}
							team2color={boardData?.TeamsConfigs?.Team2Color}
						/>
						<StatPill
							label="Points"
							value={
								players.MatchType === "Doubles"
									? players.Team1Points
									: players.Team1Player1Points
							}
							color="red"
							team1color={boardData?.TeamsConfigs?.Team1Color}
							team2color={boardData?.TeamsConfigs?.Team2Color}
						/>
					</div>

					<div className="w-14 h-14 rounded-full bg-black flex items-center justify-center">
						<span className="text-white font-medium">VS</span>
					</div>
					<div className="grid grid-cols-2 gap-2 mx-auto">
						<StatPill
							label="Played"
							value={
								players.MatchType === "Doubles"
									? players.Team2Played
									: players.Team2Player1Played
							}
							color="green"
							team1color={boardData?.TeamsConfigs?.Team1Color}
							team2color={boardData?.TeamsConfigs?.Team2Color}
						/>
						<StatPill
							label="Won"
							value={
								players.MatchType === "Doubles"
									? players.Team2Won
									: players.Team2Player1Won
							}
							color="green"
							team1color={boardData?.TeamsConfigs?.Team1Color}
							team2color={boardData?.TeamsConfigs?.Team2Color}
						/>
						<StatPill
							label="Lost"
							value={
								players.MatchType === "Doubles"
									? players.Team2Lost
									: players.Team2Player1Lost
							}
							color="green"
							team1color={boardData?.TeamsConfigs?.Team1Color}
							team2color={boardData?.TeamsConfigs?.Team2Color}
						/>
						<StatPill
							label="Points"
							value={
								players.MatchType === "Doubles"
									? players.Team2Points
									: players.Team2Player1Points
							}
							color="green"
							team1color={boardData?.TeamsConfigs?.Team1Color}
							team2color={boardData?.TeamsConfigs?.Team2Color}
						/>
					</div>

					{/* Right Team */}
					<div className="flex-1 w-[35%">
						<div className="space-y-6">
							{/* Players */}
							<div className="flex gap-4 justify-center">
								<PlayerAvatar
									imageUrl={players.Team2Player1ImageLink}
									name={players.Team2Player1Name}
									getInitials={getInitials}
									matchType={players.MatchType}
								/>
								{players.MatchType === "Doubles" && (
									<PlayerAvatar
										imageUrl={players.Team2Player2ImageLink}
										name={players.Team2Player2Name}
										getInitials={getInitials}
										matchType={players.MatchType}
									/>
								)}
							</div>

							{/* Stats Grid */}
							{/* Names */}
							<div className="space-y-2">
								<div className="text-center">
									<div className="bg-purple-50 rounded-full px-6 py-2 inline-block">
										<span className="text-purple-700 text-md whitespace-nowrap">
											{players.Team2Player1Name}
										</span>
									</div>
								</div>
								{players.MatchType === "Doubles" && (
									<>
										<div className="text-center">
											<div className="bg-purple-50 rounded-full px-6 py-2 inline-block">
												<span className="text-purple-700 text-md whitespace-nowrap">
													{players.Team2Player2Name}
												</span>
											</div>
										</div>
										{players.Team2Name && (
											<div className="text-center">
												<div className="bg-purple-50 rounded-full px-6 py-2 inline-block">
													<span className="text-purple-700 text-md font-medium whitespace-nowrap">
														{players.Team2Name}
													</span>
												</div>
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	return null;
};

function PlayerAvatar({ imageUrl, name, getInitials, matchType }) {
	if (matchType !== "Doubles") {
		return (
			<div className="relative">
				<div className="w-48 h-48 mx-auto rounded-[16px] overflow-hidden">
					{imageUrl?.substring(0, 9) === "undefined" ? (
						<div className="w-48 h-48 bg-gray-200 flex items-center justify-center text-lg font-medium">
							{getInitials(name)}
						</div>
					) : (
						<img
							src={`https://caromblob.blob.core.windows.net/images/Players/${imageUrl}`}
							alt={name}
							className="w-full h-full object-cover"
							onError={(e) => {
								e.target.style.display = "none";
								e.target.parentElement.innerHTML = `<div class="w-48 h-48 bg-gray-200 flex items-center justify-center text-lg font-medium">${getInitials(
									name
								)}</div>`;
							}}
						/>
					)}
				</div>
			</div>
		);
	} else {
		return (
			<div className="relative">
				<div className="w-24 h-24 flex justify-center items-center rounded-lg overflow-hidden">
					{imageUrl?.substring(0, 9) === "undefined" ? (
						<div className="w-full h-full bg-gray-200 flex items-center justify-center text-lg font-medium">
							{getInitials(name)}
						</div>
					) : (
						<img
							src={`https://caromblob.blob.core.windows.net/images/Players/${imageUrl}`}
							alt={name}
							className="w-full h-full object-cover"
							onError={(e) => {
								e.target.style.display = "none";
								e.target.parentElement.innerHTML = `<div class="w-full h-full bg-gray-200 flex items-center justify-center text-lg font-medium">${getInitials(
									name
								)}</div>`;
							}}
						/>
					)}
				</div>
			</div>
		);
	}
}

function StatPill({ label, value, color, team1color, team2color }) {
	//const bgColor = color === 'red' ? team1color :team2color
	const bgColor = color === "red" ? "bg-red-500" : "bg-green-500";

	return (
		<div className={`w-24 text-center flex flex-col gap-2`}>
			<div
				className={`rounded-full p-1 text-white ${bgColor} lg:text-md sm:text-sm text-md font-medium whitespace-nowrap`}>
				{label}
			</div>
			<div className="text-black rounded-full p-2 border border-gray-50 text-md font-normal leading-tight whitespace-nowrap">
				{value}
			</div>
		</div>
	);
}
