import { useEffect, useState } from "react";
import ApiService from "../../../service/Api.service";
import { useAuthProvider } from "../../../context/AuthProvider";
import { Table } from "../../../Components/V2/TableWrapper";
import { playerImgDir } from "../../../Utilities/ImageLinks";
import { Avatar } from "@chakra-ui/react";
import { MatchupResults } from "./MatchupResults";
import { playerProfImgDir } from "../../../Utilities/ImageLinks";

export const TeamMatchup = ({
	eventId,
	tournamentname,
	round,
	team1Id,
	team2Id,
	roundNo,
}) => {
	const user = useAuthProvider();

	const [matchupResults, setMatchupResults] = useState([]);
	const [team1captain, setTeam1Captain] = useState("");
	const [team2captain, setTeam2Captain] = useState("");
	const [team1name, setTeam1Name] = useState("");
	const [team2name, setTeam2Name] = useState("");
	const [team1logo, setTeam1Logo] = useState("");
	const [team2logo, setTeam2Logo] = useState("");
	const [team1color, setTeam1Color] = useState("");
	const [team2color, setTeam2Color] = useState("");
	const [team1lost, setTeam1Lost] = useState("");
	const [team2lost, setTeam2Lost] = useState("");
	const [team1won, setTeam1Won] = useState("");
	const [team2won, setTeam2Won] = useState("");
	const [team1played, setTeam1Played] = useState("");
	const [team2played, setTeam2Played] = useState("");
	const [team1points, setTeam1Points] = useState("");
	const [team2points, setTeam2Points] = useState("");
	const [roundno, setRoundNo] = useState("");

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 5;

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const displayedMatchups = matchupResults?.slice(startIndex, endIndex);

	async function fetchMatchups() {
		try {
			let matchupRes = await ApiService.httpGet(
				`/TeamEventTeams?Class=getTeamMatchupsByIds&Team1Id=${team1Id}&Team2Id=${team2Id}&RoundNo=${roundNo}&User=${user.userId}`,
				user.userId
			);
			if (matchupRes.status === true) {
				setMatchupResults(matchupRes.Matchups.Matchups);
				setTeam1Captain(matchupRes.Matchups.Team1Captain);
				setTeam2Captain(matchupRes.Matchups.Team2Captain);
				setTeam1Name(matchupRes.Matchups.Team1Name);
				setTeam2Name(matchupRes.Matchups.Team2Name);
				setTeam1Logo(matchupRes.Matchups.Team1Logo);
				setTeam2Logo(matchupRes.Matchups.Team2Logo);
				setTeam1Lost(matchupRes.Matchups.Team1Lost);
				setTeam2Lost(matchupRes.Matchups.Team2Lost);
				setTeam1Won(matchupRes.Matchups.Team1Won);
				setTeam2Won(matchupRes.Matchups.Team2Won);
				setTeam1Played(matchupRes.Matchups.Team1Played);
				setTeam2Played(matchupRes.Matchups.Team2Played);
				setTeam1Color(matchupRes.Matchups.Team1Color);
				setTeam2Color(matchupRes.Matchups.Team2Color);
				setTeam1Points(matchupRes.Matchups.Team1Points);
				setTeam2Points(matchupRes.Matchups.Team2Points);
				setRoundNo(matchupRes.Matchups.RoundNo);
				console.log("matchups", matchupResults);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		fetchMatchups();
	}, [eventId, tournamentname, round]);

	useEffect(() => {
		// Function to show the next values
		console.log("second useEffect");
		const showNextValues = () => {
			if (endIndex < matchupResults.length) {
				setCurrentPage(currentPage + 1);
			} else {
				setCurrentPage(1); // Start from the beginning if reached the end
			}
		};

		// Automatically show next values every 5 seconds
		const timer = setTimeout(showNextValues, 5000);

		// Clear the timer when the component unmounts or when the currentPage changes
		return () => clearTimeout(timer);
	}, [currentPage, endIndex, matchupResults.length]);

	const columns = [
		{ key: "Board", title: "Board", align: "left" },
		{ key: "Position", title: "Position", align: "left" },
		{
			key: "Player1",
			title: "Player 1",
			align: "center",
		},
		{ key: "Scores", title: "Scores", align: "center" },
		{
			key: "Player2",
			title: "Player 2",
			align: "center",
		},
	];

	return (
		<div className="flex flex-col items-center lg:w-[90vw] sm:w-[98vw] bg-white rounded-2xl lg:p-4 sm:p-1 h-auto overflow-y-auto">
			<h2 className="text-xl font-bold tracking-tight lg:mb-1 sm:mb-0">Team Matchup</h2>

			<div className="flex justify-center gap-4">
				<div className="bg-gray-100 px-4 lg:py-1.5 sm:py-1 rounded-full sm:text-md lg:text-lg font-bold">
					{tournamentname}
				</div>
				<div className="bg-gray-100 px-4 lg:py-1.5  sm:py-1 rounded-full sm:text-sm font-semibold lg:text-md">
					Round-{roundno}
				</div>
			</div>

			<div className="grid grid-cols-5 gap-4 items-center text-center  lg:p-4  sm:p-2 h-auto">
				{/* Left: Team 1 */}
				<div className="flex flex-col justify-center items-center  h-auto">
					<div className="bg-purple-100 rounded-full lg:px-2 sm:px-3 lg:py-2 sm:py-1 mb-2 sm:mt-1 inline-block">
						<span className="text-purple-700 lg:text-xl sm:text-sm font-bold whitespace-nowrap">
							{team1name}
						</span>
					</div>

					<div className="flex justify-center items-center bg-gray-100 rounded-xl lg:p-5 sm:p-3">
						<img
							src={`${playerProfImgDir}${matchupResults[0]?.team1logo}`}
							className="lg:w-16 lg:h-16 sm:w-8 sm:h-8 object-cover rounded-full"
						/>
					</div>
					<div className="bg-purple-100 rounded-full px-6 py-2 mt-2 mb-2 inline-block">
						<span className="text-purple-700 sm:text-sm lg:text-lg font-semibold  whitespace-nowrap">
							{team1captain}
						</span>
					</div>
				</div>

				<div className="grid grid-cols-2 gap-2  max-w-[200px] mx-auto">
					<StatPill label="Team1Played" value={team1played} color="#4A38B7" />
					<StatPill label="Team1Won" value={team1won} color="#4A38B7" />
					<StatPill label="Team1Lost" value={team1lost} color="#4A38B7" />
					<StatPill label="Team1Points" value={team1points} color="#4A38B7" />
				</div>

				<div className="w-7 h-7 rounded-full bg-black flex ml-20 items-center justify-center">
					<span className="text-white font-medium">VS</span>
				</div>
				<div className="grid grid-cols-2 gap-2 max-w-[200px] mx-auto">
					<StatPill label="Team2Played" value={team2played} color="green" />
					<StatPill label="Team2Won" value={team2won} color="green" />
					<StatPill label="Team2Lost" value={team2lost} color="green" />
					<StatPill label="Team2Points" value={team2points} color="green" />
				</div>

				{/* Right: Team 2 */}
				<div className="flex flex-col justify-center items-center  h-full">
					<div className="bg-purple-100 rounded-full lg:px-2 sm:px-3 lg:py-2 sm:py-1 mb-2 sm:mt-1 inline-block">
						<span className="text-purple-700 lg:text-xl sm:text-sm  font-bold whitespace-nowrap">
							{team2name}
						</span>
					</div>

					<div className="flex justify-center items-center bg-gray-100 rounded-xl lg:p-5 sm:p-3">
						<img
							src={`${playerProfImgDir}${matchupResults[0]?.team2logo}`}
							className="lg:w-16 lg:h-16 sm:w-8 sm:h-8 object-cover rounded-full"
						/>
					</div>
					<div className="bg-purple-100 rounded-full px-6 py-2 mt-2 mb-2 inline-block">
						<span className="text-purple-700 lg:text-lg sm:text-sm font-semibold whitespace-nowrap">
							{team2captain}
						</span>
					</div>
				</div>
			</div>

			<Table
				data={displayedMatchups?.slice(0, 5)}
				columns={columns}
				className="w-full"
				headerClassName="text-center rounded-full"
				zebra={true}
				rounded={true}
				bordered={true}
				hover={true}
			/>
		</div>
	);
};

function StatPill({ label, value, color }) {
	const bgColor = color === "#4A38B7" ? "bg-[#4A38B7]" : "bg-green-500";

	return (
		<div className={`  text-center flex flex-col gap-2`}>
			<div
				className={`rounded-full p-1  text-white ${bgColor} lg:text-md sm:text-xs  font-medium whitespace-nowrap`}>
				{label}
			</div>
			<div className="text-black rounded-full p-1 border border-gray-100 text-md font-normal leading-tight whitespace-nowrap">
				{value}
			</div>
		</div>
	);
}
