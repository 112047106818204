import React from "react";

const MatchesStyle = ({ eachMatch }) => {
	const set1 = [eachMatch.Team1Set1, eachMatch.Team1Set2, eachMatch.Team1Set3];
	const set2 = [eachMatch.Team2Set1, eachMatch.Team2Set2, eachMatch.Team2Set3];

	// Creating the players list
	const players = [
		{ name: eachMatch.Player1Name, sets: set1 }, // Player 1 with set1 scores
		{ name: eachMatch.Player2Name, sets: set2 }, // Player 2 with set2 scores
	];

	return (
		<div className="flex flex-col items-start gap-1 whitespace-nowrap">
			<div className="border border-gray-300 w-[80vw] md:w-1/6">
				<table className="w-full table-auto border-collapse text-xs">
					<thead>
						<tr className="bg-gray-800 text-white">
							<th className="border border-gray-300 px-2 py-1 text-xs">
								{eachMatch.Position}
							</th>
							<th className="border border-gray-300 px-2 py-1 text-xs ">S1</th>
							<th className="border border-gray-300 px-2 py-1 text-xs ">S2</th>
							<th className="border border-gray-300 px-2 py-1 text-xs ">S3</th>
						</tr>
					</thead>
					<tbody>
						{players.map((player, index) => (
							<tr
								key={index}
								className={`${
									index % 2 === 0 ? "bg-gray-100" : "bg-white"
								} hover:bg-gray-200`}>
								<td className="border border-gray-300 px-2 py-1 text-xs font-medium">
									{player.name}
								</td>
								{player.sets.map((setScore, setIndex) => (
									<td
										key={setIndex}
										className="border border-gray-300 px-2 py-1 text-xs text-center">
										{setScore || 0}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default MatchesStyle;
