import {
	Container,
	Heading,
	useBreakpointValue,
	Flex,
	Stack,
	Box,
	HStack,
	IconButton,
	useColorModeValue,
	Tab,
	TabList,
	Tabs,
	TabPanel,
	TabPanels,
	useToast,
	Skeleton,
} from '@chakra-ui/react'
import { IoChevronBack } from 'react-icons/io5'
import { useLocation, useNavigate } from 'react-router-dom'
import { DoublesTeamTable } from '../../Components/EventTabsTables/DoublesTeamTable/DoublesTeamTable'
import { DoublesTeamTabCard } from '../../Components/EventTabsCards/DoublesTeamTabCard'
import { GroupsTabCard } from '../../Components/EventTabsCards/GroupsTabCard'
import { KnockoutGroupsTabCard } from '../../Components/EventTabsCards/KnockoutGroupsTabCard'
import { KnockoutMatchupsTabCard } from '../../Components/EventTabsCards/KnockoutMatchupsTabCard'
import { MatchupsTabCard } from '../../Components/EventTabsCards/MatchupsTabCard'
import { SinglesTabCard } from '../../Components/EventTabsCards/SinglesTabCard'
import { TeamsTabCard } from '../../Components/EventTabsCards/TeamsTabCard'
import { StandingsTabCard } from '../../Components/EventTabsCards/StandingsTabCard'
import { StreamingTabCard } from '../../Components/EventTabsCards/StreamingTabCard'
import { GroupsTable } from '../../Components/EventTabsTables/GroupsTable/GroupsTable'
import { KnockoutGroupsTable } from '../../Components/EventTabsTables/KnockoutGroupsTable/KnockoutGroupsTable'
import { KnockoutMatchupsTable } from '../../Components/EventTabsTables/KnockoutMatchupsTable/KnockoutMatchupsTable'
import { MatchupsTable } from '../../Components/EventTabsTables/MatchupsTable/MatchupsTable'
import { Navbar } from '../../Components/Navbar/Navbar'
import { Sidebar } from '../../Components/Sidebar/Sidebar'
import { SinglesPlayersTable } from '../../Components/EventTabsTables/SinglesPlayersTable/SinglesPlayersTable'
import { StandingsTable } from '../../Components/EventTabsTables/StandingsTable/StandingsTable'
import { StreamingTable } from '../../Components/EventTabsTables/StreamingTable/StreamingTable'
import { useEffect, useState } from 'react'
import ApiService from '../../service/Api.service'
import { DoublesPlayersTable } from '../../Components/EventTabsTables/DoublesPlayersTable/DoublesPlayersTable'
import { DoublesTabCard } from '../../Components/EventTabsCards/DoublesTabCard'
import { BallTriangle } from 'react-loader-spinner'
import { TeamsTable } from '../../Components/EventTabsTables/TeamsTable/TeamsTable'
import { PlayerSelectionTable } from '../../Components/EventTabsTables/PlayerSelectionTable/PlayerSelectionTable'
import { PlayerSelectionTabCard } from '../../Components/EventTabsCards/PlayerSelectionTabCard'
import { TeamMatchupsTabCard } from '../../Components/EventTabsCards/TeamMatchupsTabCard'
import { TeamMatchupsTable } from '../../Components/EventTabsTables/TeamMatchupsTable/TeamMatchupsTable'
import { LeaderboardTable } from '../../Components/EventTabsTables/LeaderboardTable/LeaderboardTable'
import { LeaderboardTabCard } from '../../Components/EventTabsCards/LeaderboardTabCard'
import { useAuthProvider } from '../../context/AuthProvider'

export const Events = () => {
	//Desktop BreakPoint Value
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	})
	//Page Header
	const pageHeader = useBreakpointValue({
		base: 'xs',
		sm: 'sm',
	})
	//Box Shadow
	const shadow = useColorModeValue('sm', 'sm-dark')

	let location = useLocation()
	let navigate = useNavigate()
	let toast = useToast()
	let eventType = location.state.EventType
	let eventId = location.state.EventId

	// Tab Index
	const [tabIndex, setTabIndex] = useState(0)

	const [singlesPlayers, setSinglesPlayers] = useState([])
	const [doublesPlayers, setDoublesPlayers] = useState([])
	const [doublesTeamData, setDoublesTeamData] = useState([])
	const [teamsGroups, setTeamsGroups] = useState([])
	const [teams, setTeams] = useState([])
	const [teamsPlayers, setTeamsPlayers] = useState([])
	const [teamSize, setTeamSize] = useState(0)
	const [isTeamEditable, setIsTeamEditable] = useState(null)
	const [teamsList, setTeamsList] = useState([])
	const [groups, setGroups] = useState([])
	const [selectedGroup, setSelectedGroup] = useState({})
	const [allGroupsData, setAllGroupsData] = useState([])
	const [groupsDataLength, setGroupsDataLength] = useState(0)
	const [groupsGroupName, setGroupsGroupName] = useState('')
	const [groupsData, setGroupsData] = useState([])
	const [plyrSelGroups, setPlyrSelGroups] = useState([])
	const [plyrSelRounds, setPlyrSelRounds] = useState([])
	const [selectedPlyrGroup, setSelectedPlyrGroup] = useState({})
	const [selectedPlyrRound, setSelectedPlyrRound] = useState({})
	const [plyrSelData, setPlyrSelData] = useState([])
	const [plyrSelPositions, setPlyrSelPositions] = useState([])
	const [matchUpGroups, setMatchupGroups] = useState([])
	const [selectedMatchGroup, setSelectedMatchGroup] = useState({})
	const [matchupRounds, setMatchupRounds] = useState([])
	const [selectedMatchRound, setSelectedMatchRound] = useState({})
	const [matchupsData, setMatchupsData] = useState([])
	const [matchupsInitData, setMatchupsInitData] = useState([])
	const [teamMatchupGroups, setTeamMatchupGroups] = useState([])
	const [teamMatchupRounds, setTeamMatchupRounds] = useState([])
	const [selctdTeamMatchGroup, setSelctdTeamMatchGroup] = useState({})
	const [selctdTeamMatchRound, setSelctdTeamMatchRound] = useState({})
	const [teamMatchupData, setTeamMatchupData] = useState([])
	const [teamMatchSet, setTeamMatchSet] = useState(1)
	const [teamMatchupInitData, setTeamMatchupInitData] = useState([])
	const [standingGroups, setStandingGroups] = useState([])
	const [selectedStandingsGroup, setSelectedStandingsGroup] = useState({})
	const [selectedStandingsClass, setSelectedStandingsClass] = useState('')
	const [selectedStandupRound, setSelectedStandupRound] = useState({})
	const [standingsData, setStandingsData] = useState([])
	const [standupRounds, setStandupRounds] = useState([])
	const [leaderGroups, setLeaderGroups] = useState([])
	const [selectedLeaderGroup, setSelectedLeaderGroup] = useState([])
	const [leaderboardData, setLeaderboardData] = useState([])
	const [koGroups, setKOGroups] = useState([])
	const [knockoutMatrixData, setKnockoutMatrixData] = useState([])
	const [kmStatus, setKMStatus] = useState(true)
	const [knockoutGroupsData, setKnockoutGroupsData] = useState([])
	const [disableKGAdd, setDisableKGAdd] = useState(false)
	const [knockoutMatchupsGroupsData, setKnockoutMatchupsGroupsData] = useState(
		[]
	)
	const [selectedKOMatchGroup, setSelectedKOMatchGroup] = useState('')
	const [koMatchupsInitData, setKOMatchupsInitData] = useState([])
	const [knockoutMatchupsData, setKnockoutMatchupsData] = useState([])
	const [streamingGroups, setStreamingGroups] = useState([])
	const [selectedStreamingGroup, setSelectedStreamingGroup] = useState('')
	const [streamingConfigurationsData, setStreamingConfigurationsData] =
		useState([])

	// Search Query
	const [querySinglesPlayers, setQuerySinglesPlayers] = useState('')
	const [queryDoublesPlayers, setQueryDoublesPlayers] = useState('')
	const [queryDoublesTeam, setQueryDoublesTeam] = useState('')
	const [queryMatchups, setQueryMatchups] = useState('')
	const [queryTeamMatchups, setQueryTeamMatchups] = useState('')
	const [queryStandings, setQueryStandings] = useState('')

	// Reload States
	let [teamsReload, setTeamsReload] = useState(false)
	let [singlesReload, setSinglesReload] = useState(false)
	let [doublesReload, setDoublesReload] = useState(true)

	// Loading States
	let [loading, setLoading] = useState(false)
	const [groupsLookupLoad, setGroupsLookupLoad] = useState(false)
	const [roundsLookupLoad, setRoundsLookupLoad] = useState(false)
	const [singlesLoading, setSinglesLoading] = useState(false)
	const [doublesLoading, setDoublesLoading] = useState(false)
	const [doublesTeamLoad, setDoublesTeamLoad] = useState(false)
	const [teamsLoading, setTeamsLoading] = useState(false)
	const [teamsModalLoad, setTeamsModalLoad] = useState(false)
	const [plyrSelLoading, setPlyrSelLoading] = useState(false)
	const [plyrSelModalLoad, setPlyrSelModalLoad] = useState(false)
	const [teamMatchupLoad, setTeamMatchupLoad] = useState(false)
	const [standingsLoad, setStandingsLoad] = useState(false)
	const [streamingLoad, setStreamingLoad] = useState(false)
	const [groupsDataLoading, setGroupsDataLoading] = useState(false)
	const [matchupsLoad, setMatchupsLoad] = useState(false)
	const [leaderGroupsLoad, setLeaderGroupsLoad] = useState(false)
	const [leaderLoad, setLeaderLoad] = useState(false)
	const [koMatrixLoad, setKOMatrixLoad] = useState(false)
	const [koGroupsLoad, setKOGroupsLoad] = useState(false)
	const [koMatchupsLoad, setKOMatchupsLoad] = useState(false)
	const [generateLoading, setGenerateLoading] = useState(false)

	// Event Flags
	const [eventFlags, setEventFlags] = useState(null)

	// User Details
	const user = useAuthProvider()

	//---------------------------------------Singles Players Tab---------------------------------------------
	const fetchSingles = async () => {
		setSinglesLoading(true)
		try {
			const response = await ApiService.httpGet(
				'/TourPlayers?PlayersEventId=' + eventId,
				user.userId
			)
			setSinglesPlayers(response.Players)
		} catch (err) {
			console.error(err.message)
		} finally {
			setSinglesLoading(false)
		}
	}

	//---------------------------------------Doubles Players Tab---------------------------------------------
	const fetchDoubles = async () => {
		setDoublesLoading(true)
		try {
			const response =
				eventType === 'Doubles'
					? await ApiService.httpGet(
							'/TourPlayers?PlayersEventId=' + eventId,
							user.userId
					  )
					: await ApiService.httpGet(
							'/EventTeams?TeamsEventId=' + eventId,
							user.userId
					  )
			setDoublesPlayers(
				eventType === 'Doubles' ? response.Players : response.EventTeams
			)
		} catch (err) {
			console.error(err.message)
		} finally {
			setDoublesLoading(false)
		}
	}

	//----------------------------------------Groups Tab-----------------------------------------------------
	const updateGroupsData = (event) => {
		setGroupsDataLoading(true)
		let groupData = allGroupsData.find(
			(group) => group.GroupName === event.target.value
		)
		setSelectedGroup(
			groups.find((group) => group.GroupName === event.target.value)
		)
		setGroupsData(groupData?.Players)
		setGroupsGroupName(groupData?.GroupName)
		setGroupsDataLength(groupData?.PlayerCount)
		setGroupsDataLoading(false)
	}
	const refreshGroups = async () => {
		try {
			setGroupsLookupLoad(true)
			setGroupsDataLoading(true)
			const groupsLookup = await ApiService.httpGet(
				'/EventTeams?GroupsEventId=' + eventId,
				user.userId
			)
			setGroups(groupsLookup.GroupNames)
			setGroupsLookupLoad(false)
			const groupsResponse = await ApiService.httpGet(
				'/EventTeams?EventId=' + eventId,
				user.userId
			)
			setAllGroupsData(groupsResponse.Groups)
			let data = groupsResponse.Groups.find(
				(group) => group.GroupName === selectedGroup.GroupName
			)
			setGroupsData(data?.Players)
			setGroupsGroupName(data?.GroupName)
			setGroupsDataLength(data?.PlayerCount)
			setGroupsDataLoading(false)
		} catch (err) {
			console.log(err.message)
		} finally {
			setGroupsLookupLoad(false)
			setGroupsDataLoading(false)
		}
	}
	const generateGroups = async () => {
		setGenerateLoading(true)
		try {
			const response = await ApiService.httpGet(
				`/EventTeams?TourEventId=${eventId}&TourId=${location.state.TourId}`,
				user.userId
			)
			setGroupsLookupLoad(true)
			setGroupsDataLoading(true)
			const groupsLookup = await ApiService.httpGet(
				'/EventTeams?GroupsEventId=' + eventId,
				user.userId
			)
			setGroups(groupsLookup.GroupNames)
			setGroupsLookupLoad(false)
			const groupsResponse = await ApiService.httpGet(
				'/EventTeams?EventId=' + location.state.EventId,
				user.userId
			)
			setAllGroupsData(groupsResponse.Groups)
			let data = groupsResponse.Groups[0]
			setSelectedGroup(groupsLookup.GroupNames[0])
			setGroupsData(data?.Players)
			setGroupsGroupName(data?.GroupName)
			setGroupsDataLength(data?.PlayerCount)
			setGroupsDataLoading(false)
			if (response.status === true) {
				toast({
					title: 'Generate Groups!',
					description: 'Groups Generated Successfully.',
					status: 'success',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				setTabIndex(location.state.EventType === 'Doubles' ? 2 : 1)
				fetchFlags()
			} else {
				toast({
					title: 'Generate Groups!',
					description: response.Message,
					status: 'warning',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
			}
		} catch (err) {
			toast({
				title: 'Generate Groups!',
				description: 'Groups Generation failed.',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
		} finally {
			setGenerateLoading(false)
			setGroupsLookupLoad(false)
			setGroupsDataLoading(false)
		}
	}

	//---------------------------------------Doubles Teams Tab-----------------------------------------------
	const fetchDoublesTeams = async () => {
		setDoublesTeamLoad(true)
		try {
			let doublesEventTeamsData = await ApiService.httpGet(
				'/DoublesEventTeams?EventId=' + location.state.EventId,
				user.userId
			)
			setDoublesTeamData(doublesEventTeamsData.DoublesEventTeamId)
		} catch (err) {
			console.log(err)
		} finally {
			setDoublesTeamLoad(false)
		}
	}

	//---------------------------------------Teams Tab-------------------------------------------------------
	// For fetching teams list
	const fetchTeamsList = async () => {
		setTeamsLoading(true)
		try {
			let groupsRes = await ApiService.httpGet(
				'/TeamEventTeams?Class=GroupsLookUp&TourEventId=' + eventId,
				user.userId
			)
			if ('GroupNames' in groupsRes) {
				setTeamsGroups(groupsRes.GroupNames)
				let teamsRes = await ApiService.httpGet(
					`/TeamEventTeams?Class=Teams&TourEventId=${eventId}`,
					user.userId
				)
				setTeamsList(teamsRes.TeamNames)
				setIsTeamEditable(teamsRes.isEditable)
			}
		} catch (err) {
			console.log(err)
		} finally {
			setTeamsLoading(false)
		}
	}
	// This consists of TeamSize lookup, TeamPlayersLookup and PlayersInfo
	const fetchTeamsData = async () => {
		setTeamsModalLoad(true)
		await fetchTeamsList()
		Promise.all([
			ApiService.httpGet(
				'/TeamEventTeams?Class=TeamSizeLookUp&TourEventId=' + eventId,
				user.userId
			),
			ApiService.httpGet(
				'/TeamEventTeams?Class=PlayersInfo&TourEventId=' + eventId,
				user.userId
			),
			ApiService.httpGet(
				'/TeamEventTeams?Class=PlayersLookUp&TourEventId=' + eventId,
				user.userId
			),
		])
			.then(function (responses) {
				// Get a JSON object from each of the responses
				return Promise.all(
					responses.map(function (response) {
						return response
					})
				)
			})
			.then(function (data) {
				setTeamSize(data[0].TeamSize[0].TeamSize)
				setTeams(data[1].Teams)
				setTeamsPlayers(data[2].Players)
			})
			.catch(function (error) {
				// if there's an error, log it
				console.log(error)
			})
			.finally(function () {
				setTeamsModalLoad(false)
			})
	}

	//---------------------------------------Player Selection Tab--------------------------------------------
	const updatePlyrSelection = async (groupId, roundNo) => {
		setGroupsLookupLoad(true)
		setRoundsLookupLoad(true)
		setPlyrSelLoading(true)
		setPlyrSelModalLoad(true)
		console.log('Updated Group', groupId)
		setSelectedPlyrGroup(
			plyrSelGroups.find((group) => group.GroupId === groupId)
		)
		setGroupsLookupLoad(false)
		if (groupId in plyrSelRounds) {
			if (selectedPlyrGroup.GroupId !== groupId) {
				console.log('Updated RoundNo', roundNo)
				setSelectedPlyrRound(
					plyrSelRounds[groupId].find((round) => round.Latest === 'YES')
				)
				setRoundsLookupLoad(false)
				roundNo = plyrSelRounds[groupId].find(
					(round) => round.Latest === 'YES'
				).RoundNo
			} else {
				setSelectedPlyrRound(
					plyrSelRounds[groupId].find(
						(round) => round.RoundNo === Number(roundNo)
					)
				)
				setRoundsLookupLoad(false)
			}
			try {
				let response = await ApiService.httpGet(
					`/TeamsRoundPlayers?Class=Rounds&TourEventId=${eventId}&RoundNo=${roundNo}&GroupName=${groupId}`,
					user.userId
				)
				setPlyrSelData(response)
				setPlyrSelLoading(false)
				let plyrPos = await ApiService.httpGet(
					`/TeamsRoundPlayers?Class=PositionsLookUp&TourEventId=${eventId}&GroupName=${groupId}`,
					user.userId
				)
				setPlyrSelPositions(plyrPos.Lookup)
				setPlyrSelModalLoad(false)
			} catch (err) {
				console.log(err)
			} finally {
				setGroupsLookupLoad(false)
				setRoundsLookupLoad(false)
				setPlyrSelLoading(false)
				setPlyrSelModalLoad(false)
			}
		} else {
			toast({
				title: 'Player Selection',
				description: "Rounds aren't generated yet for this Group",
				status: 'warning',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
			setPlyrSelData([])
			setPlyrSelPositions([])
		}
	}
	const latestRoundPlyrSelection = async () => {
		setGroupsLookupLoad(true)
		setRoundsLookupLoad(true)
		setPlyrSelLoading(true)
		setPlyrSelModalLoad(true)
		try {
			let plyrSelGroup = await ApiService.httpGet(
				'/TeamEventTeams?Class=GroupsLookUp&TourEventId=' + eventId,
				user.userId
			)
			let plyrSelRound = await ApiService.httpGet(
				'/TeamEventTeams?Class=PSRoundLookup&TourEventId=' +
					location.state.EventId,
				user.userId
			)
			if ('GroupNames' in plyrSelGroup) {
				setPlyrSelGroups(plyrSelGroup.GroupNames)
				if (plyrSelGroup.GroupNames.length > 0) {
					let selectedGroup = plyrSelGroup.GroupNames[0]
					setSelectedPlyrGroup(selectedGroup)
					if (selectedGroup.GroupId in plyrSelRound.Rounds) {
						setPlyrSelRounds(plyrSelRound.Rounds)
						let selectedRound = plyrSelRound.Rounds[selectedGroup.GroupId].find(
							(round) => round.Latest === 'YES'
						)
						setSelectedPlyrRound(selectedRound)
						setRoundsLookupLoad(false)
						let response = await ApiService.httpGet(
							`/TeamsRoundPlayers?Class=Rounds&TourEventId=${eventId}&RoundNo=${selectedRound.RoundNo}&GroupName=${selectedGroup.GroupId}`,
							user.userId
						)
						setPlyrSelData(response)
						setPlyrSelLoading(false)
						let plyrPos = await ApiService.httpGet(
							`/TeamsRoundPlayers?Class=PositionsLookUp&TourEventId=${eventId}&GroupName=${selectedGroup.GroupId}`,
							user.userId
						)
						setPlyrSelPositions(plyrPos.Lookup)
						setPlyrSelModalLoad(false)
					} else {
						toast({
							title: 'Player Selection',
							description: "Rounds aren't generated yet for this Group",
							status: 'warning',
							duration: 3000,
							isClosable: true,
							position: 'top-right',
						})
						setPlyrSelData([])
					}
				} else {
					toast({
						title: 'Player Selection',
						description: "Groups aren't available",
						status: 'warning',
						duration: 3000,
						isClosable: true,
						position: 'top-right',
					})
					setPlyrSelData([])
				}
			} else {
				toast({
					title: 'Player Selection',
					description: "Groups aren't available",
					status: 'warning',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				setPlyrSelData([])
			}
		} catch (err) {
			console.log(err)
			setPlyrSelData([])
		} finally {
			setGroupsLookupLoad(false)
			setRoundsLookupLoad(false)
			setPlyrSelLoading(false)
			setPlyrSelModalLoad(false)
		}
	}
	const refreshPlyrSelection = async () => {
		setPlyrSelLoading(true)
		try {
			let response = await ApiService.httpGet(
				`/TeamsRoundPlayers?Class=Rounds&TourEventId=${eventId}&RoundNo=${selectedPlyrRound?.RoundNo}&GroupName=${selectedPlyrGroup?.GroupId}`,
				user.userId
			)
			setPlyrSelData(response)
			setPlyrSelLoading(false)
		} catch (err) {
			console.log(err)
		} finally {
			setPlyrSelLoading(false)
		}
	}
	//---------------------------------------Team Matchups Tab-----------------------------------------------
	const updateTeamMatchSelection = async (groupId, roundNo) => {
		setGroupsLookupLoad(true)
		setRoundsLookupLoad(true)
		setTeamMatchupLoad(true)
		console.log('Updated GroupId', groupId)
		setSelctdTeamMatchGroup(
			teamMatchupGroups.find((group) => group.GroupId === groupId)
		)
		setGroupsLookupLoad(false)
		if (groupId in teamMatchupRounds) {
			if (selctdTeamMatchGroup.GroupId !== groupId) {
				console.log('Updated RoundNo', roundNo)
				setSelctdTeamMatchRound(
					teamMatchupRounds[groupId].find((round) => round.Latest === 'YES')
				)
				roundNo = teamMatchupRounds[groupId].find(
					(round) => round.Latest === 'YES'
				).RoundNo
				setRoundsLookupLoad(false)
			} else {
				setSelctdTeamMatchRound(
					teamMatchupRounds[groupId].find(
						(round) => round.RoundNo === Number(roundNo)
					)
				)
				setRoundsLookupLoad(false)
			}
			try {
				let updatedReponse = await ApiService.httpGet(
					`/TourEventMatchupsByGroup?TourEventId=${eventId}&GroupName=${groupId}&RoundNo=${roundNo}&Class=Teams`,
					user.userId
				)
				setTeamMatchupInitData(updatedReponse.Teams)
				setTeamMatchupData(updatedReponse.Teams)
				setTeamMatchSet(updatedReponse.SetCount)
				setTeamMatchupLoad(false)
			} catch (err) {
				console.log(err)
				setTeamMatchupInitData([])
				setTeamMatchupData([])
			} finally {
				setGroupsLookupLoad(false)
				setRoundsLookupLoad(false)
				setTeamMatchupLoad(false)
			}
		} else {
			toast({
				title: 'Matchups',
				description: "Rounds aren't generated yet for this group",
				status: 'warning',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
			setTeamMatchupInitData([])
			setTeamMatchupData([])
		}
	}
	const refreshTeamMatchups = async () => {
		setTeamMatchupLoad(true)
		console.log('Team Matchups Refreshed!')
		try {
			let updatedReponse = await ApiService.httpGet(
				`/TourEventMatchupsByGroup?TourEventId=${eventId}&GroupName=${selctdTeamMatchGroup.GroupId}&RoundNo=${selctdTeamMatchRound.RoundNo}&Class=Teams`,
				user.userId
			)
			setTeamMatchupInitData(updatedReponse.Teams)
			setTeamMatchupData(updatedReponse.Teams)
			setTeamMatchSet(updatedReponse.SetCount)
			setTeamMatchupLoad(false)
		} catch (err) {
			console.log(err)
		} finally {
			setTeamMatchupLoad(false)
		}
	}

	//---------------------------------------Matchups Tab----------------------------------------------------
	const updateMatchupsData = async (groupName, roundNo) => {
		setGroupsLookupLoad(true)
		setRoundsLookupLoad(true)
		setMatchupsLoad(true)
		setSelectedMatchGroup({
			GroupId: groupName,
			GroupName: `Group - ${groupName}`,
		})
		setGroupsLookupLoad(false)
		setSelectedMatchRound({
			RoundNo: roundNo,
			RoundName: `Round - ${roundNo}`,
		})
		setRoundsLookupLoad(false)
		try {
			let updatedResponse = await ApiService.httpGet(
				`/TourEventMatchupsByGroup?TourEventId=${location.state.EventId}&RoundNo=${roundNo}&GroupName=${groupName}`,
				user.userId
			)
			console.log('Updated Matchups Data:', updatedResponse.TourEventMatchups)
			setMatchupsInitData(updatedResponse.TourEventMatchups)
			setMatchupsData(updatedResponse.TourEventMatchups)
			setMatchupsLoad(false)
		} catch (err) {
			console.log(err)
			setMatchupsInitData([])
			setMatchupsData([])
		} finally {
			setGroupsLookupLoad(false)
			setRoundsLookupLoad(false)
			setMatchupsLoad(false)
		}
	}
	const refreshMatchups = async () => {
		setMatchupsLoad(true)
		if (
			Object.keys(selectedMatchRound).length !== 0 &&
			Object.keys(selectedMatchGroup).length !== 0
		) {
			try {
				let response3 = await ApiService.httpGet(
					`/TourEventMatchupsByGroup?TourEventId=${location.state.EventId}&RoundNo=${selectedMatchRound.RoundNo}&GroupName=${selectedMatchGroup.GroupId}`,
					user.userId
				)
				setMatchupsInitData(response3.TourEventMatchups)
				setMatchupsData(response3.TourEventMatchups)
				setMatchupsLoad(false)
			} catch (err) {
				console.log(err)
				setMatchupsInitData([])
				setMatchupsData([])
			} finally {
				setMatchupsLoad(false)
			}
		} else {
			toast({
				title: 'Refresh Matchups',
				description: 'Failed to Refresh Matchups.',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top-right',
			})
			setMatchupsInitData([])
			setMatchupsData([])
			setMatchupsLoad(false)
		}
	}
	const refreshMatchupsView = async () => {
		setGroupsLookupLoad(true)
		setRoundsLookupLoad(true)
		setMatchupsLoad(true)
		try {
			let matchupGroups = await ApiService.httpGet(
				'/EventTeams?GroupsEventId=' + eventId,
				user.userId
			)
			if ('GroupNames' in matchupGroups) {
				setMatchupGroups(matchupGroups.GroupNames)
				if (matchupGroups.GroupNames.length > 0) {
					let selectedGroup = matchupGroups.GroupNames[0]
					setSelectedMatchGroup(selectedGroup)
					setGroupsLookupLoad(false)
					let matchupsRounds = await ApiService.httpGet(
						'/TourEventMatchupsByGroup?RoundsByTourEventId=' + eventId,
						user.userId
					)
					if ('Rounds' in matchupsRounds) {
						setMatchupRounds(matchupsRounds.Rounds)
						if (matchupsRounds.Rounds.length > 0) {
							let selectedRound = matchupsRounds.Rounds.find(
								(round) => round.Latest === 'YES'
							)
							setSelectedMatchRound(selectedRound)
							setRoundsLookupLoad(false)
							let response3 = await ApiService.httpGet(
								`/TourEventMatchupsByGroup?TourEventId=${location.state.EventId}&RoundNo=${selectedRound.RoundNo}&GroupName=${selectedGroup.GroupId}`,
								user.userId
							)
							setMatchupsInitData(response3.TourEventMatchups)
							setMatchupsData(response3.TourEventMatchups)
							setMatchupsLoad(false)
						} else {
							toast({
								title: 'Matchups',
								description: "Rounds aren't available yet for this group",
								status: 'warning',
								duration: 3000,
								isClosable: true,
								position: 'top-right',
							})
							setMatchupsInitData([])
							setMatchupsData([])
						}
					} else {
						toast({
							title: 'Matchups',
							description: "Rounds aren't generated yet for this group",
							status: 'warning',
							duration: 3000,
							isClosable: true,
							position: 'top-right',
						})
						setMatchupsInitData([])
						setMatchupsData([])
					}
				} else {
					toast({
						title: 'Matchups',
						description: "Groups aren't available",
						status: 'warning',
						duration: 3000,
						isClosable: true,
						position: 'top-right',
					})
					setMatchupsInitData([])
					setMatchupsData([])
				}
			} else {
				toast({
					title: 'Matchups',
					description: "Groups aren't available",
					status: 'warning',
					duration: 3000,
					isClosable: true,
					position: 'top-right',
				})
				setMatchupsInitData([])
				setMatchupsData([])
			}
		} catch (err) {
			console.log(err)
			setMatchupsInitData([])
			setMatchupsData([])
		} finally {
			setGroupsLookupLoad(false)
			setRoundsLookupLoad(false)
			setMatchupsLoad(false)
		}
	}

	//---------------------------------------Standings Tab---------------------------------------------------
	const updateStandingsData = async (groupName, roundNo) => {
		setGroupsLookupLoad(true)
		setRoundsLookupLoad(true)
		setStandingsLoad(true)
		try {
			if (eventType !== 'Teams') {
				console.log('Updated GroupName', groupName)
				console.log('Updated RoundNo', roundNo)
				setSelectedStandingsGroup({
					GroupId: groupName,
					GroupName: `Group - ${groupName}`,
				})
				setGroupsLookupLoad(false)
				setSelectedStandupRound({
					RoundNo: roundNo,
					RoundName: `Round - ${roundNo}`,
				})
				setRoundsLookupLoad(false)
				let updatedResponse = await ApiService.httpGet(
					`/TourStandings?TourEventId=${location.state.EventId}&RoundNo=${roundNo}&GroupName=${groupName}`,
					user.userId
				)
				console.log('Updated Standings Data:', updatedResponse.TourStandings)
				setStandingsData(updatedResponse.TourStandings)
				setStandingsLoad(false)
			}
		} catch (err) {
			console.log(err)
		} finally {
			setGroupsLookupLoad(false)
			setRoundsLookupLoad(false)
			setStandingsLoad(false)
		}
	}
	const updateLeaderData = async (groupName) => {
		setLeaderGroupsLoad(true)
		setLeaderLoad(true)
		try {
			if (eventType !== 'Teams') {
				console.log('Updated GroupName', groupName)
				setSelectedLeaderGroup(groupName)
				setLeaderGroupsLoad(false)
				let updatedResponse = await ApiService.httpGet(
					`/TourStandings?Class=LeaderBoard&TourEventId=${eventId}&GroupName=${groupName}`,
					user.userId
				)
				console.log('Updated Leaderboard Data:', updatedResponse.LeaderBoard)
				setLeaderboardData(updatedResponse.LeaderBoard)
				setLeaderLoad(false)
			}
		} catch (err) {
			console.log(err)
		} finally {
			setLeaderGroupsLoad(false)
			setLeaderLoad(false)
		}
	}

	//---------------------------------------Team Standings Tab----------------------------------------------
	const updateTeamStandings = async (groupId, roundNo, className) => {
		setGroupsLookupLoad(true)
		setRoundsLookupLoad(true)
		setStandingsLoad(true)
		try {
			if (eventType === 'Teams') {
				console.log('Updated GroupName', groupId)
				setSelectedStandingsClass(className)
				console.log('Updated Class', className)
				setSelectedStandingsGroup(
					standingGroups.find((group) => group.GroupId === groupId)
				)
				setGroupsLookupLoad(false)
				if (groupId in standupRounds) {
					if (selectedStandingsGroup.GroupId !== groupId) {
						console.log('Updated RoundNo', roundNo)
						setSelectedStandupRound(
							standupRounds[groupId].find((round) => round.Latest === 'YES')
						)
						setRoundsLookupLoad(false)
						roundNo = standupRounds[groupId].find(
							(round) => round.Latest === 'YES'
						).RoundNo
					} else {
						setSelectedStandupRound(
							standupRounds[groupId].find(
								(round) => round.RoundNo === Number(roundNo)
							)
						)
						setRoundsLookupLoad(false)
					}
					let response = await ApiService.httpGet(
						`/TourStandings?TourEventId=${eventId}&GroupName=${groupId}&RoundNo=${roundNo}&Class=${className}`,
						user.userId
					)
					setStandingsData(response.TourStandings)
					setStandingsLoad(false)
				} else {
					toast({
						title: 'Standings',
						description: "Rounds aren't generated for this Group",
						status: 'warning',
						duration: 3000,
						isClosable: true,
						position: 'top-right',
					})
					setStandingsData([])
				}
			}
		} catch (err) {
			console.log(err)
		} finally {
			setGroupsLookupLoad(false)
			setRoundsLookupLoad(false)
			setStandingsLoad(false)
		}
	}

	//---------------------------------------KO Config Tab---------------------------------------------------
	const refreshKOGroups = async () => {
		setKOMatrixLoad(true)
		setKOGroupsLoad(true)
		try {
			let matrix = await ApiService.httpGet(
				'/KnockoutMatrix?Class=KM&EventId=' + location.state.EventId,
				user.userId
			)
			console.log(matrix)
			setKnockoutMatrixData(matrix.KnockoutMatrix)
			setKMStatus(matrix.status)
			setKOMatrixLoad(false)
			let groups = await ApiService.httpGet(
				'/KnockoutMatrix?Class=KG&EventId=' + location.state.EventId,
				user.userId
			)
			console.log(groups)
			setKnockoutGroupsData(groups.KOGroups)
			setDisableKGAdd(false)
			setKOGroupsLoad(false)
		} catch (err) {
			console.log(err)
		} finally {
			setKOMatrixLoad(false)
			setKOGroupsLoad(false)
		}
	}

	//---------------------------------------KO Matchups Tab-------------------------------------------------
	const updateKOMatchUpsData = async (group) => {
		setKOMatchupsLoad(true)
		setSelectedKOMatchGroup(group)
		try {
			let KMResponse = await ApiService.httpGet(
				`/KnockoutMatchups?EventId=${location.state.EventId}&GroupName=${group}`,
				user.userId
			)
			setKOMatchupsInitData(KMResponse.Knockouts)
			setKnockoutMatchupsData(KMResponse.Knockouts)
			setKOMatchupsLoad(false)
		} catch (err) {
			console.log(err)
		} finally {
			setKOMatchupsLoad(false)
		}
	}
	const refreshKOMatchUpsView = async () => {
		setGroupsLookupLoad(true)
		setKOMatchupsLoad(true)
		try {
			let response = await ApiService.httpGet(
				'/KnockoutMatchups?GroupsByEventId=' + location.state.EventId,
				user.userId
			)
			setKnockoutMatchupsGroupsData(response.GroupNames)
			setGroupsLookupLoad(false)
			let KMResponse = await ApiService.httpGet(
				`/KnockoutMatchups?EventId=${location.state.EventId}&GroupName=${selectedKOMatchGroup}`,
				user.userId
			)
			setKOMatchupsInitData(KMResponse.Knockouts)
			setKnockoutMatchupsData(KMResponse.Knockouts)
			setKOMatchupsLoad(false)
		} catch (err) {
			console.log(err)
		} finally {
			setGroupsLookupLoad(false)
			setKOMatchupsLoad(false)
		}
	}
	const refreshKOMatchUps = async () => {
		setKOMatchupsLoad(true)
		try {
			let KMResponse = await ApiService.httpGet(
				`/KnockoutMatchups?EventId=${location.state.EventId}&GroupName=${selectedKOMatchGroup}`,
				user.userId
			)
			setKOMatchupsInitData(KMResponse.Knockouts)
			setKnockoutMatchupsData(KMResponse.Knockouts)
			setKOMatchupsLoad(false)
		} catch (err) {
			console.log(err)
		} finally {
			setKOMatchupsLoad(false)
		}
	}

	//---------------------------------------Streaming Tab---------------------------------------------------
	const refreshStreaming = async () => {
		setStreamingLoad(true)
		try {
			// if (eventType !== "Teams") {
			let scResponse = await ApiService.httpGet(
				`/StreamingConfigurations?Class=ScInfo&TourEventId=${location.state.EventId}`,
				user.userId
			)
			setStreamingConfigurationsData(scResponse.StreamingConf)
			setStreamingLoad(false)
			// } else {
			// 	let scResponse = await ApiService.httpGet(
			// 		`/StreamingConfigurations?TourEventId=${location.state.EventId}`, user.userId
			// 	);
			// 	console.log("Streaming Configuration Data: ", scResponse);
			// 	setStreamingConfigurationsData(scResponse.StreamingConf);
			// }
		} catch (err) {
			console.log(err)
		} finally {
			setStreamingLoad(false)
		}
	}

	// Tabs Change Handling and API calls on tab change
	const handleTabsChange = async (index) => {
		setTabIndex(index)
		if (eventType === 'Doubles') {
			index = index - 1
		}
		if (index === 1 && eventType === 'Teams') {
			setTeamsModalLoad(true)
			await fetchTeamsList()
			Promise.all([
				ApiService.httpGet(
					'/TeamEventTeams?Class=TeamSizeLookUp&TourEventId=' + eventId,
					user.userId
				),
				ApiService.httpGet(
					'/TeamEventTeams?Class=PlayersInfo&TourEventId=' + eventId,
					user.userId
				),
				ApiService.httpGet(
					'/TeamEventTeams?Class=PlayersLookUp&TourEventId=' + eventId,
					user.userId
				),
			])
				.then(function (responses) {
					// Get a JSON object from each of the responses
					return Promise.all(
						responses.map(function (response) {
							return response
						})
					)
				})
				.then(function (data) {
					setTeamSize(data[0].TeamSize[0].TeamSize)
					setTeams(data[1].Teams)
					setTeamsPlayers(data[2].Players)
				})
				.catch(function (error) {
					// if there's an error, log it
					console.log(error)
				})
				.finally(function () {
					setTeamsModalLoad(false)
				})
		} else if (index === 2 && eventType === 'Teams') {
			setGroupsLookupLoad(true)
			setRoundsLookupLoad(true)
			setPlyrSelLoading(true)
			setPlyrSelModalLoad(true)
			try {
				let plyrSelGroup = await ApiService.httpGet(
					'/TeamEventTeams?Class=GroupsLookUp&TourEventId=' + eventId,
					user.userId
				)
				let plyrSelRound = await ApiService.httpGet(
					'/TeamEventTeams?Class=PSRoundLookup&TourEventId=' +
						location.state.EventId,
					user.userId
				)
				if ('GroupNames' in plyrSelGroup) {
					setPlyrSelGroups(plyrSelGroup.GroupNames)
					if (plyrSelGroup.GroupNames.length > 0) {
						let selectedGroup = plyrSelGroup.GroupNames[0]
						setSelectedPlyrGroup(selectedGroup)
						setGroupsLookupLoad(false)
						setPlyrSelRounds(plyrSelRound.Rounds)
						if (selectedGroup.GroupId in plyrSelRound.Rounds) {
							let selectedRound = plyrSelRound.Rounds[
								selectedGroup.GroupId
							].find((round) => round.Latest === 'YES')
							setSelectedPlyrRound(selectedRound)
							setRoundsLookupLoad(false)
							let response = await ApiService.httpGet(
								`/TeamsRoundPlayers?Class=Rounds&TourEventId=${eventId}&RoundNo=${selectedRound.RoundNo}&GroupName=${selectedGroup.GroupId}`,
								user.userId
							)
							setPlyrSelData(response)
							setPlyrSelLoading(false)
							let plyrPos = await ApiService.httpGet(
								`/TeamsRoundPlayers?Class=PositionsLookUp&TourEventId=${eventId}&GroupName=${selectedGroup.GroupId}`,
								user.userId
							)
							setPlyrSelPositions(plyrPos.Lookup)
							setPlyrSelModalLoad(false)
						} else {
							toast({
								title: 'Player Selection',
								description: "Rounds aren't generated yet fot this Group",
								status: 'warning',
								duration: 3000,
								isClosable: true,
								position: 'top-right',
							})
							setPlyrSelData([])
						}
					} else {
						toast({
							title: 'Player Selection',
							description: "Groups aren't available",
							status: 'warning',
							duration: 3000,
							isClosable: true,
							position: 'top-right',
						})
						setPlyrSelData([])
					}
				} else {
					toast({
						title: 'Player Selection',
						description: "Groups aren't available",
						status: 'warning',
						duration: 3000,
						isClosable: true,
						position: 'top-right',
					})
					setPlyrSelData([])
				}
			} catch (err) {
				console.log(err)
				setPlyrSelData([])
			} finally {
				setGroupsLookupLoad(false)
				setRoundsLookupLoad(false)
				setPlyrSelLoading(false)
				setPlyrSelModalLoad(false)
			}
		} else if (index === 3 && eventType === 'Teams') {
			setGroupsLookupLoad(true)
			setRoundsLookupLoad(true)
			setTeamMatchupLoad(true)
			try {
				let matchupGroup = await ApiService.httpGet(
					'/TeamEventTeams?Class=GroupsLookUp&TourEventId=' + eventId,
					user.userId
				)
				let matchupRound = await ApiService.httpGet(
					'/TeamEventTeams?Class=MatchesRoundLookup&TourEventId=' + eventId,
					user.userId
				)
				if ('GroupNames' in matchupGroup) {
					setTeamMatchupGroups(matchupGroup.GroupNames)
					if (matchupGroup.GroupNames.length > 0) {
						let selectedGroup = matchupGroup.GroupNames[0]
						setSelctdTeamMatchGroup(selectedGroup)
						setGroupsLookupLoad(false)
						setTeamMatchupRounds(matchupRound?.Rounds)
						if (selectedGroup.GroupId in matchupRound.Rounds) {
							let selectedRound = matchupRound?.Rounds[
								selectedGroup.GroupId
							].find((round) => round.Latest === 'YES')
							setSelctdTeamMatchRound(selectedRound)
							setRoundsLookupLoad(false)
							console.log(selectedRound)
							let matchupsReponse = await ApiService.httpGet(
								`/TourEventMatchupsByGroup?TourEventId=${eventId}&GroupName=${selectedGroup.GroupId}&RoundNo=${selectedRound?.RoundNo}&Class=Teams`,
								user.userId
							)
							setTeamMatchupInitData(matchupsReponse.Teams)
							setTeamMatchupData(matchupsReponse.Teams)
							setTeamMatchSet(matchupsReponse.SetCount)
							setTeamMatchupLoad(false)
						} else {
							toast({
								title: 'Matchups',
								description: "Rounds aren't generated yet for this group",
								status: 'warning',
								duration: 3000,
								isClosable: true,
								position: 'top-right',
							})
							setTeamMatchupInitData([])
							setTeamMatchupData([])
						}
					} else {
						toast({
							title: 'Matchups',
							description: "Groups aren't available",
							status: 'warning',
							duration: 3000,
							isClosable: true,
							position: 'top-right',
						})
						setTeamMatchupInitData([])
						setTeamMatchupData([])
					}
				} else {
					toast({
						title: 'Matchups',
						description: "Groups aren't available",
						status: 'warning',
						duration: 3000,
						isClosable: true,
						position: 'top-right',
					})
					setTeamMatchupInitData([])
					setTeamMatchupData([])
				}
			} catch (err) {
				console.log(err)
				setTeamMatchupInitData([])
				setTeamMatchupData([])
			} finally {
				setGroupsLookupLoad(false)
				setRoundsLookupLoad(false)
				setTeamMatchupLoad(false)
			}
		} else if (index === 4 && eventType === 'Teams') {
			setGroupsLookupLoad(true)
			setRoundsLookupLoad(true)
			setStandingsLoad(true)
			try {
				setSelectedStandingsClass('Teams')
				let groupsRes = await ApiService.httpGet(
					'/TeamEventTeams?Class=GroupsLookUp&TourEventId=' + eventId,
					user.userId
				)
				setStandingGroups(groupsRes.GroupNames)
				let selectedGroup = groupsRes.GroupNames[0]
				setSelectedStandingsGroup(selectedGroup)
				setGroupsLookupLoad(false)
				let standingRounds = await ApiService.httpGet(
					'/TeamEventTeams?Class=StandingsRoundLookup&TourEventId=' + eventId,
					user.userId
				)
				setStandupRounds(standingRounds.Rounds)
				if (selectedGroup.GroupId in standingRounds.Rounds) {
					let selectedRound = standingRounds.Rounds[selectedGroup.GroupId].find(
						(round) => round.Latest === 'YES'
					)
					setSelectedStandupRound(selectedRound)
					setRoundsLookupLoad(false)
					let response = await ApiService.httpGet(
						`/TourStandings?TourEventId=${eventId}&GroupName=${selectedGroup.GroupId}&RoundNo=${selectedRound?.RoundNo}&Class=Teams`,
						user.userId
					)
					setStandingsData(response.TourStandings)
					setStandingsLoad(false)
				} else {
					toast({
						title: 'Standings',
						description: "Rounds aren't generated yet",
						status: 'warning',
						duration: 3000,
						isClosable: true,
						position: 'top-right',
					})
					setStandingsData([])
				}
			} catch (err) {
				console.log(err)
			} finally {
				setGroupsLookupLoad(false)
				setRoundsLookupLoad(false)
				setStandingsLoad(false)
			}
		} else if (index === 5 && eventType === 'Teams') {
			setGroupsLookupLoad(true)
			setStreamingLoad(true)
			try {
				let scResponse = await ApiService.httpGet(
					`/StreamingConfigurations?Class=ScInfo&TourEventId=${location.state.EventId}`,
					user.userId
				)
				setStreamingConfigurationsData(scResponse.StreamingConf)
				setStreamingLoad(false)
				let scGroups = await ApiService.httpGet(
					'/StreamingConfigurations?Class=GroupsLookup&TourEventId=' + eventId,
					user.userId
				)
				setStreamingGroups(scGroups.GroupNames)
				setGroupsLookupLoad(false)
			} catch (err) {
				console.log(err)
			} finally {
				setGroupsLookupLoad(false)
				setStreamingLoad(false)
			}
		}
		if (index === 1 && eventType !== 'Teams') {
			try {
				setGroupsLookupLoad(true)
				setGroupsDataLoading(true)
				const groupsLookup = await ApiService.httpGet(
					'/EventTeams?GroupsEventId=' + eventId,
					user.userId
				)
				setGroups(groupsLookup.GroupNames)
				setGroupsLookupLoad(false)
				const groupsResponse = await ApiService.httpGet(
					'/EventTeams?EventId=' + eventId,
					user.userId
				)
				setAllGroupsData(groupsResponse.Groups)
				let data = groupsResponse.Groups.find(
					(group) => group.GroupName === groupsLookup.GroupNames[0].GroupName
				)
				setSelectedGroup(groupsLookup.GroupNames[0])
				setGroupsData(data?.Players)
				setGroupsGroupName(data?.GroupName)
				setGroupsDataLength(data?.PlayerCount)
				setGroupsDataLoading(false)
			} catch (err) {
				console.log(err.message)
			} finally {
				setGroupsLookupLoad(false)
				setGroupsDataLoading(false)
			}
		} else if (index === 2 && eventType !== 'Teams') {
			setGroupsLookupLoad(true)
			setRoundsLookupLoad(true)
			setMatchupsLoad(true)
			try {
				let matchupGroups = await ApiService.httpGet(
					'/EventTeams?GroupsEventId=' + eventId,
					user.userId
				)
				if ('GroupNames' in matchupGroups) {
					setMatchupGroups(matchupGroups.GroupNames)
					if (matchupGroups.GroupNames.length > 0) {
						let selectedGroup = matchupGroups.GroupNames[0]
						setSelectedMatchGroup(selectedGroup)
						setGroupsLookupLoad(false)
						let matchupsRounds = await ApiService.httpGet(
							'/TourEventMatchupsByGroup?RoundsByTourEventId=' + eventId,
							user.userId
						)
						if ('Rounds' in matchupsRounds) {
							setMatchupRounds(matchupsRounds.Rounds)
							if (matchupsRounds.Rounds.length > 0) {
								let selectedRound = matchupsRounds.Rounds.find(
									(round) => round.Latest === 'YES'
								)
								setSelectedMatchRound(selectedRound)
								setRoundsLookupLoad(false)
								let response3 = await ApiService.httpGet(
									`/TourEventMatchupsByGroup?TourEventId=${location.state.EventId}&RoundNo=${selectedRound.RoundNo}&GroupName=${selectedGroup.GroupId}`,
									user.userId
								)
								setMatchupsInitData(response3.TourEventMatchups)
								setMatchupsData(response3.TourEventMatchups)
								setMatchupsLoad(false)
							} else {
								toast({
									title: 'Matchups',
									description: "Rounds aren't available yet for this group",
									status: 'warning',
									duration: 3000,
									isClosable: true,
									position: 'top-right',
								})
								setMatchupsInitData([])
								setMatchupsData([])
							}
						} else {
							toast({
								title: 'Matchups',
								description: "Rounds aren't generated yet for this group",
								status: 'warning',
								duration: 3000,
								isClosable: true,
								position: 'top-right',
							})
							setMatchupsInitData([])
							setMatchupsData([])
						}
					} else {
						toast({
							title: 'Matchups',
							description: "Groups aren't available",
							status: 'warning',
							duration: 3000,
							isClosable: true,
							position: 'top-right',
						})
						setMatchupsInitData([])
						setMatchupsData([])
					}
				} else {
					toast({
						title: 'Matchups',
						description: "Groups aren't available",
						status: 'warning',
						duration: 3000,
						isClosable: true,
						position: 'top-right',
					})
					setMatchupsInitData([])
					setMatchupsData([])
				}
			} catch (err) {
				console.log(err)
			} finally {
				setGroupsLookupLoad(false)
				setRoundsLookupLoad(false)
				setMatchupsLoad(false)
			}
		} else if (index === 3 && eventType !== 'Teams') {
			setGroupsLookupLoad(true)
			setRoundsLookupLoad(true)
			setStandingsLoad(true)
			setLeaderGroupsLoad(true)
			setLeaderLoad(true)
			try {
				let groups = await ApiService.httpGet(
					'/EventTeams?GroupsEventId=' + eventId,
					user.userId
				)
				setStandingGroups(groups.GroupNames)
				let selectedGroup = groups.GroupNames[0]
				setSelectedStandingsGroup(selectedGroup)
				setGroupsLookupLoad(false)
				let standingRounds = await ApiService.httpGet(
					'/TourStandings?Class=RoundsLookup&TourEventId=' + eventId,
					user.userId
				)
				setStandupRounds(standingRounds.Rounds)
				let selectedRound = standingRounds.Rounds.find(
					(round) => round.Latest === 'YES'
				)
				setSelectedStandupRound(selectedRound)
				setRoundsLookupLoad(false)
				let response = await ApiService.httpGet(
					`/TourStandings?TourEventId=${location.state.EventId}&GroupName=A&RoundNo=${selectedRound.RoundNo}`,
					user.userId
				)
				setStandingsData(response.TourStandings)
				setStandingsLoad(false)
				let groupsRes = await ApiService.httpGet(
					'/KnockoutMatchups?GroupsByEventId=' + location.state.EventId,
					user.userId
				)
				if (groupsRes.GroupNames?.length > 0) {
					setLeaderGroups(groupsRes.GroupNames)
					const groupName = groupsRes.GroupNames[0]
					setSelectedLeaderGroup(groupName)
					setLeaderGroupsLoad(false)
					let leaderRes = await ApiService.httpGet(
						`/TourStandings?Class=LeaderBoard&TourEventId=${eventId}&GroupName=${groupName}`,
						user.userId
					)
					setLeaderboardData(leaderRes.LeaderBoard)
					setLeaderLoad(false)
				} else {
					setLeaderGroupsLoad(false)
					setLeaderLoad(false)
				}
			} catch (err) {
				console.log(err)
			} finally {
				setGroupsLookupLoad(false)
				setRoundsLookupLoad(false)
				setStandingsLoad(false)
				setLeaderGroupsLoad(false)
				setLeaderLoad(false)
			}
		} else if (index === 4 && eventType !== 'Teams') {
			setKOMatrixLoad(true)
			setKOGroupsLoad(true)
			try {
				let res = await ApiService.httpGet(
					'/EventTeams?GroupsEventId=' + eventId,
					user.userId
				)
				setKOGroups(res.GroupNames)
				let matrix = await ApiService.httpGet(
					'/KnockoutMatrix?Class=KM&EventId=' + location.state.EventId,
					user.userId
				)
				console.log(matrix)
				setKnockoutMatrixData(matrix.KnockoutMatrix)
				setKOMatrixLoad(false)
				setKMStatus(matrix.status)
				let groups = await ApiService.httpGet(
					'/KnockoutMatrix?Class=KG&EventId=' + location.state.EventId,
					user.userId
				)
				console.log(groups)
				setKnockoutGroupsData(groups.KOGroups)
				setDisableKGAdd(false)
				setKOGroupsLoad(false)
			} catch (err) {
				console.log(err)
			} finally {
				setKOMatrixLoad(false)
				setKOGroupsLoad(false)
			}
		} else if (index === 5 && eventType !== 'Teams') {
			setGroupsLookupLoad(true)
			setKOMatchupsLoad(true)
			try {
				let response = await ApiService.httpGet(
					'/KnockoutMatchups?GroupsByEventId=' + location.state.EventId,
					user.userId
				)
				console.log(response)
				setKnockoutMatchupsGroupsData(response.GroupNames)
				setSelectedKOMatchGroup(response.GroupNames[0])
				setGroupsLookupLoad(false)
				let KMResponse = await ApiService.httpGet(
					`/KnockoutMatchups?EventId=${location.state.EventId}&GroupName=${response.GroupNames[0]}`,
					user.userId
				)
				console.log(KMResponse)
				setKOMatchupsInitData(KMResponse.Knockouts)
				setKnockoutMatchupsData(KMResponse.Knockouts)
				setKOMatchupsLoad(false)
			} catch (err) {
				console.log(err)
			} finally {
				setGroupsLookupLoad(false)
				setKOMatchupsLoad(false)
			}
		} else if (index === 6 && eventType !== 'Teams') {
			setGroupsLookupLoad(true)
			setStreamingLoad(true)
			try {
				let scResponse = await ApiService.httpGet(
					`/StreamingConfigurations?Class=ScInfo&TourEventId=${location.state.EventId}`,
					user.userId
				)
				setStreamingConfigurationsData(scResponse.StreamingConf)
				setStreamingLoad(false)
				let scGroups = await ApiService.httpGet(
					'/StreamingConfigurations?Class=GroupsLookup&TourEventId=' + eventId,
					user.userId
				)
				setStreamingGroups(scGroups.GroupNames)
				setGroupsLookupLoad(false)
			} catch (err) {
				console.log(err)
			} finally {
				setGroupsLookupLoad(false)
				setStreamingLoad(false)
			}
		}
	}
	// To jump across tabs
	const navigateToStandings = () => {
		handleTabsChange(eventType === 'Singles' ? 3 : 4)
	}
	const navigateToTeamMatchups = () => {
		eventType === 'Teams' && handleTabsChange(3)
	}
	const navigateToStreaming = () => {
		eventType === 'Teams' ? handleTabsChange(5) : handleTabsChange(6)
	}
	const navigateToKOMatchups = () => {
		handleTabsChange(tabIndex + 1)
	}
	const fetchFlags = async () => {
		setLoading(true)
		try {
			let flags = await ApiService.httpGet(
				'/TourEvents?Class=EventState&TourEventId=' + eventId,
				user.userId
			)
			setEventFlags(flags)
			setLoading(false)
			if (eventType !== 'Teams' && flags.PlayerState === 'Current') {
				await handleTabsChange(0)
			} else if (eventType !== 'Teams' && flags.CurrentState === 'Matchups') {
				await handleTabsChange(eventType === 'Singles' ? 2 : 3)
			} else if (eventType !== 'Teams' && flags.KOConfState === 'Current') {
				await handleTabsChange(eventType === 'Singles' ? 4 : 5)
			} else if (eventType !== 'Teams' && flags.KOMatchState === 'Current') {
				await handleTabsChange(eventType === 'Singles' ? 5 : 6)
			}
			if (eventType === 'Teams' && flags.TeamsState === 'Current') {
				await handleTabsChange(1)
			} else if (
				eventType === 'Teams' &&
				flags.PlayerSelectionState === 'Current'
			) {
				await handleTabsChange(2)
			} else if (eventType === 'Teams' && flags.MatchupsState === 'Current') {
				await handleTabsChange(3)
			}
			eventType === 'Singles' && (await fetchSingles())
			eventType !== 'Singles' && (await fetchDoubles())
			eventType === 'Doubles' && (await fetchDoublesTeams())
		} catch (err) {
			console.log(err)
		} finally {
			setLoading(false)
		}
	}
	// Will load on inital render
	useEffect(() => {
		fetchFlags()
	}, [singlesReload, doublesReload, teamsReload])

	return (
		<Container
			flex="1"
			overflowY="auto"
			maxWidth="100vw"
			marginTop={isDesktop ? '5rem' : '1rem'}
			marginX={0}
			paddingBottom={10}
			css={{
				'::-webkit-scrollbar': {
					width: '0.5rem',
					height: '0.5rem',
				},
				'::-webkit-scrollbar-track': {
					background: '#bee3f8',
					borderRadius: '1rem',
				},
				'::-webkit-scrollbar-thumb': {
					background: '#3182ce',
					borderRadius: '1rem',
				},
			}}>
			<Stack spacing="1.7rem">
				<HStack spacing="5">
					<IconButton
						borderRadius="full"
						p={2}
						size="md"
						bgColor="white"
						as={IoChevronBack}
						onClick={() => navigate('/events')}
					/>
					{loading ? (
						<Skeleton isLoaded={!loading} w="15vw" h="3vw" />
					) : (
						<Heading size={pageHeader} fontWeight="bold">
							{location.state.TournamentName}
						</Heading>
					)}
				</HStack>
				<Box
					px=".3rem"
					py="1rem"
					bgColor="white"
					borderRadius={'2xl'}
					boxShadow={shadow}>
					<Tabs
						variant="line"
						isFitted
						size="lg"
						index={tabIndex}
						onChange={handleTabsChange}>
						<Skeleton isLoaded={!loading}>
							<TabList
								overflowX="auto"
								whiteSpace="nowrap"
								py={0}
								css={{
									scrollbarWidth: 'none',
									'::-webkit-scrollbar': { display: 'none' },
									WebkitOverflowScrolling: 'touch',
									boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
									border: '0 none',
								}}>
								<Tab py={0} isDisabled={eventFlags?.PlayerState === 'InActive'}>
									{eventType} Players
								</Tab>
								{eventType === 'Doubles' && <Tab py={0}>{eventType} Teams</Tab>}
								{eventType === 'Teams' && (
									<Tab
										py={0}
										isDisabled={eventFlags?.TeamsState === 'InActive'}>
										{eventType}
									</Tab>
								)}
								{eventType !== 'Teams' && (
									<Tab isDisabled={eventFlags?.GroupsState === 'InActive'}>
										Groups
									</Tab>
								)}
								{eventType === 'Teams' && (
									<Tab
										isDisabled={
											eventFlags?.PlayerSelectionState === 'InActive'
										}>
										Player Selection
									</Tab>
								)}
								<Tab isDisabled={eventFlags?.MatchupsState === 'InActive'}>
									Matchups
								</Tab>
								<Tab isDisabled={eventFlags?.StandingState === 'InActive'}>
									Standings
								</Tab>
								{eventType !== 'Teams' && (
									<Tab isDisabled={eventFlags?.KOConfState === 'InActive'}>
										Knockout Config
									</Tab>
								)}
								{eventType !== 'Teams' && (
									<Tab isDisabled={eventFlags?.KOMatchState === 'InActive'}>
										Knockout Matchups
									</Tab>
								)}
								{eventFlags &&
									eventFlags?.EventState?.HideStreaming !== 'Yes' && (
										<Tab>Streaming</Tab>
									)}
							</TabList>
						</Skeleton>
						<Skeleton isLoaded={!loading}>
							<TabPanels>
								{eventType === 'Singles' ? (
									<TabPanel p={0}>
										<Stack spacing="2rem">
											<SinglesTabCard
												state={location.state}
												setQuery={setQuerySinglesPlayers}
											/>
											<SinglesPlayersTable
												state={location.state}
												singlesPlayers={singlesPlayers}
												generateLoading={generateLoading}
												generateGroups={generateGroups}
												setSinglesReload={setSinglesReload}
												singlesReload={singlesReload}
												query={querySinglesPlayers}
												singlesLoading={singlesLoading}
											/>
										</Stack>
									</TabPanel>
								) : (
									<TabPanel p={0}>
										<Stack spacing="2rem">
											<DoublesTabCard
												state={location.state}
												setQuery={setQueryDoublesPlayers}
											/>
											<DoublesPlayersTable
												state={location.state}
												doublesPlayers={doublesPlayers}
												doublesReload={doublesReload}
												setDoublesReload={setDoublesReload}
												query={queryDoublesPlayers}
												doublesLoading={doublesLoading}
											/>
										</Stack>
									</TabPanel>
								)}
								{eventType === 'Doubles' && (
									<TabPanel p={0}>
										<Stack spacing="2rem">
											<DoublesTeamTabCard
												setTeamsReload={setTeamsReload}
												teamsReload={teamsReload}
												state={location.state}
												setQuery={setQueryDoublesTeam}
												eventId={eventId}
											/>
											<DoublesTeamTable
												state={location.state}
												doublesTeamData={doublesTeamData}
												generateLoading={generateLoading}
												generateGroups={generateGroups}
												setTeamsReload={setTeamsReload}
												teamsReload={teamsReload}
												query={queryDoublesTeam}
												doublesTeamLoad={doublesTeamLoad}
											/>
										</Stack>
									</TabPanel>
								)}
								{eventType === 'Teams' && (
									<TabPanel p={0}>
										<Stack spacing="2rem">
											<TeamsTabCard eventId={eventId} />
											<TeamsTable
												eventId={eventId}
												teamsGroups={teamsGroups}
												teams={teams}
												teamsPlayers={teamsPlayers}
												teamSize={teamSize}
												fetchTeamsData={fetchTeamsData}
												fetchTeamsList={fetchTeamsList}
												isTeamEditable={isTeamEditable}
												setTeamsList={setTeamsList}
												teamsList={teamsList}
												setIsTeamEditable={setIsTeamEditable}
												isRankRequired={
													eventFlags && eventFlags.EventState?.isrankrequired
												}
												teamsLoading={teamsLoading}
												teamsModalLoad={teamsModalLoad}
											/>
										</Stack>
									</TabPanel>
								)}
								{eventType !== 'Teams' && (
									<TabPanel p={0}>
										<Stack spacing="2rem">
											<GroupsTabCard
												eventType={eventType}
												groups={groups}
												groupsLength={groupsDataLength}
												updateGroupsData={updateGroupsData}
												groupName={groupsGroupName}
												state={location.state}
												groupsLookupLoad={groupsLookupLoad}
												groupsDataLoading={groupsDataLoading}
											/>
											<GroupsTable
												eventType={eventType}
												state={location.state}
												groups={groups}
												groupsData={groupsData}
												refreshGroups={refreshGroups}
												groupName={groupsGroupName}
												groupsDataLoading={groupsDataLoading}
											/>
										</Stack>
									</TabPanel>
								)}
								{eventType === 'Teams' && (
									<TabPanel p={0}>
										<Stack spacing="2rem">
											<PlayerSelectionTabCard
												eventId={eventId}
												groups={plyrSelGroups}
												rounds={plyrSelRounds[selectedPlyrGroup.GroupId]}
												selectedGroup={selectedPlyrGroup}
												selectedRound={selectedPlyrRound}
												refreshPlyrSelection={refreshPlyrSelection}
												updatePlyrSelection={updatePlyrSelection}
												latestRoundPlyrSelection={latestRoundPlyrSelection}
												groupsLookupLoad={groupsLookupLoad}
												roundsLookupLoad={roundsLookupLoad}
											/>
											<PlayerSelectionTable
												selectedGroup={selectedPlyrGroup}
												plyrSelData={plyrSelData}
												plyrSelPositions={plyrSelPositions}
												rounds={plyrSelRounds[selectedPlyrGroup.GroupId]}
												eventId={eventId}
												refreshPlyrSelection={refreshPlyrSelection}
												navigateToTeamMatchups={navigateToTeamMatchups}
												latestRoundPlyrSelection={latestRoundPlyrSelection}
												plyrSelLoading={plyrSelLoading}
												plyrSelModalLoad={plyrSelModalLoad}
												fetchFlags={fetchFlags}
											/>
										</Stack>
									</TabPanel>
								)}
								{eventType !== 'Teams' && (
									<TabPanel p={0}>
										<Stack spacing="2rem">
											<MatchupsTabCard
												groups={matchUpGroups}
												selectedGroup={selectedMatchGroup}
												rounds={matchupRounds}
												selectedRound={selectedMatchRound}
												updateMatchupsData={updateMatchupsData}
												refreshMatchups={refreshMatchups}
												refreshMatchupsView={refreshMatchupsView}
												state={location.state}
												eventType={eventType}
												navigateToStandings={navigateToStandings}
												matchupsData={matchupsData}
												navigateToStreaming={navigateToStreaming}
												setQuery={setQueryMatchups}
												groupsLookupLoad={groupsLookupLoad}
												roundsLookupLoad={roundsLookupLoad}
											/>
											<MatchupsTable
												state={location.state}
												matchupsInitData={matchupsInitData}
												matchupsData={matchupsData}
												setMatchupsInitData={setMatchupsInitData}
												setMatchupsData={setMatchupsData}
												selectedGroup={selectedMatchGroup}
												refreshMatchupsView={refreshMatchupsView}
												refreshMatchups={refreshMatchups}
												query={queryMatchups}
												matchupsLoad={matchupsLoad}
												fetchFlags={fetchFlags}
											/>
										</Stack>
									</TabPanel>
								)}
								{eventType === 'Teams' && (
									<TabPanel p={0}>
										<Stack spacing="2rem">
											<TeamMatchupsTabCard
												teamMatchupGroups={teamMatchupGroups}
												teamMatchupRounds={
													teamMatchupRounds[selctdTeamMatchGroup.GroupId]
												}
												selctdTeamMatchGroup={selctdTeamMatchGroup}
												selctdTeamMatchRound={selctdTeamMatchRound}
												updateTeamMatchSelection={updateTeamMatchSelection}
												eventId={eventId}
												teamMatchupData={teamMatchupData}
												refreshTeamMatchups={refreshTeamMatchups}
												setQuery={setQueryTeamMatchups}
												groupsLookupLoad={groupsLookupLoad}
												roundsLookupLoad={roundsLookupLoad}
											/>
											<TeamMatchupsTable
												teamMatchSet={teamMatchSet}
												teamMatchupInitData={teamMatchupInitData}
												selctdTeamMatchGroup={selctdTeamMatchGroup}
												selctdTeamMatchRound={selctdTeamMatchRound}
												teamMatchupData={teamMatchupData}
												setTeamMatchupInitData={setTeamMatchupInitData}
												setTeamMatchupData={setTeamMatchupData}
												eventId={eventId}
												refreshTeamMatchups={refreshTeamMatchups}
												query={queryTeamMatchups}
												teamMatchupLoad={teamMatchupLoad}
												fetchFlags={fetchFlags}
											/>
										</Stack>
									</TabPanel>
								)}
								<TabPanel p={0}>
									{eventType === 'Teams' ? (
										<Stack spacing="2rem">
											<StandingsTabCard
												groups={standingGroups}
												selectedGroup={selectedStandingsGroup}
												selectedClass={selectedStandingsClass}
												rounds={standupRounds[selectedStandingsGroup.GroupId]}
												selectedRound={selectedStandupRound}
												updateStandingsData={updateStandingsData}
												updateTeamStandings={updateTeamStandings}
												state={location.state}
												eventType={eventType}
												standingsData={standingsData}
												setQueryTeams={setQueryStandings}
												groupsLookupLoad={groupsLookupLoad}
												roundsLookupLoad={roundsLookupLoad}
											/>
											<StandingsTable
												state={location.state}
												selectedClass={selectedStandingsClass}
												eventType={eventType}
												standingsData={standingsData}
												selectedStandingsGroup={selectedStandingsGroup}
												queryTeams={queryStandings}
												standingsLoad={standingsLoad}
											/>
										</Stack>
									) : (
										<Tabs variant="enclosed-colored" isFitted size="lg">
											<TabList whiteSpace="nowrap">
												<Tab>Round Standings</Tab>
												<Tab isDisabled={leaderGroups?.length < 1}>
													Leaderboard
												</Tab>
											</TabList>
											<TabPanels>
												<TabPanel p={0}>
													<Stack spacing="2rem">
														<StandingsTabCard
															groups={standingGroups}
															selectedGroup={selectedStandingsGroup}
															rounds={standupRounds}
															selectedRound={selectedStandupRound}
															updateStandingsData={updateStandingsData}
															state={location.state}
															eventType={eventType}
															standingsData={standingsData}
															setQuery={setQueryStandings}
															groupsLookupLoad={groupsLookupLoad}
															roundsLookupLoad={roundsLookupLoad}
														/>
														<StandingsTable
															state={location.state}
															eventType={eventType}
															standingsData={standingsData}
															selectedStandingsGroup={selectedStandingsGroup}
															query={queryStandings}
															standingsLoad={standingsLoad}
														/>
													</Stack>
												</TabPanel>
												<TabPanel p={0}>
													<Stack spacing="2rem">
														<LeaderboardTabCard
															eventId={eventId}
															groups={leaderGroups}
															selectedGroup={selectedLeaderGroup}
															updateLeaderData={updateLeaderData}
															setLeaderGroupsLoad={setLeaderGroupsLoad}
															setLeaderLoad={setLeaderLoad}
															leaderGroupsLoad={leaderGroupsLoad}
														/>
														<LeaderboardTable
															state={location.state}
															eventType={eventType}
															leaderboardData={leaderboardData}
															selectedStandingsGroup={selectedStandingsGroup}
															leaderLoad={leaderLoad}
														/>
													</Stack>
												</TabPanel>
											</TabPanels>
										</Tabs>
									)}
								</TabPanel>
								{eventType !== 'Teams' && (
									<TabPanel p={0}>
										<Stack spacing="2rem">
											<KnockoutGroupsTabCard
												groups={koGroups}
												knockoutMatrixData={knockoutMatrixData}
												eventId={eventId}
												kmStatus={kmStatus}
												refreshKOGroups={refreshKOGroups}
												koMatrixLoad={koMatrixLoad}
											/>
											<KnockoutGroupsTable
												state={location.state}
												knockoutGroupsData={knockoutGroupsData}
												setKnockoutGroupsData={setKnockoutGroupsData}
												navigateToKOMatchups={navigateToKOMatchups}
												disableAddBtn={disableKGAdd}
												setDisableAddBtn={setDisableKGAdd}
												refreshKOGroups={refreshKOGroups}
												koGroupsLoad={koGroupsLoad}
												fetchFlags={fetchFlags}
											/>
										</Stack>
									</TabPanel>
								)}
								{eventType !== 'Teams' && (
									<TabPanel p={0}>
										<Stack spacing="2rem">
											<KnockoutMatchupsTabCard
												groups={knockoutMatchupsGroupsData}
												updateKOMatchUpsData={updateKOMatchUpsData}
												refreshMatchupsView={refreshKOMatchUpsView}
												state={location.state}
												selectedKOMatchGroup={selectedKOMatchGroup}
												groupsLookupLoad={groupsLookupLoad}
											/>
											<KnockoutMatchupsTable
												state={location.state}
												eventId={eventId}
												refreshMatchups={refreshKOMatchUps}
												koMatchupsInitData={koMatchupsInitData}
												knockoutMatchupsData={knockoutMatchupsData}
												setKnockoutMatchupsData={setKnockoutMatchupsData}
												setKOMatchupsInitData={setKOMatchupsInitData}
												selectedKOMatchGroup={selectedKOMatchGroup}
												koMatchupsLoad={koMatchupsLoad}
											/>
										</Stack>
									</TabPanel>
								)}
								<TabPanel p={0}>
									<Stack spacing="2rem">
										<StreamingTabCard
											eventId={eventId}
											eventType={eventType}
											streamingGroups={streamingGroups}
											selectedStreamingGroup={selectedStreamingGroup}
											refreshStreaming={refreshStreaming}
											groupsLookupLoad={groupsLookupLoad}
										/>
										<StreamingTable
											streamingConfigurationsData={streamingConfigurationsData}
											eventId={eventId}
											eventType={eventType}
											streamingLoad={streamingLoad}
											streamingGroups={streamingGroups}
											refreshStreaming={refreshStreaming}
											groupsLookupLoad={groupsLookupLoad}
										/>
									</Stack>
								</TabPanel>
							</TabPanels>
						</Skeleton>
					</Tabs>
				</Box>
			</Stack>
		</Container>
	)
}
